import { mdiAccountMultiple, mdiCloudOutline, mdiCog, mdiExitToApp, mdiHome, mdiMonitor, mdiStar, mdiWindowRestore } from "@mdi/js";
import Icon from "@mdi/react";
import { MenuItem } from "primereact/menuitem";
import { useMemo } from "react";

export function useMenuStructure() {
  return useMemo<MenuItem[]>(
    () => [
      {
        label: "Navigation",
        items: [
          {
            label: "Start",
            icon: <Icon path={mdiHome} size={0.7} />,
            command: () => {
              window.location.hash = "/";
            },
          },
          {
            label: "Nodes",
            icon: <Icon path={mdiMonitor} size={0.7} />,
            command: () => {
              window.location.hash = "/nodes";
            },
          },
          {
            label: "Registries",
            icon: <Icon path={mdiCloudOutline} size={0.7} />,
            command: () => {
              window.location.hash = "/registries";
            },
          },
          {
            label: "App definitions",
            icon: <Icon path={mdiWindowRestore} size={0.7} />,
            command: () => {
              window.location.hash = "/app-definitions";
            },
          },
          {
            label: "Feature",
            icon: <Icon path={mdiStar} size={0.7} />,
            command: () => {
              window.location.hash = "/features";
            },
          },
          {
            label: "Users",
            icon: <Icon path={mdiAccountMultiple} size={0.7} />,
            command: () => {
              window.location.hash = "/users";
            },
          },
        ],
      },
      {
        label: "Account",
        items: [
          {
            label: "Options",
            icon: <Icon path={mdiCog} size={0.7} />,
            command: () => {},
          },
          { label: "Sign Out", icon: <Icon path={mdiExitToApp} size={0.7} /> },
        ],
      },
    ],
    []
  );
}
