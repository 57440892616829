import { Button } from "primereact/button";
import { memo, useCallback } from "react";

export const ButtonDeleteListEntry = memo(
  ({
    minListValue,
    currentIndex,
    onRemoveListEntry,
    currentListLength,
  }: {
    minListValue: number;
    currentListLength: number;
    currentIndex: number;
    onRemoveListEntry: (index: number) => void;
  }) => {
    const onDelete = useCallback(() => {
      if (currentListLength > minListValue) {
        onRemoveListEntry(currentIndex);
      }
    }, [currentListLength, minListValue, currentIndex, onRemoveListEntry]);

    return (
      <Button onClick={onDelete} type="button" className="p-button-danger mb-3">
        Delete
      </Button>
    );
  }
);
