import { Subject } from "rxjs";

const loggedInChange = new Subject<true>();

export function getLoggedInChange$() {
  return loggedInChange.asObservable();
}

export function fireLoggedInChange() {
  loggedInChange.next(true);
}
