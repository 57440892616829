import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { memo, useCallback } from "react";
import { GraphqlForm } from "../../../components/graphql-form/graphql-form";
import { EnvironmentVariableGroupInput, useEnvironmentVariableGroupByIdLazyQuery, useUpdateEnvironmentVariableGroupMutation } from "../../../graphql/generated/graphql";
import { useElementVisibility } from "../../../hooks/useElementVisibility";
const schemaFile = require("../../../schema.graphql");

export interface UpdatedEnvironmentVariableGroupProps {
  environmentVariableGroupId: string;
}

export const UpdatedEnvironmentVariableGroup = memo<UpdatedEnvironmentVariableGroupProps>(function UpdatedEnvironmentVariableGroup({ environmentVariableGroupId }) {
  const { elementVisibility, hideElement, showElement } = useElementVisibility(false);
  const [fetch, initialValues] = useEnvironmentVariableGroupByIdLazyQuery({
    variables: {
      id: environmentVariableGroupId,
    },
  });
  const [updateEnvironmentVariableGroup] = useUpdateEnvironmentVariableGroupMutation();

  const onShow = useCallback(() => {
    fetch();
    showElement();
  }, [fetch, showElement]);

  const onSubmit = useCallback(
    async (values: EnvironmentVariableGroupInput) => {
      await updateEnvironmentVariableGroup({
        variables: {
          id: environmentVariableGroupId,
          environmentVariableGroup: values,
        },
      });
    },
    [environmentVariableGroupId, updateEnvironmentVariableGroup]
  );

  const environmentVariableGroup = initialValues.data?.appDefinition?.environmentVariableGroupById;

  return (
    <>
      <Dialog onHide={hideElement} visible={elementVisibility} header="Add app definition">
        <div className="p-fluid">
          {environmentVariableGroup && (
            <GraphqlForm<EnvironmentVariableGroupInput> schemaFile={schemaFile} inputTypeName="EnvironmentVariableGroupInput" onSubmit={onSubmit} initialData={environmentVariableGroup} />
          )}
        </div>
      </Dialog>
      <Button label="Edit Variable Group" icon={<Icon path={mdiPlus} size={0.7} />} onClick={onShow} />
    </>
  );
});
