import { memo } from "react";
import { NodeMenu } from "../../components/node-menu/node-menu";
import { NodeAppsList } from "./components/node-apps-list";

export const NodeApps = memo(function NodeApps() {
  return (
    <div className="flex flex-1">
      <NodeMenu />
      <NodeAppsList />
    </div>
  );
});
