import { GraphQLInputField } from "graphql";
import { Dropdown } from "primereact/dropdown";
import { memo, useMemo } from "react";
import { useController } from "react-hook-form";

export const IntFieldRange = memo(function IntFieldRange({
  field,
  name,
  startValue,
  endValue,
}: {
  field: GraphQLInputField;
  name: string;
  startValue: number;
  endValue: number;
}) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name, rules: { required: true } });

  const options = useMemo(() => {
    const internalOptions = [];
    for (let i = startValue; i <= endValue; i++) {
      internalOptions.push({
        label: i.toString(),
        value: i,
      });
    }
    return internalOptions;
  }, [startValue, endValue]);

  return (
    <div className="field">
      <label>{field.name}</label>
      <Dropdown
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        className={!!error ? "p-invalid" : ""}
      />
    </div>
  );
});
