import { memo, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useVmLogQueryQuery, useVmLogSubscriptionSubscription } from "../../../graphql/generated/graphql";

export const NodeVmLogData = memo(function NodeVmLogData() {
  const { nodeId } = useParams();
  const [log, setLog] = useState("");
  const { data: vmLogData } = useVmLogQueryQuery({ variables: { nodeId: nodeId! } });
  const { data: vmLogSubscription } = useVmLogSubscriptionSubscription({ variables: { nodeId: nodeId! } });

  useEffect(() => {
    const vmLog = vmLogData?.node?.byId?.vmLog;
    if (vmLog) {
      setLog(vmLog ?? "");
    }
  }, [vmLogData?.node?.byId?.vmLog]);

  useEffect(() => {
    const vmLog = vmLogSubscription?.vmLogUpdated;
    if (vmLog) {
      setLog(vmLog);
    }
  }, [vmLogSubscription?.vmLogUpdated]);

  return (
    <div className="p-card flex flex-1 flex-column p-4 overflow-auto min-w-0 min-h-0">
      <pre>{log}</pre>
    </div>
  );
});
