import { GraphQLInputField, Kind } from "graphql";
import { useMemo } from "react";

export function useIsIntRange(field: GraphQLInputField) {
  return useMemo(() => {
    if (!field.astNode?.directives?.length) {
      return null;
    }

    if (field.astNode.directives[0].name.value !== "asIntRange") {
      return null;
    }

    const intRangeArguments = field.astNode.directives[0]?.arguments;
    const firstValue = intRangeArguments?.[0].value;
    const secondValue = intRangeArguments?.[1].value;
    if (firstValue?.kind === Kind.INT && secondValue?.kind === Kind.INT) {
      return {
        startValue: parseInt(firstValue.value, 10),
        endValue: parseInt(secondValue.value, 10),
      };
    }

    return null;
  }, [field]);
}
