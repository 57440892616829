import orderBy from "lodash.orderby";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { memo, useMemo } from "react";
import { useParams } from "react-router";
import { timeRenderer } from "../../../components/columns/time-renderer";
import { useNodeReportedErrorsQuery } from "../../../graphql/generated/graphql";

export const ReportedErrorsOverview = memo(function ReportedErrorsOverview() {
  const { nodeId } = useParams();
  const { data } = useNodeReportedErrorsQuery({ variables: { id: nodeId! } });

  const reportedErrors = useMemo(() => {
    if (data?.node?.byId?.reportedErrors) {
      return orderBy(data?.node?.byId?.reportedErrors, ["time"], ["desc"]);
    }

    return [];
  }, [data?.node?.byId?.reportedErrors]);

  return (
    <div className="flex flex-column mr-5" style={{ width: "calc(100vw - 21rem)" }}>
      <div className="p-card flex flex-1 flex-column">
        <div className="p-card-body flex flex-1 flex-column">
          <DataTable value={reportedErrors}>
            <Column field="time" header="Time" body={timeRenderer("time")} />
            <Column field="error" header="Error" />
          </DataTable>
        </div>
      </div>
    </div>
  );
});
