import { isApolloError } from "@apollo/client";
import { Button, ButtonProps } from "primereact/button";
import { Toast } from "primereact/toast";
import { memo, useCallback, useRef, useState } from "react";
import { isError } from "../../util/is-error";

export const AsyncButton = memo(function AsyncButton(
  props: ButtonProps & { onClick: () => Promise<unknown> }
) {
  const toastRef = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const onClickOutside = props.onClick;
  const label = props.label;

  const onClick = useCallback(async () => {
    setLoading(true);
    let success = false;
    try {
      await onClickOutside();
      success = true;
    } catch (err) {
      if (isError(err) && isApolloError(err)) {
        toastRef.current?.show({
          severity: "error",
          summary: err.message,
        });
      }
    }

    if (success) {
      toastRef.current?.show({
        severity: "success",
        summary: label + " Executed",
      });
    }
    setLoading(false);
  }, [onClickOutside, label]);

  return (
    <>
      <Toast ref={toastRef} />
      <Button {...props} loading={loading} onClick={onClick} />
    </>
  );
});
