import { memo } from "react";
import { SideMenu } from "../../components/side-menu/side-menu";
import { FeaturesList } from "./components/features-list";

export const Features = memo(function Features() {
  return (
    <div className="flex flex-1">
      <SideMenu />
      <FeaturesList />
    </div>
  );
});
