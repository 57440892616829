import { InputText } from "primereact/inputtext";
import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNodeDefaultCustomTagQuery, useUpdateNodeDefaultCustomTagMutation } from "../../../graphql/generated/graphql";

export const NodeDefaultTag = memo(function NodeDefaultTag() {
  const { nodeId } = useParams();
  const { data } = useNodeDefaultCustomTagQuery({
    variables: {
      id: nodeId!,
    },
  });

  const [updateNodeDefaultCustomTag] = useUpdateNodeDefaultCustomTagMutation();

  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(data?.node?.byId?.defaultCustomTag ?? "");
  }, [data]);

  const onChange = useCallback((e: ChangeEvent) => {
    setValue((e.target as HTMLInputElement).value);
  }, []);

  const onBlur = useCallback(async () => {
    await updateNodeDefaultCustomTag({
      variables: {
        nodeId: nodeId!,
        defaultCustomTag: value,
      },
    });
  }, [nodeId, updateNodeDefaultCustomTag, value]);

  return (
    <div>
      <InputText value={value} onChange={onChange} onBlur={onBlur} placeholder="Version" />
    </div>
  );
});
