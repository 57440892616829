import { mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useRemoveNodeContainerAppsMutation } from "../../../graphql/generated/graphql";
import { useNodeStages } from "./hooks/useNodeStages";
import { NodeAppsAddApp } from "./node-apps-add-app";
import { NodeAppsAddAppDefinition } from "./node-apps-add-app-definition";

export const NodeAppsListMenu = memo(function NodeAppsListMenu({ id }: { id: string | null }) {
  const { nodeId } = useParams();
  const [removeNodeContainerApp] = useRemoveNodeContainerAppsMutation();

  const onDelete = useCallback(async () => {
    await removeNodeContainerApp({
      variables: {
        nodeId: nodeId!,
        appDefinitionIds: [id!],
      },
    });
  }, [id, nodeId, removeNodeContainerApp]);

  const { selectedStage, onStageSelected, availableStages } = useNodeStages(nodeId);

  return (
    <div>
      <NodeAppsAddAppDefinition />
      <NodeAppsAddApp />
      <Button label="Delete App" icon={<Icon path={mdiTrashCan} size={0.7} />} className="p-button-danger ml-2" onClick={onDelete} />
      <p>Stage</p>
      <Dropdown className=" ml-2" value={selectedStage} onChange={onStageSelected} options={availableStages} optionLabel="name" placeholder="Select a stage" />
    </div>
  );
});
