import { memo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GraphqlForm } from "../../../components/graphql-form/graphql-form";
import { UpdateContainerAppDefinitionInput, useGetContainerAppDefinitionQuery, useUpdateContainerAppDefinitionMutation } from "../../../graphql/generated/graphql";
const schemaFile = require("../../../schema.graphql");

export const AppDefinitionDetail = memo(function AppDefinitionDetail() {
  const { appDefinitionId } = useParams();
  const navigate = useNavigate();
  const [updateContainerAppDefinition] = useUpdateContainerAppDefinitionMutation();

  const appDefinition = useGetContainerAppDefinitionQuery({
    variables: { id: appDefinitionId! },
  });

  const onSubmit = useCallback(
    async (values: UpdateContainerAppDefinitionInput) => {
      await updateContainerAppDefinition({
        variables: { containerAppDefinition: values },
      });
      navigate("/app-definitions");
    },
    [updateContainerAppDefinition, navigate]
  );

  const onCancel = useCallback(() => {
    navigate("/app-definitions");
  }, [navigate]);

  const initialData = appDefinition.data?.appDefinition?.byId;

  if (!initialData) {
    return null;
  }

  return (
    <div className="flex flex-column flex-1">
      <div className="p-card flex flex-1 flex-column overflow-y-auto">
        <div className="p-card-body flex flex-1 flex-column">
          <div className="flex flex-1 flex-column">
            <div className="p-fluid">
              <GraphqlForm<UpdateContainerAppDefinitionInput>
                schemaFile={schemaFile}
                inputTypeName="UpdateContainerAppDefinitionInput"
                initialData={initialData}
                onSubmit={onSubmit}
                onCancel={onCancel}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
