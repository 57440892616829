import { mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { memo, useCallback, useRef } from "react";
import { RegisterNodeWithK8s, useK8sValuesQuery } from "../../graphql/generated/graphql";
import { K8sInput } from "./k8s-input";

export const K8sForm = memo(function K8sForm({
  onSave,
  onCancel,
  preValues,
}: {
  onSave: (values: RegisterNodeWithK8s[]) => void;
  onCancel: () => void;
  preValues?: Array<{ key: string; value: string }>;
}) {
  const { data } = useK8sValuesQuery();
  const formData = useRef<{ [key: string]: string }>({});

  const onChange = useCallback((key: string, value: string) => {
    formData.current[key] = value;
  }, []);

  const onInternalSave = useCallback(() => {
    const keys = Object.keys(formData.current);

    const k8sValues = keys.map<RegisterNodeWithK8s>((key) => {
      return {
        key,
        value: formData.current[key],
      };
    });

    onSave(k8sValues);
  }, [onSave]);

  return (
    <div>
      <div className="field">
        <label htmlFor={"name"}>Name</label>
        <InputText required onChange={(e) => onChange("nodeName", e.target.value)} />
      </div>
      {data?.node?.k8sValues?.map((k8sValue) => {
        return (
          <div key={k8sValue.name}>
            <K8sInput k8sItem={k8sValue} preValue={preValues?.find((x) => x.key === k8sValue.name)?.value ?? null} onChange={onChange} />
          </div>
        );
      })}
      <div className="flex mt-2">
        <div className="mr-2">
          <div className="field">
            <Button type="submit" icon={<Icon path={mdiCheck} size={0.7} />} label="Save" onClick={onInternalSave} />
          </div>
        </div>
        <div>
          <Button type="button" label="Cancel" onClick={onCancel} />
        </div>
      </div>
    </div>
  );
});
