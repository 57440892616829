import { Column } from "primereact/column";
import { DataTable, DataTableSelectionChangeParams } from "primereact/datatable";
import { memo, useCallback, useState } from "react";
import { useAllEnvironmentVariableGroupsQuery } from "../../../graphql/generated/graphql";
import { RegisterEnvironmentVariableGroup } from "./register-environment-variable-group";
import { UpdatedEnvironmentVariableGroup } from "./update-environment-variable-group";

export const AppDefinitionsVariableGroup = memo(function AppDefinitionsVariableGroup() {
  const [selection, setSelection] = useState<any>();
  const onSelectionChange = useCallback((e: DataTableSelectionChangeParams) => {
    setSelection(e.value);
  }, []);

  const { data } = useAllEnvironmentVariableGroupsQuery();

  console.log(selection);

  return (
    <DataTable
      className="flex-1"
      selectionMode={"single"}
      metaKeySelection={false}
      value={data?.appDefinition?.allEnvironmentVariableGroups ?? []}
      selection={selection}
      onSelectionChange={onSelectionChange}
      header={
        <div className="flex-1">
          <RegisterEnvironmentVariableGroup />
          {selection && <UpdatedEnvironmentVariableGroup environmentVariableGroupId={selection.id} />}
        </div>
      }
    >
      <Column field="groupName" header="GroupName" />
      <Column field="containerName" header="ContainerName" />
    </DataTable>
  );
});
