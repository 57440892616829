import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { timeRenderer } from "../../../components/columns/time-renderer";
import { useNodeContainerDStatsQuery } from "../../../graphql/generated/graphql";

export const ContainerDStats = memo(function ContainerDStats() {
  const { nodeId } = useParams();
  const { data } = useNodeContainerDStatsQuery({
    variables: { id: nodeId! },
  });

  return (
    <div className="flex flex-1 relative">
      <div className="absolute flex top-0 right-0 bottom-0 left-0">
        <DataTable
          value={data?.node?.byId?.containerD?.containers}
          className="flex-1"
          scrollable
          scrollHeight="flex"
        >
          <Column field="containerName" header="Container Name" />
          <Column field="containerState" header="Container State" />
          <Column
            field="createdAt"
            header="Created At"
            body={timeRenderer("createdAt")}
          />
          <Column field="cpuPerc" header="CPU" />
          <Column field="mem" header="Mem" />
        </DataTable>
      </div>
    </div>
  );
});
