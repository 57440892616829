import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { memo, useCallback, useState } from "react";
import { useParams } from "react-router";
import { AsyncButton } from "../../../components/async-button/async-button";
import { useAddFeatureToNodeMutation, useAllFeaturesQuery } from "../../../graphql/generated/graphql";
import { useElementVisibility } from "../../../hooks/useElementVisibility";

export const NodeAddFeature = memo(function NodeAddFeature() {
  const [addFeatureToNode] = useAddFeatureToNodeMutation();
  const { data: features } = useAllFeaturesQuery();
  const { elementVisibility, hideElement, showElement } = useElementVisibility(false);
  const { nodeId } = useParams();

  const [selectedFeature, setSelectedFeature] = useState<any>(null);

  const onFeatureSelect = useCallback((e: any) => {
    setSelectedFeature(e.value);
  }, []);

  const onSave = useCallback(async () => {
    addFeatureToNode({
      variables: {
        featureId: selectedFeature.id,
        nodeId: nodeId!,
      },
    });
  }, [addFeatureToNode, selectedFeature, nodeId]);

  return (
    <>
      <Dialog onHide={hideElement} visible={elementVisibility} header="Add Feature" footer={<AsyncButton onClick={onSave} label="Save" />}>
        <DataTable value={features?.feature?.all ?? []} selectionMode="single" selection={selectedFeature} onSelectionChange={onFeatureSelect}>
          <Column field="name" header="Name" />
        </DataTable>
      </Dialog>
      <Button label="Add Feature" icon={<Icon path={mdiPlus} size={0.7} />} onClick={showElement} />
    </>
  );
});
