import { memo } from "react";
import { NodeMenu } from "../../components/node-menu/node-menu";
import { NodeVmLogData } from "./components/node-vm-log-data";

export const NodeVmLog = memo(function NodeVmLog() {
  return (
    <div className="flex flex-1">
      <NodeMenu />
      <NodeVmLogData />
    </div>
  );
});
