import { TabPanel, TabView } from "primereact/tabview";
import { memo } from "react";
import { AppDefinitionsList } from "./app-definitions-list";
import { AppDefinitionsVariableGroup } from "./app-definitions-variable-groups";

export const AppDefinitionsListCard = memo(function AppDefinitionsListCard() {
  return (
    <div className="flex" style={{ width: "calc(100vw - 21rem)" }}>
      <div className="p-card flex flex-1 flex-column">
        <div className="p-card-body flex flex-1 flex-column">
          <div className="flex flex-1 flex-column">
            <TabView>
              <TabPanel header="App Definitions">
                <AppDefinitionsList />
              </TabPanel>
              <TabPanel header="Variable Groups">
                <AppDefinitionsVariableGroup />
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </div>
  );
});
