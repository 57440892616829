import { SelectItemOptionsType } from "primereact/selectitem";
import { createContext } from "react";

export type ResolverContextType = {
  resolveContextDataForDropdown(
    contextName: string
  ): Promise<SelectItemOptionsType>;
};

export default createContext<ResolverContextType | null>(null);
