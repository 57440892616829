import { memo } from "react";
import { NodeMenu } from "../../components/node-menu/node-menu";
import { NodeMachineInformation } from "./components/node-machine-information";

export const MachineInformation = memo(function MachineInformation() {
  return (
    <div className="flex flex-1">
      <NodeMenu />
      <NodeMachineInformation />
    </div>
  );
});
