import { GraphQLEnumValue } from "graphql";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { memo, useCallback } from "react";
import { useFieldArray } from "react-hook-form";

export const ListFieldEnumCheckbox = memo(
  ({
    fieldName,
    enumValue,
    listName,
  }: {
    fieldName: string;
    enumValue: GraphQLEnumValue;
    listName: string;
  }) => {
    const { append, fields } = useFieldArray({
      name: listName,
    });
    const currentIndex = fields.findIndex(
      (field) =>
        // isObjectStructure
        (field as { [Key: string]: unknown })[fieldName]
      // : field === enumValue.value
    );

    const onChange = useCallback(
      (e: CheckboxChangeParams) => {
        if (e.checked) {
          // if (isObjectStructure) {
          append({ [fieldName]: enumValue.value });
          // } else {
          //   append({
          //     [fieldName]: enumValue.value,
          //     toJSON: function () {
          //       return (this as any)[fieldName];
          //     },
          //   });
          // }
        } else {
          alert("todo");
        }
      },
      [fieldName, enumValue, append]
    );

    return (
      <div className="field field-checkbox">
        <Checkbox
          inputId={enumValue.name}
          onChange={onChange}
          checked={currentIndex >= 0}
        />
        <label htmlFor={enumValue.name}>{enumValue.name}</label>
      </div>
    );
  }
);
