import { Column } from "primereact/column";
import { DataTable, DataTableSelectionChangeParams } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { memo, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDefinitionsByIdsQuery, useNodeContainerAppDefinitionsQuery, useSetNodeAppCustomTagMutation } from "../../../graphql/generated/graphql";
import { NodeAppEditEnvironment } from "./node-app-edit-environment";
import { NodeAppsListMenu } from "./node-apps-list-menu";
import { NodeDefaultTag } from "./node-default-tag";

export const NodeAppsList = memo(function NodeAppsList() {
  const { nodeId } = useParams();
  const { data, refetch } = useNodeContainerAppDefinitionsQuery({
    variables: { id: nodeId! },
  });
  const [setNodeAppCustomTag] = useSetNodeAppCustomTagMutation();

  const appDefinitionIds = data?.node?.byId?.appDefinitionIds;

  const { data: appDefinitionIdsData } = useAppDefinitionsByIdsQuery({
    variables: {
      id: appDefinitionIds ?? [],
    },
  });

  const appDefinitionIdsDataWithCustomTag = useMemo(() => {
    return appDefinitionIdsData?.appDefinition?.byIds.map((appDefinition) => {
      return {
        ...appDefinition,
        customTag: data?.node?.byId?.nodeContainerOverrides.find((override) => override.containerAppId === appDefinition.id)?.customTag ?? "",
      };
    });
  }, [appDefinitionIdsData, data]);

  const [selectedAppDefinition, setSelectedAppDefinition] = useState<{ id?: string } | null>(null);
  const onSelectionChange = useCallback((e: DataTableSelectionChangeParams) => {
    setSelectedAppDefinition(e.value);
  }, []);

  const onCellEditComplete = useCallback(
    async (e: any) => {
      const { rowData, newValue } = e;

      await setNodeAppCustomTag({
        variables: {
          nodeId: nodeId!,
          containerAppDefinitionId: rowData.id,
          customTag: newValue,
        },
      });

      await refetch();
    },
    [nodeId, setNodeAppCustomTag, refetch]
  );

  return (
    <div className="flex flex-column mr-5" style={{ width: "calc(100vw - 21rem)" }}>
      <div className="p-card flex flex-1 flex-column">
        <div className="p-card-body flex flex-1 flex-column">
          <div className="flex flex-1 flex-column">
            <DataTable
              className="flex-1"
              selectionMode="single"
              value={appDefinitionIdsDataWithCustomTag ?? []}
              selection={selectedAppDefinition}
              onSelectionChange={onSelectionChange}
              header={
                <div className="flex-1 flex">
                  <div>
                    <NodeAppsListMenu id={selectedAppDefinition?.id ?? null} />
                  </div>
                  <div className="flex-1"></div>
                  <div>
                    <NodeDefaultTag />
                  </div>
                </div>
              }
            >
              <Column field="imageName" header="ImageName" />
              <Column field="containerName" header="ContainerName" />
              <Column
                field="customTag"
                header="CustomTag"
                onCellEditComplete={onCellEditComplete}
                editor={(options) => <InputText type="text" value={options.value} onChange={(e) => options.editorCallback?.(e.target.value)} />}
              />
              <Column body={NodeAppEditEnvironment} />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
});
