import { GraphQLInputField } from "graphql";
import { Checkbox } from "primereact/checkbox";
import { memo, useEffect } from "react";
import { useController } from "react-hook-form";

export const CheckboxField = memo(function CheckboxField({ field, name }: { field: GraphQLInputField; name: string }) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name });

  useEffect(() => {
    onChange(!value ? false : true);
  }, [onChange, value]);

  return (
    <div className="field">
      <Checkbox checked={value} onChange={onChange} onBlur={onBlur} className={!!error ? "p-invalid" : ""} />
      <label className="p-checkbox-label ml-2">{field.name}</label>
      {error && <small>{error.type}</small>}
    </div>
  );
});
