import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { InputNumber, InputNumberChangeParams } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import { K8sItem } from "../../graphql/generated/graphql";

export const K8sInput = memo(function K8sInput({ k8sItem, onChange, preValue }: { k8sItem: K8sItem; preValue: string | null; onChange: (key: string, value: string) => void }) {
  // preValue = preValue ?? k8sItem.preValue;

  const [innerValue, setInnerValue] = useState<string | null>(null);

  useEffect(() => {
    setInnerValue(preValue ?? k8sItem.preValue);
  }, [k8sItem.preValue, preValue]);

  const onChangeText = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value ?? preValue ?? "";
      onChange(k8sItem.name, newValue);
      setInnerValue(newValue);
    },
    [k8sItem.name, onChange, preValue]
  );
  const onChangeNumber = useCallback(
    (e: InputNumberChangeParams) => {
      const newValue = e.value?.toString() ?? preValue?.toString() ?? "";
      onChange(k8sItem.name, newValue);
      setInnerValue(newValue);
    },
    [k8sItem.name, onChange, preValue]
  );
  const onChangeBool = useCallback(
    (e: CheckboxChangeParams) => {
      onChange(k8sItem.name, e.checked ? "true" : "false");
      setInnerValue(e.checked ? "true" : "false");
    },
    [k8sItem.name, onChange]
  );

  useEffect(() => {
    onChange(k8sItem.name, preValue ?? k8sItem.preValue);
  }, [k8sItem.name, k8sItem.preValue, onChange, preValue]);

  return (
    <div className="field">
      <label htmlFor={k8sItem.name}>{k8sItem.name}</label>
      {k8sItem.type === "string" && <InputText value={innerValue ?? undefined} onChange={onChangeText} />}
      {k8sItem.type === "number" && <InputNumber value={innerValue ? parseInt(innerValue, 10) : undefined} onChange={onChangeNumber} />}
      {k8sItem.type === "bool" && <Checkbox checked={innerValue === "true"} onChange={onChangeBool} />}
    </div>
  );
});
