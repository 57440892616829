import { memo } from "react";
import { SideMenu } from "../../components/side-menu/side-menu";
import { NodesList } from "./components/nodes-list";

export const Nodes = memo(function Nodes() {
  return (
    <div className="flex flex-1">
      <SideMenu />
      <NodesList />
    </div>
  );
});
