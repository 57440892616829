import { memo } from "react";
import { SideMenu } from "../../components/side-menu/side-menu";

export const Users = memo(function Users() {
  return (
    <div className="flex flex-1">
      <SideMenu />
    </div>
  );
});
