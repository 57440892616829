import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { memo, useCallback } from "react";
import { GraphqlForm } from "../../../components/graphql-form/graphql-form";
import { RegisterFeatureInput, useRegisterFeatureMutation } from "../../../graphql/generated/graphql";
import { useElementVisibility } from "../../../hooks/useElementVisibility";
const schemaFile = require("../../../schema.graphql");

export const FeatureRegister = memo(function FeatureRegister() {
  const [registerFeature] = useRegisterFeatureMutation();
  const { elementVisibility, hideElement, showElement } = useElementVisibility(false);
  const onSubmit = useCallback(
    async (values: RegisterFeatureInput) => {
      await registerFeature({
        variables: {
          feature: values,
        },
      });
    },
    [registerFeature]
  );

  return (
    <>
      <Dialog onHide={hideElement} visible={elementVisibility} header="Add Feature">
        <div className="p-fluid">
          <GraphqlForm<RegisterFeatureInput> schemaFile={schemaFile} inputTypeName="RegisterFeatureInput" onSubmit={onSubmit} />
        </div>
      </Dialog>
      <Button label="Add Feature" icon={<Icon path={mdiPlus} size={0.7} />} onClick={showElement} />
    </>
  );
});
