import { GraphQLInputField } from "graphql";
import { memo } from "react";
import { useWatch } from "react-hook-form";
import { InputField } from "../input-field";

export const ListFieldObject = memo(
  ({
    objectFields,
    listName,
    enumValue,
    fieldName,
  }: {
    objectFields: GraphQLInputField[];
    listName: string;
    enumValue: string;
    fieldName: string;
  }) => {
    const enumList = useWatch({ name: listName }) as {
      [key: string]: unknown;
    }[];

    let index = -1;
    if (enumList) {
      index = enumList.findIndex((item) => item[fieldName] === enumValue);
    }

    if (index < 0) {
      return null;
    }

    return (
      <>
        {objectFields.map((objectField) => (
          <div className="col" key={objectField.name}>
            <InputField
              field={objectField}
              name={`${listName}.${index}.${objectField.name}`}
            />
          </div>
        ))}
      </>
    );
  }
);
