import { GraphQLInputField } from "graphql";
import { InputNumber } from "primereact/inputnumber";
import { memo } from "react";
import { useController } from "react-hook-form";
import { useIsIntRange } from "./hooks/is-int-range";
import { useIsYearFuture } from "./hooks/is-year-future";
import { IntFieldRange } from "./int-field-range";
import { YearFutureRange } from "./year-future-range";

export const IntField = memo(function IntField({
  field,
  name,
}: {
  field: GraphQLInputField;
  name: string;
}) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name, rules: { required: true } });

  const intRange = useIsIntRange(field);
  const yearFuture = useIsYearFuture(field);

  if (intRange) {
    return (
      <IntFieldRange
        field={field}
        name={name}
        startValue={intRange.startValue}
        endValue={intRange.endValue}
      />
    );
  }

  if (yearFuture) {
    return (
      <YearFutureRange
        field={field}
        name={name}
        yearFuture={yearFuture.yearFuture}
      />
    );
  }

  return (
    <div className="field">
      <label>{field.name}</label>
      <InputNumber
        onChange={(e) => {
          onChange(e.value);
        }}
        onBlur={onBlur}
        value={value}
        className={!!error ? "p-invalid" : ""}
      />
    </div>
  );
});
