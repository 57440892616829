import { GraphQLEnumType, GraphQLInputField } from "graphql";
import { memo, useMemo } from "react";
import { EnumFieldDropdown } from "./enum-field-dropdown";
import EnumFieldRadio from "./enum-field-radio";

export const EnumField = memo(
  ({
    field,
    type,
    name,
  }: {
    field: GraphQLInputField;
    type: GraphQLEnumType;
    name: string;
  }) => {
    const asRadio = useMemo(
      () =>
        field.astNode?.directives?.some(
          (item) => item.name.value === "asRadio"
        ) ?? false,
      [field.astNode?.directives]
    );

    return (
      <>
        {!asRadio && (
          <EnumFieldDropdown field={field} type={type} name={name} />
        )}
        {asRadio && <EnumFieldRadio field={field} type={type} name={name} />}
      </>
    );
  }
);
