import { memo } from "react";
import { SideMenu } from "../../components/side-menu/side-menu";

export const Dashboard = memo(function Dashboard() {
  return (
    <div className="flex">
      <SideMenu />
      <div>test</div>
    </div>
  );
});
