import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { memo, useCallback } from "react";
import { GraphqlForm } from "../../../components/graphql-form/graphql-form";
import { EnvironmentVariableGroupInput, useRegisterEnvironmentVariableGroupMutation } from "../../../graphql/generated/graphql";
import { useElementVisibility } from "../../../hooks/useElementVisibility";
const schemaFile = require("../../../schema.graphql");

export const RegisterEnvironmentVariableGroup = memo(function RegisterEnvironmentVariableGroup() {
  const { elementVisibility, hideElement, showElement } = useElementVisibility(false);
  const [registerEnvironmentVariableGroup] = useRegisterEnvironmentVariableGroupMutation();
  const onSubmit = useCallback(
    async (values: EnvironmentVariableGroupInput) => {
      await registerEnvironmentVariableGroup({
        variables: {
          environmentVariableGroup: values,
        },
      });
    },
    [registerEnvironmentVariableGroup]
  );

  return (
    <>
      <Dialog onHide={hideElement} visible={elementVisibility} header="Add app definition">
        <div className="p-fluid">
          <GraphqlForm<EnvironmentVariableGroupInput> schemaFile={schemaFile} inputTypeName="EnvironmentVariableGroupInput" onSubmit={onSubmit} />
        </div>
      </Dialog>
      <Button label="Add Variable Group" icon={<Icon path={mdiPlus} size={0.7} />} onClick={showElement} />
    </>
  );
});
