import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./app";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import "dayjs/locale/de";
import "dayjs/locale/de-at";
import "dayjs/locale/de-ch";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import dayjs from "dayjs";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { msalConfig } from "./auth-config";

dayjs.extend(localizedFormat);
dayjs.locale(navigator.language);

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App pca={msalInstance} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
