import { useCallback, useState } from "react";

export const useElementVisibility = (
  defaultVisibility = false
): {
  elementVisibility: boolean;
  hideElement: () => void;
  showElement: () => void;
} => {
  const [elementVisibility, setElementVisibility] = useState(defaultVisibility);
  const hideElement = useCallback((): void => {
    setElementVisibility(false);
  }, []);
  const showElement = useCallback((): void => {
    setElementVisibility(true);
  }, []);

  return {
    elementVisibility,
    hideElement,
    showElement,
  };
};
