import { memo } from "react";
import { NodeMenu } from "../../components/node-menu/node-menu";
import { K8sSettings } from "./components/k8s-settings";

export const NodeK8sSettings = memo(function NodeK8sSettings() {
  return (
    <div className="flex flex-1">
      <NodeMenu />
      <K8sSettings />
    </div>
  );
});
