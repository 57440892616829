import { InteractionType, IPublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";

import { MsalProvider } from "@azure/msal-react";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import PrimeReact from "primereact/api";
import "primereact/resources/primereact.css";
import { memo, useEffect } from "react";
import { loginRequest } from "./auth-config";

import { ShipperRouter } from "./shipper-router";

type AppProps = {
  pca: IPublicClientApplication;
};

export const App = memo<AppProps>(function App({ pca }) {
  PrimeReact.ripple = true;

  useEffect(() => {
    if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
      document.getElementById("light-style")?.setAttribute("disabled", "true");
      document.getElementById("dark-style")?.removeAttribute("disabled");
    }

    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (event) => {
      const newColorScheme = event.matches ? "dark" : "light";

      if (newColorScheme === "dark") {
        document.getElementById("light-style")?.setAttribute("disabled", "true");
        document.getElementById("dark-style")?.removeAttribute("disabled");
      } else {
        document.getElementById("light-style")?.removeAttribute("disabled");
        document.getElementById("dark-style")?.setAttribute("disabled", "true");
      }
    });
  }, []);

  // const navigate = useNavigate();
  // const navigationClient = new CustomNavigationClient(navigate);
  // pca.setNavigationClient(navigationClient);
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
        <ShipperRouter />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
});
