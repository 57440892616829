import { Configuration, LogLevel, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: "b32c3512-245b-46ae-9eff-d65763d29a4b",
    authority: "https://login.microsoftonline.com/48af5976-2d28-4b83-b8bc-3be03b2981e0",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest: PopupRequest = {
  scopes: ["User.Read"],
  extraScopesToConsent: ["https://vault.azure.net/user_impersonation"],
};
