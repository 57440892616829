import { memo } from "react";
import { useParams } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { useNodeTokenQuery } from "../../../graphql/generated/graphql";

export const NodeToken = memo(function NodeToken() {
  const { nodeId } = useParams();
  const { data } = useNodeTokenQuery({
    variables: {
      id: nodeId!,
    },
  });

  return (
    <div>
      <div>
        <h3>JWT</h3>
      </div>
      <div>
        <SyntaxHighlighter>{data?.node?.byId?.token ?? ""}</SyntaxHighlighter>
      </div>
      <div>
        <h3>NATS</h3>
      </div>
      <div>
        <SyntaxHighlighter>{data?.node?.byId?.natsCred ?? ""}</SyntaxHighlighter>
      </div>
    </div>
  );
});
