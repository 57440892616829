import { useEffect, useState } from "react";
import { getLoggedInChange$ } from "../rx/logged-in";

export function useIsLoggedIn() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    setLoggedIn(!!localStorage.getItem("token"));

    const subscription = getLoggedInChange$().subscribe(() => {
      setLoggedIn(!!localStorage.getItem("token"));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return { loggedIn };
}
