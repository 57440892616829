import { Column } from "primereact/column";
import { DataTable, DataTableSelectionChangeParams } from "primereact/datatable";
import { memo, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useAllFeaturesQuery, useNodeFeatureIdsQuery } from "../../../graphql/generated/graphql";
import { NodeAddFeature } from "./node-add-feature";
import { NodeRemoveFeature } from "./node-remove-feature";

export const NodeFeaturesList = memo(function NodeFeaturesList() {
  const { nodeId } = useParams();
  const { data: featureIds } = useNodeFeatureIdsQuery({ variables: { id: nodeId! } });
  const { data: allFeatures } = useAllFeaturesQuery();

  const [selectedFeature, setSelectedFeature] = useState<{ id?: string } | null>(null);
  const onSelectionChange = useCallback((e: DataTableSelectionChangeParams) => {
    setSelectedFeature(e.value);
  }, []);

  const allFeaturesFiltered = useMemo(() => {
    return allFeatures?.feature?.all.filter((feature) => {
      return featureIds?.node?.byId?.featureIds?.includes(feature.id);
    });
  }, [allFeatures?.feature?.all, featureIds?.node?.byId?.featureIds]);

  return (
    <div className="flex flex-column mr-5" style={{ width: "calc(100vw - 21rem)" }}>
      <div className="p-card flex flex-1 flex-column">
        <div className="p-card-body flex flex-1 flex-column">
          <div className="flex flex-1 flex-column">
            <DataTable
              className="flex-1"
              selectionMode="single"
              value={allFeaturesFiltered ?? []}
              selection={selectedFeature}
              onSelectionChange={onSelectionChange}
              header={
                <div className="flex-1 flex">
                  <div>
                    <NodeAddFeature />
                  </div>
                  <div>
                    <NodeRemoveFeature featureId={selectedFeature?.id ?? ""} />
                  </div>
                </div>
              }
            >
              <Column field="name" header="Name" />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
});
