import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { Chips, ChipsChangeParams } from "primereact/chips";
import { MultiSelect, MultiSelectChangeParams } from "primereact/multiselect";
import { memo, useCallback, useMemo, useState } from "react";
import { ContainerDLogsFilter } from "./containerd-logs-filter";

export interface ContainerDLogsSettingsProps {
  children: (filter: ContainerDLogsFilter) => React.ReactNode;
}

export const ContainerDLogsSettings = memo<ContainerDLogsSettingsProps>(function ContainerDLogsSettings({ children }) {
  const [reverse, setReserve] = useState(false);
  const [hideLoggers, setHideLoggers] = useState<string[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);

  const onReverseChange = useCallback((e: CheckboxChangeParams) => {
    setReserve(e.checked);
  }, []);
  const onHideLogger = useCallback((logger: string) => {
    setHideLoggers((prev) => [...prev, logger]);
  }, []);
  const onHideLoggersChange = useCallback((e: ChipsChangeParams) => {
    setHideLoggers(e.value);
  }, []);
  const onChangeSelectedLevels = useCallback((e: MultiSelectChangeParams) => {
    setSelectedLevels(e.value);
  }, []);
  const levels = useMemo(() => ["TRACE", "DEBUG", "INFO", "WARN", "ERROR", "CRITICAL"], []);

  return (
    <>
      <div className="flex mb-4">
        <div className="flex align-items-center">
          <Checkbox inputId="reverse" name="reverse" onChange={onReverseChange} checked={reverse} />
          <label htmlFor="ingredient1" className="ml-2">
            Reverse
          </label>
        </div>

        <div className="ml-4">
          <MultiSelect
            value={selectedLevels}
            onChange={onChangeSelectedLevels}
            options={levels}
            // optionLabel="name"
            // display="chip"
            placeholder="Select Level"
            className="w-full md:w-20rem"
          />
        </div>
        <div className="ml-4">
          <Chips value={hideLoggers} onChange={onHideLoggersChange} />
        </div>
      </div>
      {children({ reverse, onHideLogger, hideLoggers, selectedLevels })}
    </>
  );
});
