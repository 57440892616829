import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
};

export type AgentContainerLogMessagesFilter = {
  __typename?: 'AgentContainerLogMessagesFilter';
  hideLoggers: Array<Scalars['String']>;
  reverse: Scalars['Boolean'];
  selectedLevels: Array<Scalars['String']>;
};

export type AppDefinitionCommands = {
  __typename?: 'AppDefinitionCommands';
  registerContainerAppDefinition: Scalars['ID'];
  registerEnvironmentVariableGroup: Scalars['ID'];
  removeContainerAppDefinition: Scalars['ID'];
  updateContainerAppDefinition: Scalars['ID'];
  updateEnvironmentVariableGroup: Scalars['ID'];
};


export type AppDefinitionCommandsRegisterContainerAppDefinitionArgs = {
  appDefinition: RegisterContainerAppDefinitionInput;
};


export type AppDefinitionCommandsRegisterEnvironmentVariableGroupArgs = {
  environmentVariableGroup: EnvironmentVariableGroupInput;
};


export type AppDefinitionCommandsRemoveContainerAppDefinitionArgs = {
  appDefinitionId: Scalars['ID'];
};


export type AppDefinitionCommandsUpdateContainerAppDefinitionArgs = {
  appDefinition: UpdateContainerAppDefinitionInput;
};


export type AppDefinitionCommandsUpdateEnvironmentVariableGroupArgs = {
  environmentVariableGroup: EnvironmentVariableGroupInput;
  environmentVariableGroupId: Scalars['ID'];
};

export type AppDefinitionQuery = {
  __typename?: 'AppDefinitionQuery';
  all: Array<ContainerAppDefinition>;
  allCategories: Array<ContainerAppDefinitionCategory>;
  allEnvironmentVariableGroups: Array<EnvironmentVariableGroup>;
  byId?: Maybe<ContainerAppDefinition>;
  byIds: Array<ContainerAppDefinition>;
  environmentVariableGroupById: EnvironmentVariableGroup;
};


export type AppDefinitionQueryByIdArgs = {
  id: Scalars['ID'];
};


export type AppDefinitionQueryByIdsArgs = {
  id: Array<Scalars['ID']>;
};


export type AppDefinitionQueryEnvironmentVariableGroupByIdArgs = {
  id: Scalars['ID'];
};

export type Commands = {
  __typename?: 'Commands';
  appDefinition?: Maybe<AppDefinitionCommands>;
  feature?: Maybe<FeatureCommands>;
  node?: Maybe<NodeCommands>;
  nodeEvents?: Maybe<NodeEvents>;
  registry?: Maybe<RegistryCommands>;
};

export type ContainerAppDefinition = {
  __typename?: 'ContainerAppDefinition';
  appCategory?: Maybe<Scalars['String']>;
  containerName: Scalars['String'];
  customTag?: Maybe<Scalars['String']>;
  dependsOn?: Maybe<Scalars['String']>;
  engineType: EngineType;
  envs: Array<ContainerEnv>;
  graphqlRequest?: Maybe<Scalars['String']>;
  httpTriggerAfterUpgrade?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageName: Scalars['String'];
  mounts: Array<ContainerMount>;
  ports: Array<ContainerPort>;
  repositoryName?: Maybe<Scalars['String']>;
  restartAlways: Scalars['Boolean'];
};

export type ContainerAppDefinitionCategory = {
  __typename?: 'ContainerAppDefinitionCategory';
  name: Scalars['String'];
};

export type ContainerEnv = {
  __typename?: 'ContainerEnv';
  key: Scalars['String'];
  /** Supports placeholder: {hostIp}, {nodeId} */
  value: Scalars['String'];
};

export type ContainerEnvInput = {
  key: Scalars['String'];
  /** Supports placeholder: {hostIp}, {nodeId} */
  value: Scalars['String'];
};

export type ContainerLogMessages = {
  __typename?: 'ContainerLogMessages';
  containerName: Scalars['String'];
  filter: AgentContainerLogMessagesFilter;
  firstLogEntryIndex: Scalars['Int'];
  lastLogEntryIndex: Scalars['Int'];
};

export type ContainerLogMessagesFilter = {
  hideLoggers: Array<Scalars['String']>;
  reverse: Scalars['Boolean'];
  selectedLevels: Array<Scalars['String']>;
};

export type ContainerLogMessagesInput = {
  containerName: Scalars['String'];
  firstLogEntryIndex: Scalars['Int'];
  lastLogEntryIndex: Scalars['Int'];
  logMessages: Array<Scalars['String']>;
};

export type ContainerMount = {
  __typename?: 'ContainerMount';
  containerPath: Scalars['String'];
  hostPath: Scalars['String'];
};

export type ContainerMountInput = {
  containerPath: Scalars['String'];
  hostPath: Scalars['String'];
};

export type ContainerPort = {
  __typename?: 'ContainerPort';
  containerPort: Scalars['Int'];
  hostPort: Scalars['Int'];
};

export type ContainerPortInput = {
  containerPort: Scalars['Int'];
  hostPort: Scalars['Int'];
};

export enum ContainerState {
  Created = 'Created',
  Exited = 'Exited',
  Running = 'Running',
  Unknown = 'Unknown'
}

export enum EngineType {
  Container = 'Container',
  ContainerHost = 'ContainerHost'
}

export type EnvironmentVariable = {
  __typename?: 'EnvironmentVariable';
  key: Scalars['String'];
  /** Supports placeholder: {hostIp}, {nodeId} */
  value: Scalars['String'];
};

export type EnvironmentVariableGroup = {
  __typename?: 'EnvironmentVariableGroup';
  containerName: Scalars['String'];
  groupName: Scalars['String'];
  id: Scalars['ID'];
  variables: Array<EnvironmentVariable>;
};

export type EnvironmentVariableGroupInput = {
  containerName: Scalars['String'];
  groupName: Scalars['String'];
  variables: Array<EnvironmentVariableInput>;
};

export type EnvironmentVariableInput = {
  key: Scalars['String'];
  /** Supports placeholder: {hostIp}, {nodeId} */
  value: Scalars['String'];
};

export type Feature = {
  __typename?: 'Feature';
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FeatureCommands = {
  __typename?: 'FeatureCommands';
  registerFeature: Scalars['ID'];
};


export type FeatureCommandsRegisterFeatureArgs = {
  feature: RegisterFeatureInput;
};

export type FeatureQuery = {
  __typename?: 'FeatureQuery';
  all: Array<Feature>;
};

export type K8sItem = {
  __typename?: 'K8sItem';
  name: Scalars['String'];
  preValue: Scalars['String'];
  type: Scalars['String'];
};

export type LogMutation = {
  __typename?: 'LogMutation';
  reportContainerLogEntryCount: Scalars['Boolean'];
  reportContainerLogMessages: Scalars['Boolean'];
};


export type LogMutationReportContainerLogEntryCountArgs = {
  containerName: Scalars['String'];
  logEntryCount: Scalars['Int'];
};


export type LogMutationReportContainerLogMessagesArgs = {
  containerLogMessages: ContainerLogMessagesInput;
};

export type LogQuery = {
  __typename?: 'LogQuery';
  containerLogEntryCount: Scalars['Int'];
  containerLogMessages: Array<Scalars['String']>;
};


export type LogQueryContainerLogEntryCountArgs = {
  containerName: Scalars['String'];
  nodeId: Scalars['ID'];
};


export type LogQueryContainerLogMessagesArgs = {
  containerName: Scalars['String'];
  filter: ContainerLogMessagesFilter;
  firstLogEntryIndex: Scalars['Int'];
  lastLogEntryIndex: Scalars['Int'];
  nodeId: Scalars['ID'];
};

export type MachineInformation = {
  buildYear?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  machineDescription?: InputMaybe<Scalars['String']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  serialNumber: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  commands?: Maybe<Commands>;
  log?: Maybe<LogMutation>;
};

export type Node = {
  __typename?: 'Node';
  allActions: Array<NodeActionDetail>;
  appDefinitionIds: Array<Scalars['String']>;
  buildYear?: Maybe<Scalars['String']>;
  composeContainer: Array<ContainerAppDefinition>;
  containerD?: Maybe<NodeContainerDInformation>;
  currentAction?: Maybe<NodeAction>;
  defaultCustomTag: Scalars['String'];
  description: Scalars['String'];
  featureIds: Array<Scalars['String']>;
  host?: Maybe<NodeHost>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  initialized: Scalars['Boolean'];
  k8sValues: Array<NodeK8sItem>;
  lastSeen?: Maybe<Scalars['Time']>;
  location?: Maybe<Scalars['String']>;
  machineDescription?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  natsCred: Scalars['String'];
  nodeContainerOverrides: Array<NodeContainerAppOverride>;
  nodeState?: Maybe<NodeState>;
  nodeStats?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  reportedErrors: Array<NodeError>;
  serialNumber: Scalars['String'];
  stage: Scalars['String'];
  token: Scalars['String'];
  updateRequestedContainer: Array<RequestedContainer>;
  upgradeProgress?: Maybe<UpgradeProgress>;
  vmIpAddress?: Maybe<Scalars['String']>;
  vmLog?: Maybe<Scalars['String']>;
};

export enum NodeAction {
  RequestRestartAllApps = 'RequestRestartAllApps',
  RequestUpgradeAllApps = 'RequestUpgradeAllApps',
  RestartAllApps = 'RestartAllApps',
  UpgradeAllApps = 'UpgradeAllApps'
}

export type NodeActionDetail = {
  __typename?: 'NodeActionDetail';
  action: NodeAction;
  actionId: Scalars['ID'];
  arrangedBy: User;
  arrangedTime: Scalars['Time'];
  finishedTime?: Maybe<Scalars['Time']>;
  startedTime?: Maybe<Scalars['Time']>;
};

export type NodeCommands = {
  __typename?: 'NodeCommands';
  addNodeContainerApps: Scalars['ID'];
  addNodeContainerAppsByCategory: Scalars['ID'];
  arrangeRequestRestartAllApps: Scalars['ID'];
  arrangeRequestUpgradeAllApps: Scalars['ID'];
  arrangeRestartAllApps: Scalars['ID'];
  arrangeUpgradeAllApps: Scalars['ID'];
  destroyVm: Scalars['ID'];
  registerFeature: Scalars['ID'];
  registerNode: Scalars['ID'];
  registerNodeWithK8s: Scalars['ID'];
  registerNodeWithVm: Scalars['ID'];
  removeFeature: Scalars['ID'];
  removeNode: Scalars['ID'];
  removeNodeContainerApps: Scalars['ID'];
  resetUpgradeRestart: Scalars['ID'];
  setContainerEnvironmentVariables: Scalars['ID'];
  setNodeContainerCustomTag: Scalars['ID'];
  setNodeStage: Scalars['ID'];
  updateK8sInformation: Scalars['ID'];
  updateMachineInformation: Scalars['ID'];
  updateNodeContainerDContainerStats: Scalars['ID'];
  updateNodeContainerDInformation: Scalars['ID'];
  updateNodeDefaultCustomTag: Scalars['ID'];
  updateNodeInformation: Scalars['ID'];
  updateNodeStats: Scalars['ID'];
};


export type NodeCommandsAddNodeContainerAppsArgs = {
  appDefinitionIds: Array<Scalars['ID']>;
  nodeId: Scalars['ID'];
};


export type NodeCommandsAddNodeContainerAppsByCategoryArgs = {
  category: Scalars['String'];
  nodeId: Scalars['ID'];
  version: Scalars['String'];
};


export type NodeCommandsArrangeRequestRestartAllAppsArgs = {
  nodeId: Scalars['ID'];
};


export type NodeCommandsArrangeRequestUpgradeAllAppsArgs = {
  nodeId: Scalars['ID'];
};


export type NodeCommandsArrangeRestartAllAppsArgs = {
  nodeId: Scalars['ID'];
};


export type NodeCommandsArrangeUpgradeAllAppsArgs = {
  nodeId: Scalars['ID'];
};


export type NodeCommandsDestroyVmArgs = {
  name: Scalars['String'];
};


export type NodeCommandsRegisterFeatureArgs = {
  featureId: Scalars['ID'];
  nodeId: Scalars['ID'];
};


export type NodeCommandsRegisterNodeArgs = {
  node: RegisterNodeInput;
};


export type NodeCommandsRegisterNodeWithK8sArgs = {
  node: Array<RegisterNodeWithK8s>;
};


export type NodeCommandsRegisterNodeWithVmArgs = {
  node?: InputMaybe<RegisterNodeWithVm>;
};


export type NodeCommandsRemoveFeatureArgs = {
  featureId: Scalars['ID'];
  nodeId: Scalars['ID'];
};


export type NodeCommandsRemoveNodeArgs = {
  nodeId: Scalars['ID'];
};


export type NodeCommandsRemoveNodeContainerAppsArgs = {
  appDefinitionIds: Array<Scalars['ID']>;
  nodeId: Scalars['ID'];
};


export type NodeCommandsResetUpgradeRestartArgs = {
  nodeId: Scalars['ID'];
};


export type NodeCommandsSetContainerEnvironmentVariablesArgs = {
  containerAppDefinitionId: Scalars['ID'];
  containerAppEnvironmentVariables: NodeContainerAppEnvironmentVariableInput;
  nodeId: Scalars['ID'];
};


export type NodeCommandsSetNodeContainerCustomTagArgs = {
  containerAppDefinitionId: Scalars['ID'];
  customTag: Scalars['String'];
  nodeId: Scalars['ID'];
};


export type NodeCommandsSetNodeStageArgs = {
  nodeId: Scalars['ID'];
  stage: Scalars['String'];
};


export type NodeCommandsUpdateK8sInformationArgs = {
  nodeId: Scalars['ID'];
  values: Array<RegisterNodeWithK8s>;
};


export type NodeCommandsUpdateMachineInformationArgs = {
  machineInformation: MachineInformation;
  nodeId: Scalars['ID'];
};


export type NodeCommandsUpdateNodeContainerDContainerStatsArgs = {
  nodeContainerDContainerStats?: InputMaybe<NodeContainerDContainerStatsInput>;
};


export type NodeCommandsUpdateNodeContainerDInformationArgs = {
  nodeContainerDInformation?: InputMaybe<NodeContainerDInformationInput>;
};


export type NodeCommandsUpdateNodeDefaultCustomTagArgs = {
  defaultCustomTag: Scalars['String'];
  nodeId: Scalars['ID'];
};


export type NodeCommandsUpdateNodeInformationArgs = {
  nodeInformation: NodeInformation;
};


export type NodeCommandsUpdateNodeStatsArgs = {
  nodeStats: Scalars['String'];
};

export type NodeContainerAppEnvironmentVariableInput = {
  variables: Array<NodeContainerAppEnvironmentVariableItemInput>;
};

export type NodeContainerAppEnvironmentVariableItem = {
  __typename?: 'NodeContainerAppEnvironmentVariableItem';
  key: Scalars['String'];
  /** Supports placeholder: {hostIp}, {nodeId}, {serialNumber} */
  value: Scalars['String'];
};

export type NodeContainerAppEnvironmentVariableItemInput = {
  key: Scalars['String'];
  /** Supports placeholder: {hostIp}, {nodeId}, {serialNumber} */
  value: Scalars['String'];
};

export type NodeContainerAppOverride = {
  __typename?: 'NodeContainerAppOverride';
  containerAppId: Scalars['String'];
  customTag?: Maybe<Scalars['String']>;
  variables: Array<NodeContainerAppEnvironmentVariableItem>;
};

export type NodeContainerDContainerStateInput = {
  containerName: Scalars['String'];
  containerState: ContainerState;
  cpuPerc: Scalars['Float'];
  createdAt: Scalars['Time'];
  mem: Scalars['Float'];
};

export type NodeContainerDContainerStats = {
  __typename?: 'NodeContainerDContainerStats';
  appDefinitionId: Scalars['ID'];
  containerName: Scalars['String'];
  containerState: ContainerState;
  cpuPerc: Scalars['Float'];
  createdAt: Scalars['Time'];
  digest: Scalars['String'];
  mem: Scalars['Float'];
  version: Scalars['String'];
};

export type NodeContainerDContainerStatsInput = {
  containers: Array<NodeContainerDContainerStateInput>;
};

export type NodeContainerDInformation = {
  __typename?: 'NodeContainerDInformation';
  containers: Array<NodeContainerDContainerStats>;
  newContainerVersion: Scalars['String'];
  newContainerVersionAvailable: Scalars['Boolean'];
  runtimeApiVersion: Scalars['String'];
  runtimeName: Scalars['String'];
  runtimeVersion: Scalars['String'];
  version: Scalars['String'];
};

export type NodeContainerDInformationInput = {
  runtimeApiVersion: Scalars['String'];
  runtimeName: Scalars['String'];
  runtimeVersion: Scalars['String'];
  version: Scalars['String'];
};

export type NodeError = {
  __typename?: 'NodeError';
  error: Scalars['String'];
  time: Scalars['Time'];
};

export type NodeEvents = {
  __typename?: 'NodeEvents';
  errorReported: Scalars['ID'];
  requestRestartAllAppsFinished: Scalars['ID'];
  requestRestartAllAppsStarted: Scalars['ID'];
  requestUpgradeAllAppsFinished: Scalars['ID'];
  requestUpgradeAllAppsStarted: Scalars['ID'];
  restartAllAppsFinished: Scalars['ID'];
  restartAllAppsStarted: Scalars['ID'];
  upgradeAllAppsFinished: Scalars['ID'];
  upgradeAllAppsStarted: Scalars['ID'];
};


export type NodeEventsErrorReportedArgs = {
  error: Scalars['String'];
  time: Scalars['Time'];
};


export type NodeEventsUpgradeAllAppsFinishedArgs = {
  nodeResponse: Array<UpgradeAllAppsResponseInput>;
};

export type NodeHost = {
  __typename?: 'NodeHost';
  architecture: Scalars['String'];
  bootTime: Scalars['Time'];
  hostName: Scalars['String'];
  ips: Array<Scalars['String']>;
  kernelVersion: Scalars['String'];
  os?: Maybe<OsInfo>;
  timezone: Scalars['String'];
  timezoneOffsetSec: Scalars['Int'];
  uniqueId: Scalars['String'];
};

export type NodeHostInput = {
  architecture: Scalars['String'];
  bootTime: Scalars['Time'];
  hostName: Scalars['String'];
  ips: Array<Scalars['String']>;
  kernelVersion: Scalars['String'];
  os?: InputMaybe<OsInfoInput>;
  timezone: Scalars['String'];
  timezoneOffsetSec: Scalars['Int'];
  uniqueId: Scalars['String'];
};

export type NodeInformation = {
  nodeHost: NodeHostInput;
};

export type NodeK8sItem = {
  __typename?: 'NodeK8sItem';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type NodeQuery = {
  __typename?: 'NodeQuery';
  all: Array<Node>;
  availableStages?: Maybe<Array<Maybe<NodeStage>>>;
  byId?: Maybe<Node>;
  current?: Maybe<Node>;
  k8sValues: Array<K8sItem>;
};


export type NodeQueryByIdArgs = {
  id: Scalars['ID'];
};

export type NodeStage = {
  __typename?: 'NodeStage';
  displayName: Scalars['String'];
  stageName: Scalars['String'];
};

export enum NodeState {
  IsRestarting = 'IsRestarting',
  IsUpgrading = 'IsUpgrading'
}

export type OsInfo = {
  __typename?: 'OsInfo';
  build: Scalars['String'];
  codename: Scalars['String'];
  family: Scalars['String'];
  major: Scalars['Int'];
  minor: Scalars['Int'];
  name: Scalars['String'];
  patch: Scalars['Int'];
  platform: Scalars['String'];
  type: Scalars['String'];
  version: Scalars['String'];
};

export type OsInfoInput = {
  build: Scalars['String'];
  codename: Scalars['String'];
  family: Scalars['String'];
  major: Scalars['Int'];
  minor: Scalars['Int'];
  name: Scalars['String'];
  patch: Scalars['Int'];
  platform: Scalars['String'];
  type: Scalars['String'];
  version: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  appDefinition?: Maybe<AppDefinitionQuery>;
  feature?: Maybe<FeatureQuery>;
  log?: Maybe<LogQuery>;
  node?: Maybe<NodeQuery>;
  registry?: Maybe<RegistryQuery>;
  user?: Maybe<UserQuery>;
  version: Scalars['String'];
};

export type RegisterContainerAppDefinitionInput = {
  appCategory?: InputMaybe<Scalars['String']>;
  containerName: Scalars['String'];
  customTag?: InputMaybe<Scalars['String']>;
  dependsOn?: InputMaybe<Scalars['String']>;
  engineType: EngineType;
  envs: Array<ContainerEnvInput>;
  graphqlRequest?: InputMaybe<Scalars['String']>;
  httpTriggerAfterUpgrade?: InputMaybe<Scalars['String']>;
  imageName: Scalars['String'];
  mounts: Array<ContainerMountInput>;
  ports: Array<ContainerPortInput>;
  repositoryName?: InputMaybe<Scalars['String']>;
  restartAlways: Scalars['Boolean'];
};

export type RegisterFeatureInput = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type RegisterNodeInput = {
  buildYear?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  machineDescription?: InputMaybe<Scalars['String']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  owner?: InputMaybe<Scalars['String']>;
  serialNumber: Scalars['String'];
};

export type RegisterNodeWithK8s = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type RegisterNodeWithVm = {
  category: Scalars['String'];
  name: Scalars['String'];
  serialNumber: Scalars['String'];
  stage: Scalars['String'];
  variableGroupName: Scalars['String'];
  version: Scalars['String'];
};

export type RegisterRegistryInput = {
  name: Scalars['String'];
  password: Scalars['String'];
  registryType: RegistryType;
  url: Scalars['String'];
  username: Scalars['String'];
};

export type Registry = {
  __typename?: 'Registry';
  id: Scalars['ID'];
  name: Scalars['String'];
  password: Scalars['String'];
  registryType: RegistryType;
  url: Scalars['String'];
  username: Scalars['String'];
};

export type RegistryCommands = {
  __typename?: 'RegistryCommands';
  registerRegistry: Scalars['ID'];
};


export type RegistryCommandsRegisterRegistryArgs = {
  registry: RegisterRegistryInput;
};

export type RegistryQuery = {
  __typename?: 'RegistryQuery';
  all: Array<Registry>;
};

export enum RegistryType {
  ContainerRegistry = 'ContainerRegistry'
}

export type RequestedContainer = {
  __typename?: 'RequestedContainer';
  appDefinitionId: Scalars['String'];
  digest: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['Boolean']>;
  nodeActionUpdated?: Maybe<NodeAction>;
  nodeLastSeenUpdated?: Maybe<Scalars['Time']>;
  nodeNewContainerVersionAvailable?: Maybe<Node>;
  nodeStateUpdated?: Maybe<NodeState>;
  requestContainerLogEntryCount: Scalars['String'];
  requestContainerLogMessages: ContainerLogMessages;
  vmLogUpdated?: Maybe<Scalars['String']>;
};


export type SubscriptionNodeLastSeenUpdatedArgs = {
  nodeId: Scalars['ID'];
};


export type SubscriptionNodeStateUpdatedArgs = {
  nodeId: Scalars['ID'];
};


export type SubscriptionVmLogUpdatedArgs = {
  nodeId: Scalars['ID'];
};

export type UpdateContainerAppDefinitionInput = {
  appCategory?: InputMaybe<Scalars['String']>;
  containerName: Scalars['String'];
  customTag?: InputMaybe<Scalars['String']>;
  dependsOn?: InputMaybe<Scalars['String']>;
  engineType: EngineType;
  envs: Array<ContainerEnvInput>;
  graphqlRequest?: InputMaybe<Scalars['String']>;
  httpTriggerAfterUpgrade?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  imageName: Scalars['String'];
  mounts: Array<ContainerMountInput>;
  ports: Array<ContainerPortInput>;
  repositoryName?: InputMaybe<Scalars['String']>;
  restartAlways: Scalars['Boolean'];
};

export type UpgradeAllAppsResponseInput = {
  appDefinitionId: Scalars['ID'];
  containerName: Scalars['String'];
  digest: Scalars['String'];
};

export type UpgradeProgress = {
  __typename?: 'UpgradeProgress';
  message: Scalars['String'];
  progress: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  firstname: Scalars['String'];
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
  lastname: Scalars['String'];
};

export type UserQuery = {
  __typename?: 'UserQuery';
  all: Array<User>;
  current?: Maybe<User>;
};

export type AllAppDefinitionCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllAppDefinitionCategoriesQuery = { __typename?: 'Query', appDefinition?: { __typename?: 'AppDefinitionQuery', allCategories: Array<{ __typename?: 'ContainerAppDefinitionCategory', name: string }> } | null };

export type AllAppDefinitionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllAppDefinitionsQuery = { __typename?: 'Query', appDefinition?: { __typename?: 'AppDefinitionQuery', all: Array<{ __typename?: 'ContainerAppDefinition', id: string, imageName: string, containerName: string }> } | null };

export type AllEnvironmentVariableGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllEnvironmentVariableGroupsQuery = { __typename?: 'Query', appDefinition?: { __typename?: 'AppDefinitionQuery', allEnvironmentVariableGroups: Array<{ __typename?: 'EnvironmentVariableGroup', containerName: string, groupName: string, id: string }> } | null };

export type AppDefinitionsByIdsQueryVariables = Exact<{
  id: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type AppDefinitionsByIdsQuery = { __typename?: 'Query', appDefinition?: { __typename?: 'AppDefinitionQuery', byIds: Array<{ __typename?: 'ContainerAppDefinition', imageName: string, containerName: string, id: string }> } | null };

export type EnvironmentVariableGroupByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EnvironmentVariableGroupByIdQuery = { __typename?: 'Query', appDefinition?: { __typename?: 'AppDefinitionQuery', environmentVariableGroupById: { __typename?: 'EnvironmentVariableGroup', containerName: string, groupName: string, variables: Array<{ __typename?: 'EnvironmentVariable', key: string, value: string }> } } | null };

export type GetContainerAppDefinitionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetContainerAppDefinitionQuery = { __typename?: 'Query', appDefinition?: { __typename?: 'AppDefinitionQuery', byId?: { __typename?: 'ContainerAppDefinition', id: string, imageName: string, containerName: string, appCategory?: string | null, repositoryName?: string | null, customTag?: string | null, engineType: EngineType, restartAlways: boolean, graphqlRequest?: string | null, httpTriggerAfterUpgrade?: string | null, dependsOn?: string | null, mounts: Array<{ __typename?: 'ContainerMount', hostPath: string, containerPath: string }>, envs: Array<{ __typename?: 'ContainerEnv', key: string, value: string }>, ports: Array<{ __typename?: 'ContainerPort', containerPort: number, hostPort: number }> } | null } | null };

export type RegisterContainerAppDefinitionMutationVariables = Exact<{
  containerAppDefinition: RegisterContainerAppDefinitionInput;
}>;


export type RegisterContainerAppDefinitionMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', appDefinition?: { __typename?: 'AppDefinitionCommands', registerContainerAppDefinition: string } | null } | null };

export type RegisterEnvironmentVariableGroupMutationVariables = Exact<{
  environmentVariableGroup: EnvironmentVariableGroupInput;
}>;


export type RegisterEnvironmentVariableGroupMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', appDefinition?: { __typename?: 'AppDefinitionCommands', registerEnvironmentVariableGroup: string } | null } | null };

export type RemoveAppDefinitionMutationVariables = Exact<{
  appDefinitionId: Scalars['ID'];
}>;


export type RemoveAppDefinitionMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', appDefinition?: { __typename?: 'AppDefinitionCommands', removeContainerAppDefinition: string } | null } | null };

export type UpdateContainerAppDefinitionMutationVariables = Exact<{
  containerAppDefinition: UpdateContainerAppDefinitionInput;
}>;


export type UpdateContainerAppDefinitionMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', appDefinition?: { __typename?: 'AppDefinitionCommands', updateContainerAppDefinition: string } | null } | null };

export type UpdateEnvironmentVariableGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  environmentVariableGroup: EnvironmentVariableGroupInput;
}>;


export type UpdateEnvironmentVariableGroupMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', appDefinition?: { __typename?: 'AppDefinitionCommands', updateEnvironmentVariableGroup: string } | null } | null };

export type AllFeaturesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllFeaturesQuery = { __typename?: 'Query', feature?: { __typename?: 'FeatureQuery', all: Array<{ __typename?: 'Feature', id: string, name: string, description: string }> } | null };

export type RegisterFeatureMutationVariables = Exact<{
  feature: RegisterFeatureInput;
}>;


export type RegisterFeatureMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', feature?: { __typename?: 'FeatureCommands', registerFeature: string } | null } | null };

export type ContainerLogEntryCountQueryVariables = Exact<{
  nodeId: Scalars['ID'];
  containerName: Scalars['String'];
}>;


export type ContainerLogEntryCountQuery = { __typename?: 'Query', log?: { __typename?: 'LogQuery', containerLogEntryCount: number } | null };

export type ContainerLogMessagesQueryVariables = Exact<{
  nodeId: Scalars['ID'];
  containerName: Scalars['String'];
  firstLogEntryIndex: Scalars['Int'];
  lastLogEntryIndex: Scalars['Int'];
  filter: ContainerLogMessagesFilter;
}>;


export type ContainerLogMessagesQuery = { __typename?: 'Query', log?: { __typename?: 'LogQuery', containerLogMessages: Array<string> } | null };

export type AddNodeContainerAppsMutationVariables = Exact<{
  nodeId: Scalars['ID'];
  appDefinitionIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type AddNodeContainerAppsMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', addNodeContainerApps: string } | null } | null };

export type AddFeatureToNodeMutationVariables = Exact<{
  nodeId: Scalars['ID'];
  featureId: Scalars['ID'];
}>;


export type AddFeatureToNodeMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', registerFeature: string } | null } | null };

export type AddNodeContainerAppsByCategoryMutationVariables = Exact<{
  nodeId: Scalars['ID'];
  category: Scalars['String'];
  version: Scalars['String'];
}>;


export type AddNodeContainerAppsByCategoryMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', addNodeContainerAppsByCategory: string } | null } | null };

export type AllNodesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllNodesQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', all: Array<{ __typename?: 'Node', id: string, name: string, lastSeen?: any | null }> } | null };

export type ArrangeRequestRestartAllAppsMutationVariables = Exact<{
  nodeId: Scalars['ID'];
}>;


export type ArrangeRequestRestartAllAppsMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', arrangeRequestRestartAllApps: string } | null } | null };

export type ArrangeRequestUpgradeAllAppsMutationVariables = Exact<{
  nodeId: Scalars['ID'];
}>;


export type ArrangeRequestUpgradeAllAppsMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', arrangeRequestUpgradeAllApps: string } | null } | null };

export type ArrangeRestartAllAppsMutationVariables = Exact<{
  nodeId: Scalars['ID'];
}>;


export type ArrangeRestartAllAppsMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', arrangeRestartAllApps: string } | null } | null };

export type ArrangeUpgradeAllAppsMutationVariables = Exact<{
  nodeId: Scalars['ID'];
}>;


export type ArrangeUpgradeAllAppsMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', arrangeUpgradeAllApps: string } | null } | null };

export type GetMachineInformationQueryVariables = Exact<{
  nodeId: Scalars['ID'];
}>;


export type GetMachineInformationQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', machineDescription?: string | null, imageUrl?: string | null, manufacturer?: string | null, location?: string | null, serialNumber: string, buildYear?: string | null, owner?: string | null } | null } | null };

export type K8sValuesQueryVariables = Exact<{ [key: string]: never; }>;


export type K8sValuesQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', k8sValues: Array<{ __typename?: 'K8sItem', name: string, type: string, preValue: string }> } | null };

export type NodeActionsListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NodeActionsListQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', allActions: Array<{ __typename?: 'NodeActionDetail', action: NodeAction, arrangedTime: any, finishedTime?: any | null, startedTime?: any | null, arrangedBy: { __typename?: 'User', firstname: string, lastname: string } }> } | null } | null };

export type NodeContainerEnvironmentVariablesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NodeContainerEnvironmentVariablesQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', nodeContainerOverrides: Array<{ __typename?: 'NodeContainerAppOverride', containerAppId: string, variables: Array<{ __typename?: 'NodeContainerAppEnvironmentVariableItem', key: string, value: string }> }> } | null } | null };

export type AvailableStagesQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableStagesQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', availableStages?: Array<{ __typename?: 'NodeStage', displayName: string, stageName: string } | null> | null } | null };

export type NodeContainerAppDefinitionsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NodeContainerAppDefinitionsQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', appDefinitionIds: Array<string>, nodeContainerOverrides: Array<{ __typename?: 'NodeContainerAppOverride', containerAppId: string, customTag?: string | null }> } | null } | null };

export type NodeContainerDQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NodeContainerDQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', containerD?: { __typename?: 'NodeContainerDInformation', version: string, runtimeName: string, runtimeVersion: string, runtimeApiVersion: string } | null } | null } | null };

export type NodeContainerDStatsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NodeContainerDStatsQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', containerD?: { __typename?: 'NodeContainerDInformation', containers: Array<{ __typename?: 'NodeContainerDContainerStats', containerName: string, containerState: ContainerState, createdAt: any, cpuPerc: number, mem: number }> } | null } | null } | null };

export type NodeDefaultCustomTagQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NodeDefaultCustomTagQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', defaultCustomTag: string } | null } | null };

export type NodeFeatureIdsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NodeFeatureIdsQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', featureIds: Array<string> } | null } | null };

export type GetNodeStageQueryVariables = Exact<{
  nodeId: Scalars['ID'];
}>;


export type GetNodeStageQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', stage: string } | null } | null };

export type NodeInformationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NodeInformationQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', nodeState?: NodeState | null, lastSeen?: any | null, vmIpAddress?: string | null, upgradeProgress?: { __typename?: 'UpgradeProgress', progress: number, message: string } | null, host?: { __typename?: 'NodeHost', architecture: string, bootTime: any, hostName: string, ips: Array<string>, kernelVersion: string, timezone: string, timezoneOffsetSec: number, uniqueId: string, os?: { __typename?: 'OsInfo', type: string, family: string, platform: string, name: string, version: string, major: number, minor: number, patch: number, build: string, codename: string } | null } | null } | null } | null };

export type NodeInitializedQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NodeInitializedQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', initialized: boolean } | null } | null };

export type NodeK8sValuesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NodeK8sValuesQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', k8sValues: Array<{ __typename?: 'NodeK8sItem', key: string, value: string }> } | null } | null };

export type NodeLastSeenUpdatedSubscriptionVariables = Exact<{
  nodeId: Scalars['ID'];
}>;


export type NodeLastSeenUpdatedSubscription = { __typename?: 'Subscription', nodeLastSeenUpdated?: any | null };

export type NodeNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NodeNameQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', name: string } | null } | null };

export type NodeReportedErrorsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NodeReportedErrorsQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', id: string, reportedErrors: Array<{ __typename?: 'NodeError', time: any, error: string }> } | null } | null };

export type NodeStateUpdatedSubscriptionVariables = Exact<{
  nodeId: Scalars['ID'];
}>;


export type NodeStateUpdatedSubscription = { __typename?: 'Subscription', nodeStateUpdated?: NodeState | null };

export type NodeStatsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NodeStatsQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', nodeStats?: string | null } | null } | null };

export type NodeTokenQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NodeTokenQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', token: string, natsCred: string } | null } | null };

export type VmLogQueryQueryVariables = Exact<{
  nodeId: Scalars['ID'];
}>;


export type VmLogQueryQuery = { __typename?: 'Query', node?: { __typename?: 'NodeQuery', byId?: { __typename?: 'Node', vmLog?: string | null } | null } | null };

export type VmLogSubscriptionSubscriptionVariables = Exact<{
  nodeId: Scalars['ID'];
}>;


export type VmLogSubscriptionSubscription = { __typename?: 'Subscription', vmLogUpdated?: string | null };

export type RegisterK8sNodeMutationVariables = Exact<{
  input: Array<RegisterNodeWithK8s> | RegisterNodeWithK8s;
}>;


export type RegisterK8sNodeMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', registerNodeWithK8s: string } | null } | null };

export type RegisterNodeWithVmMutationVariables = Exact<{
  nodeWithVm: RegisterNodeWithVm;
}>;


export type RegisterNodeWithVmMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', registerNodeWithVm: string } | null } | null };

export type RegisterNodeMutationVariables = Exact<{
  node: RegisterNodeInput;
}>;


export type RegisterNodeMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', registerNode: string } | null } | null };

export type RemoveNodeContainerAppsMutationVariables = Exact<{
  nodeId: Scalars['ID'];
  appDefinitionIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type RemoveNodeContainerAppsMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', removeNodeContainerApps: string } | null } | null };

export type RemoveFeatureFromNodeMutationVariables = Exact<{
  nodeId: Scalars['ID'];
  featureId: Scalars['ID'];
}>;


export type RemoveFeatureFromNodeMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', removeFeature: string } | null } | null };

export type RemoveNodeMutationVariables = Exact<{
  nodeId: Scalars['ID'];
}>;


export type RemoveNodeMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', removeNode: string } | null } | null };

export type SetNodeAppEnvironmentMutationVariables = Exact<{
  nodeId: Scalars['ID'];
  containerAppDefinitionId: Scalars['ID'];
  containerAppEnv: NodeContainerAppEnvironmentVariableInput;
}>;


export type SetNodeAppEnvironmentMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', setContainerEnvironmentVariables: string } | null } | null };

export type SetNodeAppCustomTagMutationVariables = Exact<{
  nodeId: Scalars['ID'];
  containerAppDefinitionId: Scalars['ID'];
  customTag: Scalars['String'];
}>;


export type SetNodeAppCustomTagMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', setNodeContainerCustomTag: string } | null } | null };

export type SetNodeStageMutationVariables = Exact<{
  nodeId: Scalars['ID'];
  stage: Scalars['String'];
}>;


export type SetNodeStageMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', setNodeStage: string } | null } | null };

export type UpdateK8sInformationMutationVariables = Exact<{
  nodeId: Scalars['ID'];
  values: Array<RegisterNodeWithK8s> | RegisterNodeWithK8s;
}>;


export type UpdateK8sInformationMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', updateK8sInformation: string } | null } | null };

export type UpdateMachineInformationMutationVariables = Exact<{
  nodeId: Scalars['ID'];
  machineInformation: MachineInformation;
}>;


export type UpdateMachineInformationMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', updateMachineInformation: string } | null } | null };

export type UpdateNodeDefaultCustomTagMutationVariables = Exact<{
  nodeId: Scalars['ID'];
  defaultCustomTag: Scalars['String'];
}>;


export type UpdateNodeDefaultCustomTagMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', node?: { __typename?: 'NodeCommands', updateNodeDefaultCustomTag: string } | null } | null };

export type AllRegistriesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllRegistriesQuery = { __typename?: 'Query', registry?: { __typename?: 'RegistryQuery', all: Array<{ __typename?: 'Registry', id: string, name: string, url: string }> } | null };

export type RegisterRegistryMutationVariables = Exact<{
  registry: RegisterRegistryInput;
}>;


export type RegisterRegistryMutation = { __typename?: 'Mutation', commands?: { __typename?: 'Commands', registry?: { __typename?: 'RegistryCommands', registerRegistry: string } | null } | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', user?: { __typename?: 'UserQuery', current?: { __typename?: 'User', id: string } | null } | null };


export const AllAppDefinitionCategoriesDocument = gql`
    query allAppDefinitionCategories {
  appDefinition {
    allCategories {
      name
    }
  }
}
    `;

/**
 * __useAllAppDefinitionCategoriesQuery__
 *
 * To run a query within a React component, call `useAllAppDefinitionCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAppDefinitionCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAppDefinitionCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAppDefinitionCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<AllAppDefinitionCategoriesQuery, AllAppDefinitionCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAppDefinitionCategoriesQuery, AllAppDefinitionCategoriesQueryVariables>(AllAppDefinitionCategoriesDocument, options);
      }
export function useAllAppDefinitionCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAppDefinitionCategoriesQuery, AllAppDefinitionCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAppDefinitionCategoriesQuery, AllAppDefinitionCategoriesQueryVariables>(AllAppDefinitionCategoriesDocument, options);
        }
export type AllAppDefinitionCategoriesQueryHookResult = ReturnType<typeof useAllAppDefinitionCategoriesQuery>;
export type AllAppDefinitionCategoriesLazyQueryHookResult = ReturnType<typeof useAllAppDefinitionCategoriesLazyQuery>;
export type AllAppDefinitionCategoriesQueryResult = Apollo.QueryResult<AllAppDefinitionCategoriesQuery, AllAppDefinitionCategoriesQueryVariables>;
export const AllAppDefinitionsDocument = gql`
    query allAppDefinitions {
  appDefinition {
    all {
      id
      imageName
      containerName
    }
  }
}
    `;

/**
 * __useAllAppDefinitionsQuery__
 *
 * To run a query within a React component, call `useAllAppDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAppDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAppDefinitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAppDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<AllAppDefinitionsQuery, AllAppDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAppDefinitionsQuery, AllAppDefinitionsQueryVariables>(AllAppDefinitionsDocument, options);
      }
export function useAllAppDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAppDefinitionsQuery, AllAppDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAppDefinitionsQuery, AllAppDefinitionsQueryVariables>(AllAppDefinitionsDocument, options);
        }
export type AllAppDefinitionsQueryHookResult = ReturnType<typeof useAllAppDefinitionsQuery>;
export type AllAppDefinitionsLazyQueryHookResult = ReturnType<typeof useAllAppDefinitionsLazyQuery>;
export type AllAppDefinitionsQueryResult = Apollo.QueryResult<AllAppDefinitionsQuery, AllAppDefinitionsQueryVariables>;
export const AllEnvironmentVariableGroupsDocument = gql`
    query allEnvironmentVariableGroups {
  appDefinition {
    allEnvironmentVariableGroups {
      containerName
      groupName
      id
    }
  }
}
    `;

/**
 * __useAllEnvironmentVariableGroupsQuery__
 *
 * To run a query within a React component, call `useAllEnvironmentVariableGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEnvironmentVariableGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEnvironmentVariableGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllEnvironmentVariableGroupsQuery(baseOptions?: Apollo.QueryHookOptions<AllEnvironmentVariableGroupsQuery, AllEnvironmentVariableGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllEnvironmentVariableGroupsQuery, AllEnvironmentVariableGroupsQueryVariables>(AllEnvironmentVariableGroupsDocument, options);
      }
export function useAllEnvironmentVariableGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllEnvironmentVariableGroupsQuery, AllEnvironmentVariableGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllEnvironmentVariableGroupsQuery, AllEnvironmentVariableGroupsQueryVariables>(AllEnvironmentVariableGroupsDocument, options);
        }
export type AllEnvironmentVariableGroupsQueryHookResult = ReturnType<typeof useAllEnvironmentVariableGroupsQuery>;
export type AllEnvironmentVariableGroupsLazyQueryHookResult = ReturnType<typeof useAllEnvironmentVariableGroupsLazyQuery>;
export type AllEnvironmentVariableGroupsQueryResult = Apollo.QueryResult<AllEnvironmentVariableGroupsQuery, AllEnvironmentVariableGroupsQueryVariables>;
export const AppDefinitionsByIdsDocument = gql`
    query appDefinitionsByIds($id: [ID!]!) {
  appDefinition {
    byIds(id: $id) {
      imageName
      containerName
      id
    }
  }
}
    `;

/**
 * __useAppDefinitionsByIdsQuery__
 *
 * To run a query within a React component, call `useAppDefinitionsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppDefinitionsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppDefinitionsByIdsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppDefinitionsByIdsQuery(baseOptions: Apollo.QueryHookOptions<AppDefinitionsByIdsQuery, AppDefinitionsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppDefinitionsByIdsQuery, AppDefinitionsByIdsQueryVariables>(AppDefinitionsByIdsDocument, options);
      }
export function useAppDefinitionsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppDefinitionsByIdsQuery, AppDefinitionsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppDefinitionsByIdsQuery, AppDefinitionsByIdsQueryVariables>(AppDefinitionsByIdsDocument, options);
        }
export type AppDefinitionsByIdsQueryHookResult = ReturnType<typeof useAppDefinitionsByIdsQuery>;
export type AppDefinitionsByIdsLazyQueryHookResult = ReturnType<typeof useAppDefinitionsByIdsLazyQuery>;
export type AppDefinitionsByIdsQueryResult = Apollo.QueryResult<AppDefinitionsByIdsQuery, AppDefinitionsByIdsQueryVariables>;
export const EnvironmentVariableGroupByIdDocument = gql`
    query environmentVariableGroupById($id: ID!) {
  appDefinition {
    environmentVariableGroupById(id: $id) {
      containerName
      groupName
      variables {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useEnvironmentVariableGroupByIdQuery__
 *
 * To run a query within a React component, call `useEnvironmentVariableGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentVariableGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentVariableGroupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnvironmentVariableGroupByIdQuery(baseOptions: Apollo.QueryHookOptions<EnvironmentVariableGroupByIdQuery, EnvironmentVariableGroupByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnvironmentVariableGroupByIdQuery, EnvironmentVariableGroupByIdQueryVariables>(EnvironmentVariableGroupByIdDocument, options);
      }
export function useEnvironmentVariableGroupByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentVariableGroupByIdQuery, EnvironmentVariableGroupByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnvironmentVariableGroupByIdQuery, EnvironmentVariableGroupByIdQueryVariables>(EnvironmentVariableGroupByIdDocument, options);
        }
export type EnvironmentVariableGroupByIdQueryHookResult = ReturnType<typeof useEnvironmentVariableGroupByIdQuery>;
export type EnvironmentVariableGroupByIdLazyQueryHookResult = ReturnType<typeof useEnvironmentVariableGroupByIdLazyQuery>;
export type EnvironmentVariableGroupByIdQueryResult = Apollo.QueryResult<EnvironmentVariableGroupByIdQuery, EnvironmentVariableGroupByIdQueryVariables>;
export const GetContainerAppDefinitionDocument = gql`
    query getContainerAppDefinition($id: ID!) {
  appDefinition {
    byId(id: $id) {
      id
      imageName
      containerName
      appCategory
      repositoryName
      customTag
      engineType
      restartAlways
      graphqlRequest
      httpTriggerAfterUpgrade
      mounts {
        hostPath
        containerPath
      }
      envs {
        key
        value
      }
      ports {
        containerPort
        hostPort
      }
      dependsOn
    }
  }
}
    `;

/**
 * __useGetContainerAppDefinitionQuery__
 *
 * To run a query within a React component, call `useGetContainerAppDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContainerAppDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContainerAppDefinitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContainerAppDefinitionQuery(baseOptions: Apollo.QueryHookOptions<GetContainerAppDefinitionQuery, GetContainerAppDefinitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContainerAppDefinitionQuery, GetContainerAppDefinitionQueryVariables>(GetContainerAppDefinitionDocument, options);
      }
export function useGetContainerAppDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContainerAppDefinitionQuery, GetContainerAppDefinitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContainerAppDefinitionQuery, GetContainerAppDefinitionQueryVariables>(GetContainerAppDefinitionDocument, options);
        }
export type GetContainerAppDefinitionQueryHookResult = ReturnType<typeof useGetContainerAppDefinitionQuery>;
export type GetContainerAppDefinitionLazyQueryHookResult = ReturnType<typeof useGetContainerAppDefinitionLazyQuery>;
export type GetContainerAppDefinitionQueryResult = Apollo.QueryResult<GetContainerAppDefinitionQuery, GetContainerAppDefinitionQueryVariables>;
export const RegisterContainerAppDefinitionDocument = gql`
    mutation registerContainerAppDefinition($containerAppDefinition: RegisterContainerAppDefinitionInput!) {
  commands {
    appDefinition {
      registerContainerAppDefinition(appDefinition: $containerAppDefinition)
    }
  }
}
    `;
export type RegisterContainerAppDefinitionMutationFn = Apollo.MutationFunction<RegisterContainerAppDefinitionMutation, RegisterContainerAppDefinitionMutationVariables>;

/**
 * __useRegisterContainerAppDefinitionMutation__
 *
 * To run a mutation, you first call `useRegisterContainerAppDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterContainerAppDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerContainerAppDefinitionMutation, { data, loading, error }] = useRegisterContainerAppDefinitionMutation({
 *   variables: {
 *      containerAppDefinition: // value for 'containerAppDefinition'
 *   },
 * });
 */
export function useRegisterContainerAppDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<RegisterContainerAppDefinitionMutation, RegisterContainerAppDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterContainerAppDefinitionMutation, RegisterContainerAppDefinitionMutationVariables>(RegisterContainerAppDefinitionDocument, options);
      }
export type RegisterContainerAppDefinitionMutationHookResult = ReturnType<typeof useRegisterContainerAppDefinitionMutation>;
export type RegisterContainerAppDefinitionMutationResult = Apollo.MutationResult<RegisterContainerAppDefinitionMutation>;
export type RegisterContainerAppDefinitionMutationOptions = Apollo.BaseMutationOptions<RegisterContainerAppDefinitionMutation, RegisterContainerAppDefinitionMutationVariables>;
export const RegisterEnvironmentVariableGroupDocument = gql`
    mutation registerEnvironmentVariableGroup($environmentVariableGroup: EnvironmentVariableGroupInput!) {
  commands {
    appDefinition {
      registerEnvironmentVariableGroup(
        environmentVariableGroup: $environmentVariableGroup
      )
    }
  }
}
    `;
export type RegisterEnvironmentVariableGroupMutationFn = Apollo.MutationFunction<RegisterEnvironmentVariableGroupMutation, RegisterEnvironmentVariableGroupMutationVariables>;

/**
 * __useRegisterEnvironmentVariableGroupMutation__
 *
 * To run a mutation, you first call `useRegisterEnvironmentVariableGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterEnvironmentVariableGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerEnvironmentVariableGroupMutation, { data, loading, error }] = useRegisterEnvironmentVariableGroupMutation({
 *   variables: {
 *      environmentVariableGroup: // value for 'environmentVariableGroup'
 *   },
 * });
 */
export function useRegisterEnvironmentVariableGroupMutation(baseOptions?: Apollo.MutationHookOptions<RegisterEnvironmentVariableGroupMutation, RegisterEnvironmentVariableGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterEnvironmentVariableGroupMutation, RegisterEnvironmentVariableGroupMutationVariables>(RegisterEnvironmentVariableGroupDocument, options);
      }
export type RegisterEnvironmentVariableGroupMutationHookResult = ReturnType<typeof useRegisterEnvironmentVariableGroupMutation>;
export type RegisterEnvironmentVariableGroupMutationResult = Apollo.MutationResult<RegisterEnvironmentVariableGroupMutation>;
export type RegisterEnvironmentVariableGroupMutationOptions = Apollo.BaseMutationOptions<RegisterEnvironmentVariableGroupMutation, RegisterEnvironmentVariableGroupMutationVariables>;
export const RemoveAppDefinitionDocument = gql`
    mutation removeAppDefinition($appDefinitionId: ID!) {
  commands {
    appDefinition {
      removeContainerAppDefinition(appDefinitionId: $appDefinitionId)
    }
  }
}
    `;
export type RemoveAppDefinitionMutationFn = Apollo.MutationFunction<RemoveAppDefinitionMutation, RemoveAppDefinitionMutationVariables>;

/**
 * __useRemoveAppDefinitionMutation__
 *
 * To run a mutation, you first call `useRemoveAppDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAppDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAppDefinitionMutation, { data, loading, error }] = useRemoveAppDefinitionMutation({
 *   variables: {
 *      appDefinitionId: // value for 'appDefinitionId'
 *   },
 * });
 */
export function useRemoveAppDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAppDefinitionMutation, RemoveAppDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAppDefinitionMutation, RemoveAppDefinitionMutationVariables>(RemoveAppDefinitionDocument, options);
      }
export type RemoveAppDefinitionMutationHookResult = ReturnType<typeof useRemoveAppDefinitionMutation>;
export type RemoveAppDefinitionMutationResult = Apollo.MutationResult<RemoveAppDefinitionMutation>;
export type RemoveAppDefinitionMutationOptions = Apollo.BaseMutationOptions<RemoveAppDefinitionMutation, RemoveAppDefinitionMutationVariables>;
export const UpdateContainerAppDefinitionDocument = gql`
    mutation updateContainerAppDefinition($containerAppDefinition: UpdateContainerAppDefinitionInput!) {
  commands {
    appDefinition {
      updateContainerAppDefinition(appDefinition: $containerAppDefinition)
    }
  }
}
    `;
export type UpdateContainerAppDefinitionMutationFn = Apollo.MutationFunction<UpdateContainerAppDefinitionMutation, UpdateContainerAppDefinitionMutationVariables>;

/**
 * __useUpdateContainerAppDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateContainerAppDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContainerAppDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContainerAppDefinitionMutation, { data, loading, error }] = useUpdateContainerAppDefinitionMutation({
 *   variables: {
 *      containerAppDefinition: // value for 'containerAppDefinition'
 *   },
 * });
 */
export function useUpdateContainerAppDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContainerAppDefinitionMutation, UpdateContainerAppDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContainerAppDefinitionMutation, UpdateContainerAppDefinitionMutationVariables>(UpdateContainerAppDefinitionDocument, options);
      }
export type UpdateContainerAppDefinitionMutationHookResult = ReturnType<typeof useUpdateContainerAppDefinitionMutation>;
export type UpdateContainerAppDefinitionMutationResult = Apollo.MutationResult<UpdateContainerAppDefinitionMutation>;
export type UpdateContainerAppDefinitionMutationOptions = Apollo.BaseMutationOptions<UpdateContainerAppDefinitionMutation, UpdateContainerAppDefinitionMutationVariables>;
export const UpdateEnvironmentVariableGroupDocument = gql`
    mutation updateEnvironmentVariableGroup($id: ID!, $environmentVariableGroup: EnvironmentVariableGroupInput!) {
  commands {
    appDefinition {
      updateEnvironmentVariableGroup(
        environmentVariableGroupId: $id
        environmentVariableGroup: $environmentVariableGroup
      )
    }
  }
}
    `;
export type UpdateEnvironmentVariableGroupMutationFn = Apollo.MutationFunction<UpdateEnvironmentVariableGroupMutation, UpdateEnvironmentVariableGroupMutationVariables>;

/**
 * __useUpdateEnvironmentVariableGroupMutation__
 *
 * To run a mutation, you first call `useUpdateEnvironmentVariableGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnvironmentVariableGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnvironmentVariableGroupMutation, { data, loading, error }] = useUpdateEnvironmentVariableGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      environmentVariableGroup: // value for 'environmentVariableGroup'
 *   },
 * });
 */
export function useUpdateEnvironmentVariableGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnvironmentVariableGroupMutation, UpdateEnvironmentVariableGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnvironmentVariableGroupMutation, UpdateEnvironmentVariableGroupMutationVariables>(UpdateEnvironmentVariableGroupDocument, options);
      }
export type UpdateEnvironmentVariableGroupMutationHookResult = ReturnType<typeof useUpdateEnvironmentVariableGroupMutation>;
export type UpdateEnvironmentVariableGroupMutationResult = Apollo.MutationResult<UpdateEnvironmentVariableGroupMutation>;
export type UpdateEnvironmentVariableGroupMutationOptions = Apollo.BaseMutationOptions<UpdateEnvironmentVariableGroupMutation, UpdateEnvironmentVariableGroupMutationVariables>;
export const AllFeaturesDocument = gql`
    query allFeatures {
  feature {
    all {
      id
      name
      description
    }
  }
}
    `;

/**
 * __useAllFeaturesQuery__
 *
 * To run a query within a React component, call `useAllFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<AllFeaturesQuery, AllFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllFeaturesQuery, AllFeaturesQueryVariables>(AllFeaturesDocument, options);
      }
export function useAllFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllFeaturesQuery, AllFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllFeaturesQuery, AllFeaturesQueryVariables>(AllFeaturesDocument, options);
        }
export type AllFeaturesQueryHookResult = ReturnType<typeof useAllFeaturesQuery>;
export type AllFeaturesLazyQueryHookResult = ReturnType<typeof useAllFeaturesLazyQuery>;
export type AllFeaturesQueryResult = Apollo.QueryResult<AllFeaturesQuery, AllFeaturesQueryVariables>;
export const RegisterFeatureDocument = gql`
    mutation registerFeature($feature: RegisterFeatureInput!) {
  commands {
    feature {
      registerFeature(feature: $feature)
    }
  }
}
    `;
export type RegisterFeatureMutationFn = Apollo.MutationFunction<RegisterFeatureMutation, RegisterFeatureMutationVariables>;

/**
 * __useRegisterFeatureMutation__
 *
 * To run a mutation, you first call `useRegisterFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerFeatureMutation, { data, loading, error }] = useRegisterFeatureMutation({
 *   variables: {
 *      feature: // value for 'feature'
 *   },
 * });
 */
export function useRegisterFeatureMutation(baseOptions?: Apollo.MutationHookOptions<RegisterFeatureMutation, RegisterFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterFeatureMutation, RegisterFeatureMutationVariables>(RegisterFeatureDocument, options);
      }
export type RegisterFeatureMutationHookResult = ReturnType<typeof useRegisterFeatureMutation>;
export type RegisterFeatureMutationResult = Apollo.MutationResult<RegisterFeatureMutation>;
export type RegisterFeatureMutationOptions = Apollo.BaseMutationOptions<RegisterFeatureMutation, RegisterFeatureMutationVariables>;
export const ContainerLogEntryCountDocument = gql`
    query containerLogEntryCount($nodeId: ID!, $containerName: String!) {
  log {
    containerLogEntryCount(nodeId: $nodeId, containerName: $containerName)
  }
}
    `;

/**
 * __useContainerLogEntryCountQuery__
 *
 * To run a query within a React component, call `useContainerLogEntryCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useContainerLogEntryCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContainerLogEntryCountQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      containerName: // value for 'containerName'
 *   },
 * });
 */
export function useContainerLogEntryCountQuery(baseOptions: Apollo.QueryHookOptions<ContainerLogEntryCountQuery, ContainerLogEntryCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContainerLogEntryCountQuery, ContainerLogEntryCountQueryVariables>(ContainerLogEntryCountDocument, options);
      }
export function useContainerLogEntryCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContainerLogEntryCountQuery, ContainerLogEntryCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContainerLogEntryCountQuery, ContainerLogEntryCountQueryVariables>(ContainerLogEntryCountDocument, options);
        }
export type ContainerLogEntryCountQueryHookResult = ReturnType<typeof useContainerLogEntryCountQuery>;
export type ContainerLogEntryCountLazyQueryHookResult = ReturnType<typeof useContainerLogEntryCountLazyQuery>;
export type ContainerLogEntryCountQueryResult = Apollo.QueryResult<ContainerLogEntryCountQuery, ContainerLogEntryCountQueryVariables>;
export const ContainerLogMessagesDocument = gql`
    query containerLogMessages($nodeId: ID!, $containerName: String!, $firstLogEntryIndex: Int!, $lastLogEntryIndex: Int!, $filter: ContainerLogMessagesFilter!) {
  log {
    containerLogMessages(
      nodeId: $nodeId
      containerName: $containerName
      firstLogEntryIndex: $firstLogEntryIndex
      lastLogEntryIndex: $lastLogEntryIndex
      filter: $filter
    )
  }
}
    `;

/**
 * __useContainerLogMessagesQuery__
 *
 * To run a query within a React component, call `useContainerLogMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContainerLogMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContainerLogMessagesQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      containerName: // value for 'containerName'
 *      firstLogEntryIndex: // value for 'firstLogEntryIndex'
 *      lastLogEntryIndex: // value for 'lastLogEntryIndex'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useContainerLogMessagesQuery(baseOptions: Apollo.QueryHookOptions<ContainerLogMessagesQuery, ContainerLogMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContainerLogMessagesQuery, ContainerLogMessagesQueryVariables>(ContainerLogMessagesDocument, options);
      }
export function useContainerLogMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContainerLogMessagesQuery, ContainerLogMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContainerLogMessagesQuery, ContainerLogMessagesQueryVariables>(ContainerLogMessagesDocument, options);
        }
export type ContainerLogMessagesQueryHookResult = ReturnType<typeof useContainerLogMessagesQuery>;
export type ContainerLogMessagesLazyQueryHookResult = ReturnType<typeof useContainerLogMessagesLazyQuery>;
export type ContainerLogMessagesQueryResult = Apollo.QueryResult<ContainerLogMessagesQuery, ContainerLogMessagesQueryVariables>;
export const AddNodeContainerAppsDocument = gql`
    mutation addNodeContainerApps($nodeId: ID!, $appDefinitionIds: [ID!]!) {
  commands {
    node {
      addNodeContainerApps(nodeId: $nodeId, appDefinitionIds: $appDefinitionIds)
    }
  }
}
    `;
export type AddNodeContainerAppsMutationFn = Apollo.MutationFunction<AddNodeContainerAppsMutation, AddNodeContainerAppsMutationVariables>;

/**
 * __useAddNodeContainerAppsMutation__
 *
 * To run a mutation, you first call `useAddNodeContainerAppsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNodeContainerAppsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNodeContainerAppsMutation, { data, loading, error }] = useAddNodeContainerAppsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      appDefinitionIds: // value for 'appDefinitionIds'
 *   },
 * });
 */
export function useAddNodeContainerAppsMutation(baseOptions?: Apollo.MutationHookOptions<AddNodeContainerAppsMutation, AddNodeContainerAppsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNodeContainerAppsMutation, AddNodeContainerAppsMutationVariables>(AddNodeContainerAppsDocument, options);
      }
export type AddNodeContainerAppsMutationHookResult = ReturnType<typeof useAddNodeContainerAppsMutation>;
export type AddNodeContainerAppsMutationResult = Apollo.MutationResult<AddNodeContainerAppsMutation>;
export type AddNodeContainerAppsMutationOptions = Apollo.BaseMutationOptions<AddNodeContainerAppsMutation, AddNodeContainerAppsMutationVariables>;
export const AddFeatureToNodeDocument = gql`
    mutation addFeatureToNode($nodeId: ID!, $featureId: ID!) {
  commands {
    node {
      registerFeature(nodeId: $nodeId, featureId: $featureId)
    }
  }
}
    `;
export type AddFeatureToNodeMutationFn = Apollo.MutationFunction<AddFeatureToNodeMutation, AddFeatureToNodeMutationVariables>;

/**
 * __useAddFeatureToNodeMutation__
 *
 * To run a mutation, you first call `useAddFeatureToNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFeatureToNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFeatureToNodeMutation, { data, loading, error }] = useAddFeatureToNodeMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      featureId: // value for 'featureId'
 *   },
 * });
 */
export function useAddFeatureToNodeMutation(baseOptions?: Apollo.MutationHookOptions<AddFeatureToNodeMutation, AddFeatureToNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFeatureToNodeMutation, AddFeatureToNodeMutationVariables>(AddFeatureToNodeDocument, options);
      }
export type AddFeatureToNodeMutationHookResult = ReturnType<typeof useAddFeatureToNodeMutation>;
export type AddFeatureToNodeMutationResult = Apollo.MutationResult<AddFeatureToNodeMutation>;
export type AddFeatureToNodeMutationOptions = Apollo.BaseMutationOptions<AddFeatureToNodeMutation, AddFeatureToNodeMutationVariables>;
export const AddNodeContainerAppsByCategoryDocument = gql`
    mutation addNodeContainerAppsByCategory($nodeId: ID!, $category: String!, $version: String!) {
  commands {
    node {
      addNodeContainerAppsByCategory(
        nodeId: $nodeId
        category: $category
        version: $version
      )
    }
  }
}
    `;
export type AddNodeContainerAppsByCategoryMutationFn = Apollo.MutationFunction<AddNodeContainerAppsByCategoryMutation, AddNodeContainerAppsByCategoryMutationVariables>;

/**
 * __useAddNodeContainerAppsByCategoryMutation__
 *
 * To run a mutation, you first call `useAddNodeContainerAppsByCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNodeContainerAppsByCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNodeContainerAppsByCategoryMutation, { data, loading, error }] = useAddNodeContainerAppsByCategoryMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      category: // value for 'category'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useAddNodeContainerAppsByCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddNodeContainerAppsByCategoryMutation, AddNodeContainerAppsByCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNodeContainerAppsByCategoryMutation, AddNodeContainerAppsByCategoryMutationVariables>(AddNodeContainerAppsByCategoryDocument, options);
      }
export type AddNodeContainerAppsByCategoryMutationHookResult = ReturnType<typeof useAddNodeContainerAppsByCategoryMutation>;
export type AddNodeContainerAppsByCategoryMutationResult = Apollo.MutationResult<AddNodeContainerAppsByCategoryMutation>;
export type AddNodeContainerAppsByCategoryMutationOptions = Apollo.BaseMutationOptions<AddNodeContainerAppsByCategoryMutation, AddNodeContainerAppsByCategoryMutationVariables>;
export const AllNodesDocument = gql`
    query allNodes {
  node {
    all {
      id
      name
      lastSeen
    }
  }
}
    `;

/**
 * __useAllNodesQuery__
 *
 * To run a query within a React component, call `useAllNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllNodesQuery(baseOptions?: Apollo.QueryHookOptions<AllNodesQuery, AllNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllNodesQuery, AllNodesQueryVariables>(AllNodesDocument, options);
      }
export function useAllNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllNodesQuery, AllNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllNodesQuery, AllNodesQueryVariables>(AllNodesDocument, options);
        }
export type AllNodesQueryHookResult = ReturnType<typeof useAllNodesQuery>;
export type AllNodesLazyQueryHookResult = ReturnType<typeof useAllNodesLazyQuery>;
export type AllNodesQueryResult = Apollo.QueryResult<AllNodesQuery, AllNodesQueryVariables>;
export const ArrangeRequestRestartAllAppsDocument = gql`
    mutation arrangeRequestRestartAllApps($nodeId: ID!) {
  commands {
    node {
      arrangeRequestRestartAllApps(nodeId: $nodeId)
    }
  }
}
    `;
export type ArrangeRequestRestartAllAppsMutationFn = Apollo.MutationFunction<ArrangeRequestRestartAllAppsMutation, ArrangeRequestRestartAllAppsMutationVariables>;

/**
 * __useArrangeRequestRestartAllAppsMutation__
 *
 * To run a mutation, you first call `useArrangeRequestRestartAllAppsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArrangeRequestRestartAllAppsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [arrangeRequestRestartAllAppsMutation, { data, loading, error }] = useArrangeRequestRestartAllAppsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useArrangeRequestRestartAllAppsMutation(baseOptions?: Apollo.MutationHookOptions<ArrangeRequestRestartAllAppsMutation, ArrangeRequestRestartAllAppsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArrangeRequestRestartAllAppsMutation, ArrangeRequestRestartAllAppsMutationVariables>(ArrangeRequestRestartAllAppsDocument, options);
      }
export type ArrangeRequestRestartAllAppsMutationHookResult = ReturnType<typeof useArrangeRequestRestartAllAppsMutation>;
export type ArrangeRequestRestartAllAppsMutationResult = Apollo.MutationResult<ArrangeRequestRestartAllAppsMutation>;
export type ArrangeRequestRestartAllAppsMutationOptions = Apollo.BaseMutationOptions<ArrangeRequestRestartAllAppsMutation, ArrangeRequestRestartAllAppsMutationVariables>;
export const ArrangeRequestUpgradeAllAppsDocument = gql`
    mutation arrangeRequestUpgradeAllApps($nodeId: ID!) {
  commands {
    node {
      arrangeRequestUpgradeAllApps(nodeId: $nodeId)
    }
  }
}
    `;
export type ArrangeRequestUpgradeAllAppsMutationFn = Apollo.MutationFunction<ArrangeRequestUpgradeAllAppsMutation, ArrangeRequestUpgradeAllAppsMutationVariables>;

/**
 * __useArrangeRequestUpgradeAllAppsMutation__
 *
 * To run a mutation, you first call `useArrangeRequestUpgradeAllAppsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArrangeRequestUpgradeAllAppsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [arrangeRequestUpgradeAllAppsMutation, { data, loading, error }] = useArrangeRequestUpgradeAllAppsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useArrangeRequestUpgradeAllAppsMutation(baseOptions?: Apollo.MutationHookOptions<ArrangeRequestUpgradeAllAppsMutation, ArrangeRequestUpgradeAllAppsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArrangeRequestUpgradeAllAppsMutation, ArrangeRequestUpgradeAllAppsMutationVariables>(ArrangeRequestUpgradeAllAppsDocument, options);
      }
export type ArrangeRequestUpgradeAllAppsMutationHookResult = ReturnType<typeof useArrangeRequestUpgradeAllAppsMutation>;
export type ArrangeRequestUpgradeAllAppsMutationResult = Apollo.MutationResult<ArrangeRequestUpgradeAllAppsMutation>;
export type ArrangeRequestUpgradeAllAppsMutationOptions = Apollo.BaseMutationOptions<ArrangeRequestUpgradeAllAppsMutation, ArrangeRequestUpgradeAllAppsMutationVariables>;
export const ArrangeRestartAllAppsDocument = gql`
    mutation arrangeRestartAllApps($nodeId: ID!) {
  commands {
    node {
      arrangeRestartAllApps(nodeId: $nodeId)
    }
  }
}
    `;
export type ArrangeRestartAllAppsMutationFn = Apollo.MutationFunction<ArrangeRestartAllAppsMutation, ArrangeRestartAllAppsMutationVariables>;

/**
 * __useArrangeRestartAllAppsMutation__
 *
 * To run a mutation, you first call `useArrangeRestartAllAppsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArrangeRestartAllAppsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [arrangeRestartAllAppsMutation, { data, loading, error }] = useArrangeRestartAllAppsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useArrangeRestartAllAppsMutation(baseOptions?: Apollo.MutationHookOptions<ArrangeRestartAllAppsMutation, ArrangeRestartAllAppsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArrangeRestartAllAppsMutation, ArrangeRestartAllAppsMutationVariables>(ArrangeRestartAllAppsDocument, options);
      }
export type ArrangeRestartAllAppsMutationHookResult = ReturnType<typeof useArrangeRestartAllAppsMutation>;
export type ArrangeRestartAllAppsMutationResult = Apollo.MutationResult<ArrangeRestartAllAppsMutation>;
export type ArrangeRestartAllAppsMutationOptions = Apollo.BaseMutationOptions<ArrangeRestartAllAppsMutation, ArrangeRestartAllAppsMutationVariables>;
export const ArrangeUpgradeAllAppsDocument = gql`
    mutation arrangeUpgradeAllApps($nodeId: ID!) {
  commands {
    node {
      arrangeUpgradeAllApps(nodeId: $nodeId)
    }
  }
}
    `;
export type ArrangeUpgradeAllAppsMutationFn = Apollo.MutationFunction<ArrangeUpgradeAllAppsMutation, ArrangeUpgradeAllAppsMutationVariables>;

/**
 * __useArrangeUpgradeAllAppsMutation__
 *
 * To run a mutation, you first call `useArrangeUpgradeAllAppsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArrangeUpgradeAllAppsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [arrangeUpgradeAllAppsMutation, { data, loading, error }] = useArrangeUpgradeAllAppsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useArrangeUpgradeAllAppsMutation(baseOptions?: Apollo.MutationHookOptions<ArrangeUpgradeAllAppsMutation, ArrangeUpgradeAllAppsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArrangeUpgradeAllAppsMutation, ArrangeUpgradeAllAppsMutationVariables>(ArrangeUpgradeAllAppsDocument, options);
      }
export type ArrangeUpgradeAllAppsMutationHookResult = ReturnType<typeof useArrangeUpgradeAllAppsMutation>;
export type ArrangeUpgradeAllAppsMutationResult = Apollo.MutationResult<ArrangeUpgradeAllAppsMutation>;
export type ArrangeUpgradeAllAppsMutationOptions = Apollo.BaseMutationOptions<ArrangeUpgradeAllAppsMutation, ArrangeUpgradeAllAppsMutationVariables>;
export const GetMachineInformationDocument = gql`
    query getMachineInformation($nodeId: ID!) {
  node {
    byId(id: $nodeId) {
      machineDescription
      imageUrl
      manufacturer
      location
      serialNumber
      buildYear
      owner
    }
  }
}
    `;

/**
 * __useGetMachineInformationQuery__
 *
 * To run a query within a React component, call `useGetMachineInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachineInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachineInformationQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetMachineInformationQuery(baseOptions: Apollo.QueryHookOptions<GetMachineInformationQuery, GetMachineInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachineInformationQuery, GetMachineInformationQueryVariables>(GetMachineInformationDocument, options);
      }
export function useGetMachineInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachineInformationQuery, GetMachineInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachineInformationQuery, GetMachineInformationQueryVariables>(GetMachineInformationDocument, options);
        }
export type GetMachineInformationQueryHookResult = ReturnType<typeof useGetMachineInformationQuery>;
export type GetMachineInformationLazyQueryHookResult = ReturnType<typeof useGetMachineInformationLazyQuery>;
export type GetMachineInformationQueryResult = Apollo.QueryResult<GetMachineInformationQuery, GetMachineInformationQueryVariables>;
export const K8sValuesDocument = gql`
    query k8sValues {
  node {
    k8sValues {
      name
      type
      preValue
    }
  }
}
    `;

/**
 * __useK8sValuesQuery__
 *
 * To run a query within a React component, call `useK8sValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useK8sValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useK8sValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useK8sValuesQuery(baseOptions?: Apollo.QueryHookOptions<K8sValuesQuery, K8sValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<K8sValuesQuery, K8sValuesQueryVariables>(K8sValuesDocument, options);
      }
export function useK8sValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<K8sValuesQuery, K8sValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<K8sValuesQuery, K8sValuesQueryVariables>(K8sValuesDocument, options);
        }
export type K8sValuesQueryHookResult = ReturnType<typeof useK8sValuesQuery>;
export type K8sValuesLazyQueryHookResult = ReturnType<typeof useK8sValuesLazyQuery>;
export type K8sValuesQueryResult = Apollo.QueryResult<K8sValuesQuery, K8sValuesQueryVariables>;
export const NodeActionsListDocument = gql`
    query nodeActionsList($id: ID!) {
  node {
    byId(id: $id) {
      allActions {
        action
        arrangedTime
        finishedTime
        startedTime
        arrangedBy {
          firstname
          lastname
        }
      }
    }
  }
}
    `;

/**
 * __useNodeActionsListQuery__
 *
 * To run a query within a React component, call `useNodeActionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeActionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeActionsListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeActionsListQuery(baseOptions: Apollo.QueryHookOptions<NodeActionsListQuery, NodeActionsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeActionsListQuery, NodeActionsListQueryVariables>(NodeActionsListDocument, options);
      }
export function useNodeActionsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeActionsListQuery, NodeActionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeActionsListQuery, NodeActionsListQueryVariables>(NodeActionsListDocument, options);
        }
export type NodeActionsListQueryHookResult = ReturnType<typeof useNodeActionsListQuery>;
export type NodeActionsListLazyQueryHookResult = ReturnType<typeof useNodeActionsListLazyQuery>;
export type NodeActionsListQueryResult = Apollo.QueryResult<NodeActionsListQuery, NodeActionsListQueryVariables>;
export const NodeContainerEnvironmentVariablesDocument = gql`
    query nodeContainerEnvironmentVariables($id: ID!) {
  node {
    byId(id: $id) {
      nodeContainerOverrides {
        containerAppId
        variables {
          key
          value
        }
      }
    }
  }
}
    `;

/**
 * __useNodeContainerEnvironmentVariablesQuery__
 *
 * To run a query within a React component, call `useNodeContainerEnvironmentVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeContainerEnvironmentVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeContainerEnvironmentVariablesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeContainerEnvironmentVariablesQuery(baseOptions: Apollo.QueryHookOptions<NodeContainerEnvironmentVariablesQuery, NodeContainerEnvironmentVariablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeContainerEnvironmentVariablesQuery, NodeContainerEnvironmentVariablesQueryVariables>(NodeContainerEnvironmentVariablesDocument, options);
      }
export function useNodeContainerEnvironmentVariablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeContainerEnvironmentVariablesQuery, NodeContainerEnvironmentVariablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeContainerEnvironmentVariablesQuery, NodeContainerEnvironmentVariablesQueryVariables>(NodeContainerEnvironmentVariablesDocument, options);
        }
export type NodeContainerEnvironmentVariablesQueryHookResult = ReturnType<typeof useNodeContainerEnvironmentVariablesQuery>;
export type NodeContainerEnvironmentVariablesLazyQueryHookResult = ReturnType<typeof useNodeContainerEnvironmentVariablesLazyQuery>;
export type NodeContainerEnvironmentVariablesQueryResult = Apollo.QueryResult<NodeContainerEnvironmentVariablesQuery, NodeContainerEnvironmentVariablesQueryVariables>;
export const AvailableStagesDocument = gql`
    query availableStages {
  node {
    availableStages {
      displayName
      stageName
    }
  }
}
    `;

/**
 * __useAvailableStagesQuery__
 *
 * To run a query within a React component, call `useAvailableStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableStagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableStagesQuery(baseOptions?: Apollo.QueryHookOptions<AvailableStagesQuery, AvailableStagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableStagesQuery, AvailableStagesQueryVariables>(AvailableStagesDocument, options);
      }
export function useAvailableStagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableStagesQuery, AvailableStagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableStagesQuery, AvailableStagesQueryVariables>(AvailableStagesDocument, options);
        }
export type AvailableStagesQueryHookResult = ReturnType<typeof useAvailableStagesQuery>;
export type AvailableStagesLazyQueryHookResult = ReturnType<typeof useAvailableStagesLazyQuery>;
export type AvailableStagesQueryResult = Apollo.QueryResult<AvailableStagesQuery, AvailableStagesQueryVariables>;
export const NodeContainerAppDefinitionsDocument = gql`
    query nodeContainerAppDefinitions($id: ID!) {
  node {
    byId(id: $id) {
      appDefinitionIds
      nodeContainerOverrides {
        containerAppId
        customTag
      }
    }
  }
}
    `;

/**
 * __useNodeContainerAppDefinitionsQuery__
 *
 * To run a query within a React component, call `useNodeContainerAppDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeContainerAppDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeContainerAppDefinitionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeContainerAppDefinitionsQuery(baseOptions: Apollo.QueryHookOptions<NodeContainerAppDefinitionsQuery, NodeContainerAppDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeContainerAppDefinitionsQuery, NodeContainerAppDefinitionsQueryVariables>(NodeContainerAppDefinitionsDocument, options);
      }
export function useNodeContainerAppDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeContainerAppDefinitionsQuery, NodeContainerAppDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeContainerAppDefinitionsQuery, NodeContainerAppDefinitionsQueryVariables>(NodeContainerAppDefinitionsDocument, options);
        }
export type NodeContainerAppDefinitionsQueryHookResult = ReturnType<typeof useNodeContainerAppDefinitionsQuery>;
export type NodeContainerAppDefinitionsLazyQueryHookResult = ReturnType<typeof useNodeContainerAppDefinitionsLazyQuery>;
export type NodeContainerAppDefinitionsQueryResult = Apollo.QueryResult<NodeContainerAppDefinitionsQuery, NodeContainerAppDefinitionsQueryVariables>;
export const NodeContainerDDocument = gql`
    query nodeContainerD($id: ID!) {
  node {
    byId(id: $id) {
      containerD {
        version
        runtimeName
        runtimeVersion
        runtimeApiVersion
      }
    }
  }
}
    `;

/**
 * __useNodeContainerDQuery__
 *
 * To run a query within a React component, call `useNodeContainerDQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeContainerDQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeContainerDQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeContainerDQuery(baseOptions: Apollo.QueryHookOptions<NodeContainerDQuery, NodeContainerDQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeContainerDQuery, NodeContainerDQueryVariables>(NodeContainerDDocument, options);
      }
export function useNodeContainerDLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeContainerDQuery, NodeContainerDQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeContainerDQuery, NodeContainerDQueryVariables>(NodeContainerDDocument, options);
        }
export type NodeContainerDQueryHookResult = ReturnType<typeof useNodeContainerDQuery>;
export type NodeContainerDLazyQueryHookResult = ReturnType<typeof useNodeContainerDLazyQuery>;
export type NodeContainerDQueryResult = Apollo.QueryResult<NodeContainerDQuery, NodeContainerDQueryVariables>;
export const NodeContainerDStatsDocument = gql`
    query nodeContainerDStats($id: ID!) {
  node {
    byId(id: $id) {
      containerD {
        containers {
          containerName
          containerState
          createdAt
          cpuPerc
          mem
        }
      }
    }
  }
}
    `;

/**
 * __useNodeContainerDStatsQuery__
 *
 * To run a query within a React component, call `useNodeContainerDStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeContainerDStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeContainerDStatsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeContainerDStatsQuery(baseOptions: Apollo.QueryHookOptions<NodeContainerDStatsQuery, NodeContainerDStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeContainerDStatsQuery, NodeContainerDStatsQueryVariables>(NodeContainerDStatsDocument, options);
      }
export function useNodeContainerDStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeContainerDStatsQuery, NodeContainerDStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeContainerDStatsQuery, NodeContainerDStatsQueryVariables>(NodeContainerDStatsDocument, options);
        }
export type NodeContainerDStatsQueryHookResult = ReturnType<typeof useNodeContainerDStatsQuery>;
export type NodeContainerDStatsLazyQueryHookResult = ReturnType<typeof useNodeContainerDStatsLazyQuery>;
export type NodeContainerDStatsQueryResult = Apollo.QueryResult<NodeContainerDStatsQuery, NodeContainerDStatsQueryVariables>;
export const NodeDefaultCustomTagDocument = gql`
    query nodeDefaultCustomTag($id: ID!) {
  node {
    byId(id: $id) {
      defaultCustomTag
    }
  }
}
    `;

/**
 * __useNodeDefaultCustomTagQuery__
 *
 * To run a query within a React component, call `useNodeDefaultCustomTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeDefaultCustomTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeDefaultCustomTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeDefaultCustomTagQuery(baseOptions: Apollo.QueryHookOptions<NodeDefaultCustomTagQuery, NodeDefaultCustomTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeDefaultCustomTagQuery, NodeDefaultCustomTagQueryVariables>(NodeDefaultCustomTagDocument, options);
      }
export function useNodeDefaultCustomTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeDefaultCustomTagQuery, NodeDefaultCustomTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeDefaultCustomTagQuery, NodeDefaultCustomTagQueryVariables>(NodeDefaultCustomTagDocument, options);
        }
export type NodeDefaultCustomTagQueryHookResult = ReturnType<typeof useNodeDefaultCustomTagQuery>;
export type NodeDefaultCustomTagLazyQueryHookResult = ReturnType<typeof useNodeDefaultCustomTagLazyQuery>;
export type NodeDefaultCustomTagQueryResult = Apollo.QueryResult<NodeDefaultCustomTagQuery, NodeDefaultCustomTagQueryVariables>;
export const NodeFeatureIdsDocument = gql`
    query nodeFeatureIds($id: ID!) {
  node {
    byId(id: $id) {
      featureIds
    }
  }
}
    `;

/**
 * __useNodeFeatureIdsQuery__
 *
 * To run a query within a React component, call `useNodeFeatureIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeFeatureIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeFeatureIdsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeFeatureIdsQuery(baseOptions: Apollo.QueryHookOptions<NodeFeatureIdsQuery, NodeFeatureIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeFeatureIdsQuery, NodeFeatureIdsQueryVariables>(NodeFeatureIdsDocument, options);
      }
export function useNodeFeatureIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeFeatureIdsQuery, NodeFeatureIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeFeatureIdsQuery, NodeFeatureIdsQueryVariables>(NodeFeatureIdsDocument, options);
        }
export type NodeFeatureIdsQueryHookResult = ReturnType<typeof useNodeFeatureIdsQuery>;
export type NodeFeatureIdsLazyQueryHookResult = ReturnType<typeof useNodeFeatureIdsLazyQuery>;
export type NodeFeatureIdsQueryResult = Apollo.QueryResult<NodeFeatureIdsQuery, NodeFeatureIdsQueryVariables>;
export const GetNodeStageDocument = gql`
    query getNodeStage($nodeId: ID!) {
  node {
    byId(id: $nodeId) {
      stage
    }
  }
}
    `;

/**
 * __useGetNodeStageQuery__
 *
 * To run a query within a React component, call `useGetNodeStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodeStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodeStageQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetNodeStageQuery(baseOptions: Apollo.QueryHookOptions<GetNodeStageQuery, GetNodeStageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNodeStageQuery, GetNodeStageQueryVariables>(GetNodeStageDocument, options);
      }
export function useGetNodeStageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNodeStageQuery, GetNodeStageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNodeStageQuery, GetNodeStageQueryVariables>(GetNodeStageDocument, options);
        }
export type GetNodeStageQueryHookResult = ReturnType<typeof useGetNodeStageQuery>;
export type GetNodeStageLazyQueryHookResult = ReturnType<typeof useGetNodeStageLazyQuery>;
export type GetNodeStageQueryResult = Apollo.QueryResult<GetNodeStageQuery, GetNodeStageQueryVariables>;
export const NodeInformationDocument = gql`
    query nodeInformation($id: ID!) {
  node {
    byId(id: $id) {
      nodeState
      upgradeProgress {
        progress
        message
      }
      host {
        architecture
        bootTime
        hostName
        ips
        kernelVersion
        os {
          type
          family
          platform
          name
          version
          major
          minor
          patch
          build
          codename
        }
        timezone
        timezoneOffsetSec
        uniqueId
      }
      lastSeen
      vmIpAddress
    }
  }
}
    `;

/**
 * __useNodeInformationQuery__
 *
 * To run a query within a React component, call `useNodeInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeInformationQuery(baseOptions: Apollo.QueryHookOptions<NodeInformationQuery, NodeInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeInformationQuery, NodeInformationQueryVariables>(NodeInformationDocument, options);
      }
export function useNodeInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeInformationQuery, NodeInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeInformationQuery, NodeInformationQueryVariables>(NodeInformationDocument, options);
        }
export type NodeInformationQueryHookResult = ReturnType<typeof useNodeInformationQuery>;
export type NodeInformationLazyQueryHookResult = ReturnType<typeof useNodeInformationLazyQuery>;
export type NodeInformationQueryResult = Apollo.QueryResult<NodeInformationQuery, NodeInformationQueryVariables>;
export const NodeInitializedDocument = gql`
    query nodeInitialized($id: ID!) {
  node {
    byId(id: $id) {
      initialized
    }
  }
}
    `;

/**
 * __useNodeInitializedQuery__
 *
 * To run a query within a React component, call `useNodeInitializedQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeInitializedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeInitializedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeInitializedQuery(baseOptions: Apollo.QueryHookOptions<NodeInitializedQuery, NodeInitializedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeInitializedQuery, NodeInitializedQueryVariables>(NodeInitializedDocument, options);
      }
export function useNodeInitializedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeInitializedQuery, NodeInitializedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeInitializedQuery, NodeInitializedQueryVariables>(NodeInitializedDocument, options);
        }
export type NodeInitializedQueryHookResult = ReturnType<typeof useNodeInitializedQuery>;
export type NodeInitializedLazyQueryHookResult = ReturnType<typeof useNodeInitializedLazyQuery>;
export type NodeInitializedQueryResult = Apollo.QueryResult<NodeInitializedQuery, NodeInitializedQueryVariables>;
export const NodeK8sValuesDocument = gql`
    query nodeK8sValues($id: ID!) {
  node {
    byId(id: $id) {
      k8sValues {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useNodeK8sValuesQuery__
 *
 * To run a query within a React component, call `useNodeK8sValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeK8sValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeK8sValuesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeK8sValuesQuery(baseOptions: Apollo.QueryHookOptions<NodeK8sValuesQuery, NodeK8sValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeK8sValuesQuery, NodeK8sValuesQueryVariables>(NodeK8sValuesDocument, options);
      }
export function useNodeK8sValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeK8sValuesQuery, NodeK8sValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeK8sValuesQuery, NodeK8sValuesQueryVariables>(NodeK8sValuesDocument, options);
        }
export type NodeK8sValuesQueryHookResult = ReturnType<typeof useNodeK8sValuesQuery>;
export type NodeK8sValuesLazyQueryHookResult = ReturnType<typeof useNodeK8sValuesLazyQuery>;
export type NodeK8sValuesQueryResult = Apollo.QueryResult<NodeK8sValuesQuery, NodeK8sValuesQueryVariables>;
export const NodeLastSeenUpdatedDocument = gql`
    subscription nodeLastSeenUpdated($nodeId: ID!) {
  nodeLastSeenUpdated(nodeId: $nodeId)
}
    `;

/**
 * __useNodeLastSeenUpdatedSubscription__
 *
 * To run a query within a React component, call `useNodeLastSeenUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNodeLastSeenUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeLastSeenUpdatedSubscription({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useNodeLastSeenUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<NodeLastSeenUpdatedSubscription, NodeLastSeenUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NodeLastSeenUpdatedSubscription, NodeLastSeenUpdatedSubscriptionVariables>(NodeLastSeenUpdatedDocument, options);
      }
export type NodeLastSeenUpdatedSubscriptionHookResult = ReturnType<typeof useNodeLastSeenUpdatedSubscription>;
export type NodeLastSeenUpdatedSubscriptionResult = Apollo.SubscriptionResult<NodeLastSeenUpdatedSubscription>;
export const NodeNameDocument = gql`
    query nodeName($id: ID!) {
  node {
    byId(id: $id) {
      name
    }
  }
}
    `;

/**
 * __useNodeNameQuery__
 *
 * To run a query within a React component, call `useNodeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeNameQuery(baseOptions: Apollo.QueryHookOptions<NodeNameQuery, NodeNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeNameQuery, NodeNameQueryVariables>(NodeNameDocument, options);
      }
export function useNodeNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeNameQuery, NodeNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeNameQuery, NodeNameQueryVariables>(NodeNameDocument, options);
        }
export type NodeNameQueryHookResult = ReturnType<typeof useNodeNameQuery>;
export type NodeNameLazyQueryHookResult = ReturnType<typeof useNodeNameLazyQuery>;
export type NodeNameQueryResult = Apollo.QueryResult<NodeNameQuery, NodeNameQueryVariables>;
export const NodeReportedErrorsDocument = gql`
    query nodeReportedErrors($id: ID!) {
  node {
    byId(id: $id) {
      id
      reportedErrors {
        time
        error
      }
    }
  }
}
    `;

/**
 * __useNodeReportedErrorsQuery__
 *
 * To run a query within a React component, call `useNodeReportedErrorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeReportedErrorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeReportedErrorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeReportedErrorsQuery(baseOptions: Apollo.QueryHookOptions<NodeReportedErrorsQuery, NodeReportedErrorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeReportedErrorsQuery, NodeReportedErrorsQueryVariables>(NodeReportedErrorsDocument, options);
      }
export function useNodeReportedErrorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeReportedErrorsQuery, NodeReportedErrorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeReportedErrorsQuery, NodeReportedErrorsQueryVariables>(NodeReportedErrorsDocument, options);
        }
export type NodeReportedErrorsQueryHookResult = ReturnType<typeof useNodeReportedErrorsQuery>;
export type NodeReportedErrorsLazyQueryHookResult = ReturnType<typeof useNodeReportedErrorsLazyQuery>;
export type NodeReportedErrorsQueryResult = Apollo.QueryResult<NodeReportedErrorsQuery, NodeReportedErrorsQueryVariables>;
export const NodeStateUpdatedDocument = gql`
    subscription nodeStateUpdated($nodeId: ID!) {
  nodeStateUpdated(nodeId: $nodeId)
}
    `;

/**
 * __useNodeStateUpdatedSubscription__
 *
 * To run a query within a React component, call `useNodeStateUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNodeStateUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeStateUpdatedSubscription({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useNodeStateUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<NodeStateUpdatedSubscription, NodeStateUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NodeStateUpdatedSubscription, NodeStateUpdatedSubscriptionVariables>(NodeStateUpdatedDocument, options);
      }
export type NodeStateUpdatedSubscriptionHookResult = ReturnType<typeof useNodeStateUpdatedSubscription>;
export type NodeStateUpdatedSubscriptionResult = Apollo.SubscriptionResult<NodeStateUpdatedSubscription>;
export const NodeStatsDocument = gql`
    query nodeStats($id: ID!) {
  node {
    byId(id: $id) {
      nodeStats
    }
  }
}
    `;

/**
 * __useNodeStatsQuery__
 *
 * To run a query within a React component, call `useNodeStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeStatsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeStatsQuery(baseOptions: Apollo.QueryHookOptions<NodeStatsQuery, NodeStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeStatsQuery, NodeStatsQueryVariables>(NodeStatsDocument, options);
      }
export function useNodeStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeStatsQuery, NodeStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeStatsQuery, NodeStatsQueryVariables>(NodeStatsDocument, options);
        }
export type NodeStatsQueryHookResult = ReturnType<typeof useNodeStatsQuery>;
export type NodeStatsLazyQueryHookResult = ReturnType<typeof useNodeStatsLazyQuery>;
export type NodeStatsQueryResult = Apollo.QueryResult<NodeStatsQuery, NodeStatsQueryVariables>;
export const NodeTokenDocument = gql`
    query nodeToken($id: ID!) {
  node {
    byId(id: $id) {
      token
      natsCred
    }
  }
}
    `;

/**
 * __useNodeTokenQuery__
 *
 * To run a query within a React component, call `useNodeTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeTokenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNodeTokenQuery(baseOptions: Apollo.QueryHookOptions<NodeTokenQuery, NodeTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeTokenQuery, NodeTokenQueryVariables>(NodeTokenDocument, options);
      }
export function useNodeTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeTokenQuery, NodeTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeTokenQuery, NodeTokenQueryVariables>(NodeTokenDocument, options);
        }
export type NodeTokenQueryHookResult = ReturnType<typeof useNodeTokenQuery>;
export type NodeTokenLazyQueryHookResult = ReturnType<typeof useNodeTokenLazyQuery>;
export type NodeTokenQueryResult = Apollo.QueryResult<NodeTokenQuery, NodeTokenQueryVariables>;
export const VmLogQueryDocument = gql`
    query vmLogQuery($nodeId: ID!) {
  node {
    byId(id: $nodeId) {
      vmLog
    }
  }
}
    `;

/**
 * __useVmLogQueryQuery__
 *
 * To run a query within a React component, call `useVmLogQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useVmLogQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVmLogQueryQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useVmLogQueryQuery(baseOptions: Apollo.QueryHookOptions<VmLogQueryQuery, VmLogQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VmLogQueryQuery, VmLogQueryQueryVariables>(VmLogQueryDocument, options);
      }
export function useVmLogQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VmLogQueryQuery, VmLogQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VmLogQueryQuery, VmLogQueryQueryVariables>(VmLogQueryDocument, options);
        }
export type VmLogQueryQueryHookResult = ReturnType<typeof useVmLogQueryQuery>;
export type VmLogQueryLazyQueryHookResult = ReturnType<typeof useVmLogQueryLazyQuery>;
export type VmLogQueryQueryResult = Apollo.QueryResult<VmLogQueryQuery, VmLogQueryQueryVariables>;
export const VmLogSubscriptionDocument = gql`
    subscription vmLogSubscription($nodeId: ID!) {
  vmLogUpdated(nodeId: $nodeId)
}
    `;

/**
 * __useVmLogSubscriptionSubscription__
 *
 * To run a query within a React component, call `useVmLogSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVmLogSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVmLogSubscriptionSubscription({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useVmLogSubscriptionSubscription(baseOptions: Apollo.SubscriptionHookOptions<VmLogSubscriptionSubscription, VmLogSubscriptionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<VmLogSubscriptionSubscription, VmLogSubscriptionSubscriptionVariables>(VmLogSubscriptionDocument, options);
      }
export type VmLogSubscriptionSubscriptionHookResult = ReturnType<typeof useVmLogSubscriptionSubscription>;
export type VmLogSubscriptionSubscriptionResult = Apollo.SubscriptionResult<VmLogSubscriptionSubscription>;
export const RegisterK8sNodeDocument = gql`
    mutation registerK8sNode($input: [RegisterNodeWithK8s!]!) {
  commands {
    node {
      registerNodeWithK8s(node: $input)
    }
  }
}
    `;
export type RegisterK8sNodeMutationFn = Apollo.MutationFunction<RegisterK8sNodeMutation, RegisterK8sNodeMutationVariables>;

/**
 * __useRegisterK8sNodeMutation__
 *
 * To run a mutation, you first call `useRegisterK8sNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterK8sNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerK8sNodeMutation, { data, loading, error }] = useRegisterK8sNodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterK8sNodeMutation(baseOptions?: Apollo.MutationHookOptions<RegisterK8sNodeMutation, RegisterK8sNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterK8sNodeMutation, RegisterK8sNodeMutationVariables>(RegisterK8sNodeDocument, options);
      }
export type RegisterK8sNodeMutationHookResult = ReturnType<typeof useRegisterK8sNodeMutation>;
export type RegisterK8sNodeMutationResult = Apollo.MutationResult<RegisterK8sNodeMutation>;
export type RegisterK8sNodeMutationOptions = Apollo.BaseMutationOptions<RegisterK8sNodeMutation, RegisterK8sNodeMutationVariables>;
export const RegisterNodeWithVmDocument = gql`
    mutation registerNodeWithVm($nodeWithVm: RegisterNodeWithVm!) {
  commands {
    node {
      registerNodeWithVm(node: $nodeWithVm)
    }
  }
}
    `;
export type RegisterNodeWithVmMutationFn = Apollo.MutationFunction<RegisterNodeWithVmMutation, RegisterNodeWithVmMutationVariables>;

/**
 * __useRegisterNodeWithVmMutation__
 *
 * To run a mutation, you first call `useRegisterNodeWithVmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterNodeWithVmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerNodeWithVmMutation, { data, loading, error }] = useRegisterNodeWithVmMutation({
 *   variables: {
 *      nodeWithVm: // value for 'nodeWithVm'
 *   },
 * });
 */
export function useRegisterNodeWithVmMutation(baseOptions?: Apollo.MutationHookOptions<RegisterNodeWithVmMutation, RegisterNodeWithVmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterNodeWithVmMutation, RegisterNodeWithVmMutationVariables>(RegisterNodeWithVmDocument, options);
      }
export type RegisterNodeWithVmMutationHookResult = ReturnType<typeof useRegisterNodeWithVmMutation>;
export type RegisterNodeWithVmMutationResult = Apollo.MutationResult<RegisterNodeWithVmMutation>;
export type RegisterNodeWithVmMutationOptions = Apollo.BaseMutationOptions<RegisterNodeWithVmMutation, RegisterNodeWithVmMutationVariables>;
export const RegisterNodeDocument = gql`
    mutation registerNode($node: RegisterNodeInput!) {
  commands {
    node {
      registerNode(node: $node)
    }
  }
}
    `;
export type RegisterNodeMutationFn = Apollo.MutationFunction<RegisterNodeMutation, RegisterNodeMutationVariables>;

/**
 * __useRegisterNodeMutation__
 *
 * To run a mutation, you first call `useRegisterNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerNodeMutation, { data, loading, error }] = useRegisterNodeMutation({
 *   variables: {
 *      node: // value for 'node'
 *   },
 * });
 */
export function useRegisterNodeMutation(baseOptions?: Apollo.MutationHookOptions<RegisterNodeMutation, RegisterNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterNodeMutation, RegisterNodeMutationVariables>(RegisterNodeDocument, options);
      }
export type RegisterNodeMutationHookResult = ReturnType<typeof useRegisterNodeMutation>;
export type RegisterNodeMutationResult = Apollo.MutationResult<RegisterNodeMutation>;
export type RegisterNodeMutationOptions = Apollo.BaseMutationOptions<RegisterNodeMutation, RegisterNodeMutationVariables>;
export const RemoveNodeContainerAppsDocument = gql`
    mutation removeNodeContainerApps($nodeId: ID!, $appDefinitionIds: [ID!]!) {
  commands {
    node {
      removeNodeContainerApps(nodeId: $nodeId, appDefinitionIds: $appDefinitionIds)
    }
  }
}
    `;
export type RemoveNodeContainerAppsMutationFn = Apollo.MutationFunction<RemoveNodeContainerAppsMutation, RemoveNodeContainerAppsMutationVariables>;

/**
 * __useRemoveNodeContainerAppsMutation__
 *
 * To run a mutation, you first call `useRemoveNodeContainerAppsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNodeContainerAppsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNodeContainerAppsMutation, { data, loading, error }] = useRemoveNodeContainerAppsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      appDefinitionIds: // value for 'appDefinitionIds'
 *   },
 * });
 */
export function useRemoveNodeContainerAppsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveNodeContainerAppsMutation, RemoveNodeContainerAppsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveNodeContainerAppsMutation, RemoveNodeContainerAppsMutationVariables>(RemoveNodeContainerAppsDocument, options);
      }
export type RemoveNodeContainerAppsMutationHookResult = ReturnType<typeof useRemoveNodeContainerAppsMutation>;
export type RemoveNodeContainerAppsMutationResult = Apollo.MutationResult<RemoveNodeContainerAppsMutation>;
export type RemoveNodeContainerAppsMutationOptions = Apollo.BaseMutationOptions<RemoveNodeContainerAppsMutation, RemoveNodeContainerAppsMutationVariables>;
export const RemoveFeatureFromNodeDocument = gql`
    mutation removeFeatureFromNode($nodeId: ID!, $featureId: ID!) {
  commands {
    node {
      removeFeature(nodeId: $nodeId, featureId: $featureId)
    }
  }
}
    `;
export type RemoveFeatureFromNodeMutationFn = Apollo.MutationFunction<RemoveFeatureFromNodeMutation, RemoveFeatureFromNodeMutationVariables>;

/**
 * __useRemoveFeatureFromNodeMutation__
 *
 * To run a mutation, you first call `useRemoveFeatureFromNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFeatureFromNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFeatureFromNodeMutation, { data, loading, error }] = useRemoveFeatureFromNodeMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      featureId: // value for 'featureId'
 *   },
 * });
 */
export function useRemoveFeatureFromNodeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFeatureFromNodeMutation, RemoveFeatureFromNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFeatureFromNodeMutation, RemoveFeatureFromNodeMutationVariables>(RemoveFeatureFromNodeDocument, options);
      }
export type RemoveFeatureFromNodeMutationHookResult = ReturnType<typeof useRemoveFeatureFromNodeMutation>;
export type RemoveFeatureFromNodeMutationResult = Apollo.MutationResult<RemoveFeatureFromNodeMutation>;
export type RemoveFeatureFromNodeMutationOptions = Apollo.BaseMutationOptions<RemoveFeatureFromNodeMutation, RemoveFeatureFromNodeMutationVariables>;
export const RemoveNodeDocument = gql`
    mutation removeNode($nodeId: ID!) {
  commands {
    node {
      removeNode(nodeId: $nodeId)
    }
  }
}
    `;
export type RemoveNodeMutationFn = Apollo.MutationFunction<RemoveNodeMutation, RemoveNodeMutationVariables>;

/**
 * __useRemoveNodeMutation__
 *
 * To run a mutation, you first call `useRemoveNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNodeMutation, { data, loading, error }] = useRemoveNodeMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useRemoveNodeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveNodeMutation, RemoveNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveNodeMutation, RemoveNodeMutationVariables>(RemoveNodeDocument, options);
      }
export type RemoveNodeMutationHookResult = ReturnType<typeof useRemoveNodeMutation>;
export type RemoveNodeMutationResult = Apollo.MutationResult<RemoveNodeMutation>;
export type RemoveNodeMutationOptions = Apollo.BaseMutationOptions<RemoveNodeMutation, RemoveNodeMutationVariables>;
export const SetNodeAppEnvironmentDocument = gql`
    mutation setNodeAppEnvironment($nodeId: ID!, $containerAppDefinitionId: ID!, $containerAppEnv: NodeContainerAppEnvironmentVariableInput!) {
  commands {
    node {
      setContainerEnvironmentVariables(
        nodeId: $nodeId
        containerAppDefinitionId: $containerAppDefinitionId
        containerAppEnvironmentVariables: $containerAppEnv
      )
    }
  }
}
    `;
export type SetNodeAppEnvironmentMutationFn = Apollo.MutationFunction<SetNodeAppEnvironmentMutation, SetNodeAppEnvironmentMutationVariables>;

/**
 * __useSetNodeAppEnvironmentMutation__
 *
 * To run a mutation, you first call `useSetNodeAppEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNodeAppEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNodeAppEnvironmentMutation, { data, loading, error }] = useSetNodeAppEnvironmentMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      containerAppDefinitionId: // value for 'containerAppDefinitionId'
 *      containerAppEnv: // value for 'containerAppEnv'
 *   },
 * });
 */
export function useSetNodeAppEnvironmentMutation(baseOptions?: Apollo.MutationHookOptions<SetNodeAppEnvironmentMutation, SetNodeAppEnvironmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNodeAppEnvironmentMutation, SetNodeAppEnvironmentMutationVariables>(SetNodeAppEnvironmentDocument, options);
      }
export type SetNodeAppEnvironmentMutationHookResult = ReturnType<typeof useSetNodeAppEnvironmentMutation>;
export type SetNodeAppEnvironmentMutationResult = Apollo.MutationResult<SetNodeAppEnvironmentMutation>;
export type SetNodeAppEnvironmentMutationOptions = Apollo.BaseMutationOptions<SetNodeAppEnvironmentMutation, SetNodeAppEnvironmentMutationVariables>;
export const SetNodeAppCustomTagDocument = gql`
    mutation setNodeAppCustomTag($nodeId: ID!, $containerAppDefinitionId: ID!, $customTag: String!) {
  commands {
    node {
      setNodeContainerCustomTag(
        nodeId: $nodeId
        containerAppDefinitionId: $containerAppDefinitionId
        customTag: $customTag
      )
    }
  }
}
    `;
export type SetNodeAppCustomTagMutationFn = Apollo.MutationFunction<SetNodeAppCustomTagMutation, SetNodeAppCustomTagMutationVariables>;

/**
 * __useSetNodeAppCustomTagMutation__
 *
 * To run a mutation, you first call `useSetNodeAppCustomTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNodeAppCustomTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNodeAppCustomTagMutation, { data, loading, error }] = useSetNodeAppCustomTagMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      containerAppDefinitionId: // value for 'containerAppDefinitionId'
 *      customTag: // value for 'customTag'
 *   },
 * });
 */
export function useSetNodeAppCustomTagMutation(baseOptions?: Apollo.MutationHookOptions<SetNodeAppCustomTagMutation, SetNodeAppCustomTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNodeAppCustomTagMutation, SetNodeAppCustomTagMutationVariables>(SetNodeAppCustomTagDocument, options);
      }
export type SetNodeAppCustomTagMutationHookResult = ReturnType<typeof useSetNodeAppCustomTagMutation>;
export type SetNodeAppCustomTagMutationResult = Apollo.MutationResult<SetNodeAppCustomTagMutation>;
export type SetNodeAppCustomTagMutationOptions = Apollo.BaseMutationOptions<SetNodeAppCustomTagMutation, SetNodeAppCustomTagMutationVariables>;
export const SetNodeStageDocument = gql`
    mutation setNodeStage($nodeId: ID!, $stage: String!) {
  commands {
    node {
      setNodeStage(nodeId: $nodeId, stage: $stage)
    }
  }
}
    `;
export type SetNodeStageMutationFn = Apollo.MutationFunction<SetNodeStageMutation, SetNodeStageMutationVariables>;

/**
 * __useSetNodeStageMutation__
 *
 * To run a mutation, you first call `useSetNodeStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNodeStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNodeStageMutation, { data, loading, error }] = useSetNodeStageMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useSetNodeStageMutation(baseOptions?: Apollo.MutationHookOptions<SetNodeStageMutation, SetNodeStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNodeStageMutation, SetNodeStageMutationVariables>(SetNodeStageDocument, options);
      }
export type SetNodeStageMutationHookResult = ReturnType<typeof useSetNodeStageMutation>;
export type SetNodeStageMutationResult = Apollo.MutationResult<SetNodeStageMutation>;
export type SetNodeStageMutationOptions = Apollo.BaseMutationOptions<SetNodeStageMutation, SetNodeStageMutationVariables>;
export const UpdateK8sInformationDocument = gql`
    mutation updateK8sInformation($nodeId: ID!, $values: [RegisterNodeWithK8s!]!) {
  commands {
    node {
      updateK8sInformation(nodeId: $nodeId, values: $values)
    }
  }
}
    `;
export type UpdateK8sInformationMutationFn = Apollo.MutationFunction<UpdateK8sInformationMutation, UpdateK8sInformationMutationVariables>;

/**
 * __useUpdateK8sInformationMutation__
 *
 * To run a mutation, you first call `useUpdateK8sInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateK8sInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateK8sInformationMutation, { data, loading, error }] = useUpdateK8sInformationMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateK8sInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateK8sInformationMutation, UpdateK8sInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateK8sInformationMutation, UpdateK8sInformationMutationVariables>(UpdateK8sInformationDocument, options);
      }
export type UpdateK8sInformationMutationHookResult = ReturnType<typeof useUpdateK8sInformationMutation>;
export type UpdateK8sInformationMutationResult = Apollo.MutationResult<UpdateK8sInformationMutation>;
export type UpdateK8sInformationMutationOptions = Apollo.BaseMutationOptions<UpdateK8sInformationMutation, UpdateK8sInformationMutationVariables>;
export const UpdateMachineInformationDocument = gql`
    mutation updateMachineInformation($nodeId: ID!, $machineInformation: MachineInformation!) {
  commands {
    node {
      updateMachineInformation(
        nodeId: $nodeId
        machineInformation: $machineInformation
      )
    }
  }
}
    `;
export type UpdateMachineInformationMutationFn = Apollo.MutationFunction<UpdateMachineInformationMutation, UpdateMachineInformationMutationVariables>;

/**
 * __useUpdateMachineInformationMutation__
 *
 * To run a mutation, you first call `useUpdateMachineInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMachineInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMachineInformationMutation, { data, loading, error }] = useUpdateMachineInformationMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      machineInformation: // value for 'machineInformation'
 *   },
 * });
 */
export function useUpdateMachineInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMachineInformationMutation, UpdateMachineInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMachineInformationMutation, UpdateMachineInformationMutationVariables>(UpdateMachineInformationDocument, options);
      }
export type UpdateMachineInformationMutationHookResult = ReturnType<typeof useUpdateMachineInformationMutation>;
export type UpdateMachineInformationMutationResult = Apollo.MutationResult<UpdateMachineInformationMutation>;
export type UpdateMachineInformationMutationOptions = Apollo.BaseMutationOptions<UpdateMachineInformationMutation, UpdateMachineInformationMutationVariables>;
export const UpdateNodeDefaultCustomTagDocument = gql`
    mutation updateNodeDefaultCustomTag($nodeId: ID!, $defaultCustomTag: String!) {
  commands {
    node {
      updateNodeDefaultCustomTag(nodeId: $nodeId, defaultCustomTag: $defaultCustomTag)
    }
  }
}
    `;
export type UpdateNodeDefaultCustomTagMutationFn = Apollo.MutationFunction<UpdateNodeDefaultCustomTagMutation, UpdateNodeDefaultCustomTagMutationVariables>;

/**
 * __useUpdateNodeDefaultCustomTagMutation__
 *
 * To run a mutation, you first call `useUpdateNodeDefaultCustomTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNodeDefaultCustomTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNodeDefaultCustomTagMutation, { data, loading, error }] = useUpdateNodeDefaultCustomTagMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      defaultCustomTag: // value for 'defaultCustomTag'
 *   },
 * });
 */
export function useUpdateNodeDefaultCustomTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNodeDefaultCustomTagMutation, UpdateNodeDefaultCustomTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNodeDefaultCustomTagMutation, UpdateNodeDefaultCustomTagMutationVariables>(UpdateNodeDefaultCustomTagDocument, options);
      }
export type UpdateNodeDefaultCustomTagMutationHookResult = ReturnType<typeof useUpdateNodeDefaultCustomTagMutation>;
export type UpdateNodeDefaultCustomTagMutationResult = Apollo.MutationResult<UpdateNodeDefaultCustomTagMutation>;
export type UpdateNodeDefaultCustomTagMutationOptions = Apollo.BaseMutationOptions<UpdateNodeDefaultCustomTagMutation, UpdateNodeDefaultCustomTagMutationVariables>;
export const AllRegistriesDocument = gql`
    query allRegistries {
  registry {
    all {
      id
      name
      url
    }
  }
}
    `;

/**
 * __useAllRegistriesQuery__
 *
 * To run a query within a React component, call `useAllRegistriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRegistriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRegistriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRegistriesQuery(baseOptions?: Apollo.QueryHookOptions<AllRegistriesQuery, AllRegistriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllRegistriesQuery, AllRegistriesQueryVariables>(AllRegistriesDocument, options);
      }
export function useAllRegistriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllRegistriesQuery, AllRegistriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllRegistriesQuery, AllRegistriesQueryVariables>(AllRegistriesDocument, options);
        }
export type AllRegistriesQueryHookResult = ReturnType<typeof useAllRegistriesQuery>;
export type AllRegistriesLazyQueryHookResult = ReturnType<typeof useAllRegistriesLazyQuery>;
export type AllRegistriesQueryResult = Apollo.QueryResult<AllRegistriesQuery, AllRegistriesQueryVariables>;
export const RegisterRegistryDocument = gql`
    mutation registerRegistry($registry: RegisterRegistryInput!) {
  commands {
    registry {
      registerRegistry(registry: $registry)
    }
  }
}
    `;
export type RegisterRegistryMutationFn = Apollo.MutationFunction<RegisterRegistryMutation, RegisterRegistryMutationVariables>;

/**
 * __useRegisterRegistryMutation__
 *
 * To run a mutation, you first call `useRegisterRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerRegistryMutation, { data, loading, error }] = useRegisterRegistryMutation({
 *   variables: {
 *      registry: // value for 'registry'
 *   },
 * });
 */
export function useRegisterRegistryMutation(baseOptions?: Apollo.MutationHookOptions<RegisterRegistryMutation, RegisterRegistryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterRegistryMutation, RegisterRegistryMutationVariables>(RegisterRegistryDocument, options);
      }
export type RegisterRegistryMutationHookResult = ReturnType<typeof useRegisterRegistryMutation>;
export type RegisterRegistryMutationResult = Apollo.MutationResult<RegisterRegistryMutation>;
export type RegisterRegistryMutationOptions = Apollo.BaseMutationOptions<RegisterRegistryMutation, RegisterRegistryMutationVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  user {
    current {
      id
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;