import { memo } from "react";
import { useParams } from "react-router-dom";
import { useNodeContainerDQuery } from "../../../graphql/generated/graphql";

export const ContainerDInfo = memo(function ContainerDInfo() {
  const { nodeId } = useParams();
  const { data } = useNodeContainerDQuery({ variables: { id: nodeId! } });

  if (!data) {
    return null;
  }

  return (
    <div className="flex">
      <table className="flex-1 h-1rem">
        <tbody>
          <tr>
            <td>Version</td>
            <td>{data.node?.byId?.containerD?.version}</td>
          </tr>
          <tr>
            <td>RuntimeName</td>
            <td>{data.node?.byId?.containerD?.runtimeName}</td>
          </tr>
        </tbody>
      </table>
      <table className="flex-1 h-1rem">
        <tbody>
          <tr>
            <td>RuntimeVersion</td>
            <td>{data.node?.byId?.containerD?.runtimeVersion}</td>
          </tr>
          <tr>
            <td>RuntimeApiVersion</td>
            <td>{data.node?.byId?.containerD?.runtimeApiVersion}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});
