import { memo, useCallback, useState } from "react";
import { ContainerDLogsSettings } from "./containerd-logs-settings";
import { ContainerDLogsTable } from "./containerd-logs-table";
import { SelectableContainer } from "./selectable-container";

export const ContainerDLogs = memo(function ContainerDLogs() {
  const [selectedContainer, setSelectedContainer] = useState<string | null>(null);
  const onContainerChange = useCallback((containerName: string) => {
    setSelectedContainer(containerName);
  }, []);

  return (
    <div className="flex-1 flex flex-column">
      <div className="mb-4">
        <SelectableContainer onChange={onContainerChange} />
      </div>
      {selectedContainer && <ContainerDLogsSettings>{(filter) => <ContainerDLogsTable containerName={selectedContainer} filter={filter} />}</ContainerDLogsSettings>}
    </div>
  );
});
