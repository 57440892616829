import { memo } from "react";
import { AppDefinitionDetail } from "./components/app-definition-detail";

export const AppDefinition = memo(function AppDefinition() {
  return (
    <div className="flex flex-1">
      <AppDefinitionDetail />
    </div>
  );
});
