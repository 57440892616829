import { memo, useCallback } from "react";
import { useNavigate } from "react-router";
import { K8sForm } from "../../../components/k8s-form/k8s-form";
import { RegisterNodeWithK8s, useRegisterK8sNodeMutation } from "../../../graphql/generated/graphql";

export const CreateK8s = memo(function CreateK8s({ onCancel }: { onCancel: () => void }) {
  const [registerK8sNodeMutation] = useRegisterK8sNodeMutation();
  const navigate = useNavigate();

  const onSave = useCallback(
    async (values: RegisterNodeWithK8s[]) => {
      const node = await registerK8sNodeMutation({
        variables: {
          input: values,
        },
      });

      navigate(`/node/${node.data?.commands?.node?.registerNodeWithK8s}`);
    },
    [registerK8sNodeMutation]
  );

  return <K8sForm onSave={onSave} onCancel={onCancel} />;
});
