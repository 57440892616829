import { useMsal } from "@azure/msal-react";
import { memo, useEffect, useState } from "react";
import { from } from "rxjs";
import { ShipperApollo } from "./shipper-apollo";

export const ShipperRouter = memo(function ShipperRouter() {
  const { instance } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [vaultToken, setVaultToken] = useState<string | null>(null);

  useEffect(() => {
    const account = instance.getActiveAccount();
    from(
      instance.acquireTokenSilent({
        account: account!,
        scopes: ["User.Read"],
      })
    ).subscribe((token) => {
      setAccessToken(token.accessToken);
    });

    from(instance.acquireTokenSilent({ scopes: ["https://vault.azure.net/user_impersonation"] })).subscribe((token) => {
      setVaultToken(token.accessToken);
    });
  }, [instance]);

  if (!accessToken || !vaultToken) {
    return null;
  }

  return <ShipperApollo accessToken={accessToken} vaultToken={vaultToken} />;
});
