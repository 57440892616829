import { memo } from "react";
import { useParams } from "react-router-dom";
import { useNodeInitializedQuery } from "../../../graphql/generated/graphql";
import { NoNodeRegistered } from "./no-node-registered";
import { NodeActions } from "./node-actions";
import { NodeInformation } from "./node-information";

export const NodeOverviewDetail = memo(function NodeOverviewDetail() {
  const { nodeId } = useParams();
  const { data } = useNodeInitializedQuery({ variables: { id: nodeId! } });

  const initialized = data?.node?.byId?.initialized ?? false;

  return (
    <div className="flex flex-column mr-5" style={{ width: "calc(100vw - 21rem)" }}>
      <NodeActions />
      <div className="p-card flex flex-1 flex-column">
        <div className="p-card-body flex flex-1 flex-column">
          {!initialized && <NoNodeRegistered />}
          {initialized && <NodeInformation />}
        </div>
      </div>
    </div>
  );
});
