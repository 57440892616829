import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTableSelectParams } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ChangeEvent, memo, useCallback, useState } from "react";
import { useParams } from "react-router";
import { useAddNodeContainerAppsByCategoryMutation, useAllAppDefinitionCategoriesQuery } from "../../../graphql/generated/graphql";
import { useElementVisibility } from "../../../hooks/useElementVisibility";

export const NodeAppsAddAppDefinition = memo(function NodeAppsAddAppDefinition() {
  const { nodeId } = useParams();
  const { elementVisibility, hideElement, showElement } = useElementVisibility(false);
  const [version, setVersion] = useState("");
  const { data } = useAllAppDefinitionCategoriesQuery();
  const [addNodeContainerAppsByCategory] = useAddNodeContainerAppsByCategoryMutation();
  const [selection, setSelection] = useState<{ name: string }>();

  const onRowSelect = useCallback((event: DataTableSelectParams) => {
    setSelection(event.data);
  }, []);

  const onAddAppDefinition = useCallback(async () => {
    await addNodeContainerAppsByCategory({
      variables: {
        category: selection?.name ?? "",
        nodeId: nodeId ?? "",
        version: version,
      },
    });
  }, [addNodeContainerAppsByCategory, nodeId, selection?.name, version]);

  const onChangeVersion = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setVersion(e.target.value);
  }, []);

  return (
    <>
      <Dialog
        onHide={hideElement}
        visible={elementVisibility}
        header="Add App Definition"
        footer={<Button label="Add" icon={<Icon path={mdiPlus} size={0.7} />} onClick={onAddAppDefinition} />}
      >
        <div className="p-fluid">
          <DataTable value={data?.appDefinition?.allCategories ?? []} onRowSelect={onRowSelect} selection={selection}>
            <Column selectionMode="single" style={{ width: "3em" }} />
            <Column field="name" header="Name" />
          </DataTable>
        </div>
        <span className="p-float-label mt-6">
          <InputText id="version" value={version} onChange={onChangeVersion} />
          <label htmlFor="version">Version</label>
        </span>
      </Dialog>
      <Button label="Add App Definition" className="ml-2" icon={<Icon path={mdiPlus} size={0.7} />} onClick={showElement} />
    </>
  );
});
