import { memo } from "react";
import { NodeMenu } from "../../components/node-menu/node-menu";
import { ReportedErrorsOverview } from "./components/reported-errors-overview";

export const NodeReportedErrors = memo(function NodeReportedErrors() {
  return (
    <div className="flex flex-1">
      <NodeMenu />
      <ReportedErrorsOverview />
    </div>
  );
});
