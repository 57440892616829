import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router";
import { GraphqlForm } from "../../../components/graphql-form/graphql-form";
import { NodeContainerAppEnvironmentVariableInput, useNodeContainerEnvironmentVariablesQuery, useSetNodeAppEnvironmentMutation } from "../../../graphql/generated/graphql";
import { useElementVisibility } from "../../../hooks/useElementVisibility";
const schemaFile = require("../../../schema.graphql");

export interface NodeAppEditEnvironmentProps {
  id: string;
}

export const NodeAppEditEnvironment = function NodeAppEditEnvironment({ id }: NodeAppEditEnvironmentProps) {
  const { elementVisibility, hideElement, showElement } = useElementVisibility(false);
  const [setNodeAppEnvironment] = useSetNodeAppEnvironmentMutation();
  const { nodeId } = useParams();
  const { data: nodeContainerAppEnvironmentVariables } = useNodeContainerEnvironmentVariablesQuery({ variables: { id: nodeId! } });

  const initialData = useMemo<NodeContainerAppEnvironmentVariableInput>(() => {
    const nodeContainerEnvironmentVariables = nodeContainerAppEnvironmentVariables?.node?.byId?.nodeContainerOverrides.find((variable) => variable.containerAppId === id);

    return {
      variables: nodeContainerEnvironmentVariables?.variables ?? [],
    };
  }, [nodeContainerAppEnvironmentVariables, id]);

  const onSubmit = useCallback(
    async (data: NodeContainerAppEnvironmentVariableInput) => {
      await setNodeAppEnvironment({
        variables: {
          containerAppEnv: data,
          nodeId: nodeId!,
          containerAppDefinitionId: id,
        },
      });
    },
    [id, nodeId, setNodeAppEnvironment]
  );

  return (
    <div>
      <Button onClick={showElement}>Set Env</Button>

      <Dialog visible={elementVisibility} onHide={hideElement} style={{ width: "400px" }} header="Edit App Environment">
        <GraphqlForm<NodeContainerAppEnvironmentVariableInput>
          schemaFile={schemaFile}
          inputTypeName="NodeContainerAppEnvironmentVariableInput"
          initialData={initialData}
          onSubmit={onSubmit}
          onCancel={hideElement}
        />
      </Dialog>
    </div>
  );
};
