import { memo, useCallback } from "react";
import { useParams } from "react-router";
import { noop } from "rxjs";
import { K8sForm } from "../../../components/k8s-form/k8s-form";
import { RegisterNodeWithK8s, useNodeK8sValuesQuery, useUpdateK8sInformationMutation } from "../../../graphql/generated/graphql";

export const K8sSettings = memo(function K8sSettings() {
  const { nodeId } = useParams();
  const { data } = useNodeK8sValuesQuery({ variables: { id: nodeId! } });
  const [updateK8sInformation] = useUpdateK8sInformationMutation();

  const onSave = useCallback(
    async (values: RegisterNodeWithK8s[]) => {
      updateK8sInformation({
        variables: {
          nodeId: nodeId!,
          values: values,
        },
      });
    },
    [nodeId, updateK8sInformation]
  );

  if (!data?.node?.byId?.k8sValues) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex-1 flex">
      <K8sForm onCancel={noop} onSave={onSave} preValues={data?.node?.byId?.k8sValues} />
    </div>
  );
});
