import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router";
import { RegisterNodeWithVm, useRegisterNodeWithVmMutation } from "../../../graphql/generated/graphql";
import { useElementVisibility } from "../../../hooks/useElementVisibility";
import { CreateK8s } from "./create-k8s";
const schemaFile = require("../../../schema.graphql");

export const RegisterNodeVm = memo(function RegisterNodeVm() {
  const [registerNodeWithVm] = useRegisterNodeWithVmMutation();
  const { elementVisibility: elementK8sVisibility, hideElement: hideK8sElement, showElement: showK8sElement } = useElementVisibility(false);
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (values: RegisterNodeWithVm) => {
      const data = await registerNodeWithVm({
        variables: {
          nodeWithVm: values,
        },
      });

      const nodeId = data.data?.commands?.node?.registerNodeWithVm;

      navigate(`/node/${nodeId}/vmlog`);
    },
    [navigate, registerNodeWithVm]
  );

  return (
    <>
      <Dialog onHide={hideK8sElement} visible={elementK8sVisibility} header="Add Node">
        <div className="p-fluid">
          <CreateK8s onCancel={hideK8sElement} />
        </div>
      </Dialog>
      <Button label="Add Node with K8s" icon={<Icon path={mdiPlus} size={0.7} />} onClick={showK8sElement} />
    </>
  );
});
