import dayjs from "dayjs";

export function timeRenderer<T>(fieldName: keyof T) {
  return (data: T) => {
    const val = data[fieldName];
    if (!val) {
      return <>N/A</>;
    }

    const time = dayjs(data[fieldName] as any);

    return <>{time.format("L LTS")}</>;
  };
}
