import { GraphQLInputField, Kind } from "graphql";
import { useMemo } from "react";

export function useIsYearFuture(field: GraphQLInputField) {
  return useMemo(() => {
    if (!field.astNode?.directives?.length) {
      return null;
    }

    if (field.astNode.directives[0].name.value !== "asYearFuture") {
      return null;
    }

    const intRangeArguments = field.astNode.directives[0]?.arguments;
    const futureYearsValue = intRangeArguments?.[0].value;
    if (futureYearsValue?.kind === Kind.INT) {
      return {
        yearFuture: parseInt(futureYearsValue.value, 10),
      };
    }

    return null;
  }, [field]);
}
