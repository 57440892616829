import { memo } from "react";
import { SideMenu } from "../../components/side-menu/side-menu";
import { AppDefinitionsListCard } from "./components/app-definitions-list-card";

export const AppDefinitions = memo(function AppDefinitions() {
  return (
    <div className="flex flex-1">
      <SideMenu />
      <AppDefinitionsListCard />
    </div>
  );
});
