import { TabPanel, TabView } from "primereact/tabview";
import { memo } from "react";
import { ContainerDInfo } from "./containerd-info";
import { ContainerDLogs } from "./containerd-logs/containerd-logs";
import { ContainerDStats } from "./containerd-stats";

export const ContainerDOverview = memo(function ContainerDOverview() {
  return (
    <div className="flex flex-column mr-5" style={{ width: "calc(100vw - 21rem)" }}>
      <div className="p-card flex flex-1 flex-column">
        <div className="p-card-body flex flex-1 flex-column">
          <ContainerDInfo />
          <TabView className="flex-1 flex flex-column" panelContainerClassName="flex-1 flex flex-column">
            <TabPanel header="Containers" className="flex-1 flex flex-column">
              <ContainerDStats />
            </TabPanel>
            <TabPanel header="Logs" className="flex-1 flex flex-column">
              <ContainerDLogs />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
});
