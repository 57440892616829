import { memo } from "react";
import { NodeMenu } from "../../components/node-menu/node-menu";
import { NodeFeaturesList } from "./components/node-features-list";

export const NodeFeatures = memo(function NodeFeatures() {
  return (
    <div className="flex flex-1">
      <NodeMenu />
      <NodeFeaturesList />
    </div>
  );
});
