import { memo } from "react";
import { NodeMenu } from "../../components/node-menu/node-menu";
import { ContainerDOverview } from "./components/containerd-overview";

export const NodeContainerD = memo(function NodeContainerD() {
  return (
    <div className="flex flex-1">
      <NodeMenu />
      <ContainerDOverview />
    </div>
  );
});
