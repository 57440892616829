import { buildASTSchema, GraphQLInputObjectType, parse } from "graphql";
import { Button } from "primereact/button";
import { SelectItemOptionsType } from "primereact/selectitem";
import { memo, useEffect, useMemo, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { from, map, switchMap } from "rxjs";
import ResolverContext, { ResolverContextType } from "./context/resolver-context";
import { ButtonSubmit } from "./fields/button-submit";
import InputFields from "./input-fields";

const genericMemo: <T>(component: T) => T = memo;

export const GraphqlForm = genericMemo(function GraphqlForm<TData extends FieldValues>({
  schemaFile,
  inputTypeName,
  onSubmit,
  onResolveContextDataForDropdown,
  onCancel,
  initialData,
}: {
  initialData?: TData;
  schemaFile: string;
  inputTypeName: string;
  onCancel?: () => void;
  onSubmit: (data: TData) => void;
  onResolveContextDataForDropdown?: (contextName: string) => Promise<SelectItemOptionsType>;
}) {
  const form = useForm<TData>({
    defaultValues: initialData as any,
  });
  const [graphQlInputType, setGraphQlInputType] = useState<GraphQLInputObjectType>();

  useEffect(() => {
    const subscription = from(fetch(schemaFile))
      .pipe(
        switchMap((schemaResponse) => schemaResponse.text()),
        map((schemaText) => buildASTSchema(parse(schemaText)))
      )
      .subscribe((graphqlSchema) => {
        const inputType = graphqlSchema.getType(inputTypeName) as GraphQLInputObjectType;
        setGraphQlInputType(inputType);
      });

    return () => subscription.unsubscribe();
  }, [schemaFile, inputTypeName]);

  const resolverContext = useMemo<ResolverContextType>(
    () => ({
      resolveContextDataForDropdown: onResolveContextDataForDropdown ?? (() => Promise.resolve([])),
    }),
    [onResolveContextDataForDropdown]
  );

  if (!graphQlInputType) {
    return null;
  }

  return (
    <ResolverContext.Provider value={resolverContext}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit as (data: any) => void)}>
          <InputFields inputObjectField={graphQlInputType} />
          <div className="flex mt-2">
            <div className="mr-2">
              <ButtonSubmit />
            </div>
            <div>
              <Button type="button" label="Cancel" onClick={onCancel} />
            </div>
          </div>
        </form>
      </FormProvider>
    </ResolverContext.Provider>
  );
});
