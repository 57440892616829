import { GraphQLEnumType, GraphQLInputField } from "graphql";
import { Dropdown } from "primereact/dropdown";
import { memo } from "react";
import { useController } from "react-hook-form";

export const EnumFieldDropdown = memo(
  ({
    field,
    type,
    name,
  }: {
    field: GraphQLInputField;
    type: GraphQLEnumType;
    name: string;
  }) => {
    const {
      field: { onChange, onBlur, value },
      fieldState: { error },
    } = useController({ name, rules: { required: true } });

    return (
      <div className="field">
        <label>{field.name}</label>
        <Dropdown
          options={type.getValues().map((val) => ({
            label: val.value,
            value: val.name,
          }))}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          className={!!error ? "p-invalid" : ""}
        />
        {error && <small>{error.type}</small>}
      </div>
    );
  }
);
