import { ApolloClient, createHttpLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { sha256 } from "crypto-hash";
import { useEffect, useMemo } from "react";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { useIsLoggedIn } from "./is-logged-in";

const uri = process.env.REACT_APP_BACKEND_URL ?? "http://localhost:8090/graphql";

export function useApolloClient(accessToken: string, vaultToken: string) {
  const { loggedIn } = useIsLoggedIn();

  const subscriptionClient = useMemo(
    () =>
      new SubscriptionClient(uri.replace("https://", "wss://").replace("http://", "ws://"), {
        reconnect: true,
        lazy: true,
        connectionParams() {
          return {
            Authorization: `Bearer ${accessToken}`,
          };
        },
      }),
    [accessToken]
  );

  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri,
    });

    const wsLink = new WebSocketLink(subscriptionClient);

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
          Vault: vaultToken,
        },
      };
    });

    const link = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query) as any;
        return kind === "OperationDefinition" && operation === "subscription";
      },
      wsLink,
      authLink.concat(httpLink)
    );

    const persistedQueryLink = createPersistedQueryLink({
      sha256,
    }).concat(link);

    return new ApolloClient({
      link: persistedQueryLink,
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: "network-only",
          errorPolicy: "all",
        },
      },
    });
  }, [subscriptionClient, accessToken]);

  useEffect(() => {
    if (!loggedIn) {
      subscriptionClient.close(true);
      client.stop();
      client.clearStore();
      client.resetStore();
    }
  }, [loggedIn, subscriptionClient, client]);

  return client;
}
