import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { memo, useCallback } from "react";
import { GraphqlForm } from "../../../components/graphql-form/graphql-form";
import { RegisterContainerAppDefinitionInput, useRegisterContainerAppDefinitionMutation } from "../../../graphql/generated/graphql";
import { useElementVisibility } from "../../../hooks/useElementVisibility";
const schemaFile = require("../../../schema.graphql");

export const RegisterAppDefinition = memo(function RegisterAppDefinition() {
  const [registerContainerAppDefinition] = useRegisterContainerAppDefinitionMutation();
  const { elementVisibility, hideElement, showElement } = useElementVisibility(false);
  const onSubmit = useCallback(
    async (values: RegisterContainerAppDefinitionInput) => {
      console.log(values);
      await registerContainerAppDefinition({
        variables: {
          containerAppDefinition: values,
        },
      });
    },
    [registerContainerAppDefinition]
  );

  return (
    <>
      <Dialog onHide={hideElement} visible={elementVisibility} header="Add app definition">
        <div className="p-fluid">
          <GraphqlForm<RegisterContainerAppDefinitionInput> schemaFile={schemaFile} inputTypeName="RegisterContainerAppDefinitionInput" onSubmit={onSubmit} />
        </div>
      </Dialog>
      <Button label="Add App Definition" icon={<Icon path={mdiPlus} size={0.7} />} onClick={showElement} />
    </>
  );
});
