export function userRenderer<T>(fieldName: keyof T) {
  return (data: T) => {
    const userObj = data[fieldName] as unknown as {
      firstname: string;
      lastname: string;
    };

    return (
      <>
        {userObj.firstname} {userObj.lastname}
      </>
    );
  };
}
