import { mdiPencil, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTableSelectionChangeParams } from "primereact/datatable";
import { memo, useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAllAppDefinitionsQuery, useRemoveAppDefinitionMutation } from "../../../graphql/generated/graphql";
import { RegisterAppDefinition } from "./register-app-definition";

export const AppDefinitionsList = memo(function AppDefinitionsList({ onSelection }: { onSelection?: (value: any) => void }) {
  const navigate = useNavigate();
  const { appDefinitionId } = useParams();
  const { data } = useAllAppDefinitionsQuery();
  const [removeAppDefinition] = useRemoveAppDefinitionMutation();
  const [selection, setSelection] = useState<any>();
  const onSelectionChange = useCallback(
    (e: DataTableSelectionChangeParams) => {
      setSelection(e.value);
      if (onSelection) {
        onSelection(e.value);
      }
    },
    [onSelection]
  );

  const onEdit = useCallback(() => {
    navigate(`/app-definition/${selection.id}`);
  }, [navigate, selection]);

  const allAppDefinitions = useMemo(() => data?.appDefinition?.all ?? [], [data]);

  const onDelete = useCallback(() => {
    removeAppDefinition({
      variables: {
        appDefinitionId: selection.id!,
      },
    });
  }, [selection, removeAppDefinition]);

  const selectedRegistry = useMemo(() => {
    if (onSelection) {
      return selection;
    }

    if (!appDefinitionId) {
      return null;
    }

    return allAppDefinitions?.find((item) => item.id === appDefinitionId);
  }, [allAppDefinitions, appDefinitionId, onSelection, selection]);

  if (!allAppDefinitions) {
    return null;
  }

  return (
    <DataTable
      className="flex-1"
      selectionMode={onSelection ? "multiple" : "single"}
      metaKeySelection={false}
      value={allAppDefinitions}
      selection={selectedRegistry}
      onSelectionChange={onSelectionChange}
      header={
        onSelection ? null : (
          <div className="flex-1">
            <RegisterAppDefinition />
            <Button label="Edit App Definition" icon={<Icon path={mdiPencil} size={0.7} />} className="p-button ml-2" onClick={onEdit} />
            <Button label="Delete App Definition" icon={<Icon path={mdiTrashCan} size={0.7} />} className="p-button-danger ml-2" onClick={onDelete} />
          </div>
        )
      }
    >
      <Column field="containerName" header="ContainerName" />
      <Column field="imageName" header="ImageName" />
    </DataTable>
  );
});
