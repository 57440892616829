import { GraphQLInputField } from "graphql";
import { memo, useMemo } from "react";
import { DropdownResolverField } from "./dropdrown-resolver-field";
import { TextField } from "./text-field";

export const StringField = memo(function StringField({
  field,
  name,
}: {
  field: GraphQLInputField;
  name: string;
}) {
  const asCustomResolver = useMemo(
    () =>
      field.astNode?.directives?.some(
        (item) => item.name.value === "asCustomResolver"
      ) ?? false,
    [field.astNode?.directives]
  );

  return (
    <>
      {asCustomResolver && <DropdownResolverField field={field} name={name} />}
      {!asCustomResolver && <TextField field={field} name={name} />}
    </>
  );
});
