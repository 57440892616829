import { memo } from "react";
import { SideMenu } from "../../components/side-menu/side-menu";
import { RegistriesList } from "./components/registries-list";

export const Registries = memo(function Registries() {
  return (
    <div className="flex flex-1">
      <SideMenu />
      <RegistriesList />
    </div>
  );
});
