import { DropdownChangeParams } from "primereact/dropdown";
import { useCallback, useState } from "react";
import { useAvailableStagesQuery, useGetNodeStageQuery, useSetNodeStageMutation } from "../../../../graphql/generated/graphql";

export function useNodeStages(nodeId: string | undefined): {
  selectedStage: { name: string; harbor: string } | undefined;
  onStageSelected: (e: DropdownChangeParams) => Promise<void>;
  availableStages: { name: string; harbor: string }[] | undefined;
} {
  const [setNodeStage] = useSetNodeStageMutation();
  const [selectedStage, setSelectedStage] = useState<undefined | { name: string; harbor: string }>(undefined);

  const [stages, setStages] = useState<{ name: string; harbor: string }[]>();

  useAvailableStagesQuery({
    onCompleted: (data) => {
      const stages = data.node?.availableStages?.map((stage) => {
        return { name: stage?.displayName!, harbor: stage?.stageName! };
      });
      setStages(stages);
    },
  });

  useGetNodeStageQuery({
    variables: {
      nodeId: nodeId!,
    },
    onCompleted: (data) => {
      setSelectedStage(stages?.find((stage) => stage.harbor === data?.node?.byId?.stage));
    },
  });

  const onStageSelected = useCallback(
    async (e: DropdownChangeParams) => {
      setSelectedStage(e.value);
      await setNodeStage({
        variables: {
          nodeId: nodeId!,
          stage: e.value.harbor,
        },
      });
    },
    [nodeId, setNodeStage]
  );

  return {
    selectedStage,
    onStageSelected,
    availableStages: stages,
  };
}
