import { memo } from "react";
import { useParams } from "react-router";
import { useNodeNameQuery } from "../../graphql/generated/graphql";

export const NodeName = memo(function NodeName() {
  const { nodeId } = useParams();
  const { data } = useNodeNameQuery({ variables: { id: nodeId! } });

  return (
    <div className="p-3">
      <h4>{data?.node?.byId?.name}</h4>
    </div>
  );
});
