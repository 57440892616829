import { GraphQLInputObjectType } from "graphql";
import { memo, useMemo } from "react";
import { InputField } from "./input-field";

export const InputFields = memo(
  ({ inputObjectField }: { inputObjectField: GraphQLInputObjectType }) => {
    const fields = useMemo(
      () => Object.values(inputObjectField.getFields()),
      [inputObjectField]
    );

    return (
      <>
        {fields.map((field) => (
          <InputField field={field} key={field.name} name={field.name} />
        ))}
      </>
    );
  }
);

export default InputFields;
