import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { memo, useCallback, useRef } from "react";
import { useParams } from "react-router";
import { useAddNodeContainerAppsMutation } from "../../../graphql/generated/graphql";
import { useElementVisibility } from "../../../hooks/useElementVisibility";
import { AppDefinitionsList } from "../../app-definitions-overview/components/app-definitions-list";

export const NodeAppsAddApp = memo(function NodeAppsAddApp() {
  const { nodeId } = useParams();
  const { elementVisibility, hideElement, showElement } = useElementVisibility(false);
  const selectionRef = useRef<{ id: string }[]>();
  const [addNodeContainerApps] = useAddNodeContainerAppsMutation();
  const onSelection = useCallback((value: { id: string }[]) => {
    selectionRef.current = value;
  }, []);

  const onAddAppDefinition = useCallback(async () => {
    await addNodeContainerApps({
      variables: {
        nodeId: nodeId!,
        appDefinitionIds: selectionRef.current!.map((item) => item.id),
      },
    });
  }, [addNodeContainerApps, nodeId]);

  return (
    <>
      <Dialog
        onHide={hideElement}
        visible={elementVisibility}
        header="Add App Definition"
        footer={<Button label="Add" icon={<Icon path={mdiPlus} size={0.7} />} onClick={onAddAppDefinition} />}
      >
        <div className="p-fluid">
          <AppDefinitionsList onSelection={onSelection} />
        </div>
      </Dialog>
      <Button label="Add App" className="ml-2" icon={<Icon path={mdiPlus} size={0.7} />} onClick={showElement} />
    </>
  );
});
