import dayjs from "dayjs";
import { GraphQLInputField } from "graphql";
import { Dropdown } from "primereact/dropdown";
import { memo, useMemo } from "react";
import { useController } from "react-hook-form";

export const YearFutureRange = memo(function YearFutureRange({
  field,
  name,
  yearFuture,
}: {
  field: GraphQLInputField;
  name: string;
  yearFuture: number;
}) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name, rules: { required: true } });

  const options = useMemo(() => {
    const yearOptions = [];
    const currentYear = dayjs().year();

    for (let i = 0; i < yearFuture; i++) {
      yearOptions.push(currentYear + i);
    }
    return yearOptions;
  }, [yearFuture]);

  return (
    <div className="field">
      <label>{field.name}</label>
      <Dropdown
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        className={!!error ? "p-invalid" : ""}
      />
    </div>
  );
});
