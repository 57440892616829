import { mdiCheck } from "@mdi/js";
import { Icon } from "@mdi/react";
import { Button } from "primereact/button";
import { memo } from "react";
import { useFormState } from "react-hook-form";

export const ButtonSubmit = memo(() => {
  const { isSubmitting } = useFormState();

  return (
    <div className="field">
      <Button loading={isSubmitting} type="submit" icon={<Icon path={mdiCheck} size={0.7} />} label="Save" />
    </div>
  );
});
