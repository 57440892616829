import { GraphQLInputField, GraphQLInputObjectType, Kind } from "graphql";
import { Button } from "primereact/button";
import { Fragment, memo, useCallback, useEffect, useMemo } from "react";
import { useFieldArray } from "react-hook-form";
import { InputField } from "../input-field";
import { ButtonDeleteListEntry } from "./button-delete-list-entry";

export const ListObjectField = memo(({ field, type, name }: { field: GraphQLInputField; type: GraphQLInputObjectType; name: string }) => {
  const { fields, append, remove } = useFieldArray({ name });

  const minListValue = useMemo(() => {
    const minListLength = field.astNode?.directives?.find((directive) => directive.name.value === "minListLength");
    if (minListLength) {
      const minValue = minListLength.arguments?.[0].value;
      if (minValue && minValue.kind === Kind.INT) {
        return parseInt(minValue.value);
      }
    }

    return 0;
  }, [field]);

  const isHorizontalValue = useMemo(() => {
    const isHorizontal = field.astNode?.directives?.find((directive) => directive.name.value === "isHorizontal");

    return !!isHorizontal;
  }, [field]);

  useEffect(() => {
    for (let i = 0; i < minListValue; i++) {
      append({});
    }
  }, [append, minListValue]);

  const onAdd = useCallback(() => {
    append({});
  }, [append]);

  return (
    <>
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <div className={"flex " + (isHorizontalValue ? "justify-content-between flex-row" : "flex-column")}>
            {Object.values(type.getFields()).map((field) => (
              <InputField field={field} key={field.name} name={`${name}.${index}.${field.name}`} />
            ))}
          </div>
          <ButtonDeleteListEntry minListValue={minListValue} currentListLength={fields.length} currentIndex={index} onRemoveListEntry={remove} />
        </Fragment>
      ))}
      <Button onClick={onAdd} type="button" className="mt-2">
        Add {field.name}
      </Button>
    </>
  );
});
