import { getNullableType, GraphQLInputField, GraphQLInputType, GraphQLList, isEnumType, isInputObjectType } from "graphql";
import { memo, useMemo } from "react";
import { ListFieldEnum } from "./list-field-enum";
import { ListObjectField } from "./list-object-field";
// import { UserSelectorField } from "./user-selector-field.tsx_";

export const ListField = memo(({ field, type, name }: { field: GraphQLInputField; type: GraphQLList<GraphQLInputType>; name: string }) => {
  const asUserSelector = useMemo(() => field.astNode?.directives?.some((item) => item.name.value === "asUserSelector") ?? false, [field.astNode?.directives]);

  const listType = getNullableType(type.ofType);
  if (isInputObjectType(listType)) {
    const objectFields = Object.values(listType.getFields());
    const firstField = objectFields[0];
    const firstFieldType = getNullableType(firstField.type);
    if (isEnumType(firstFieldType)) {
      return <ListFieldEnum name={name} field={field} enumField={firstFieldType} objectFields={[...objectFields].slice(1)} />;
    } else {
      return <ListObjectField field={field} name={name} type={listType} />;
    }
  } else if (isEnumType(listType)) {
    return <ListFieldEnum name={name} field={field} enumField={listType} />;
  } else if (asUserSelector) {
    return null;
  }

  return <div>todo</div>;
});
