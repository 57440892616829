import { ApolloProvider } from "@apollo/client";
import { memo } from "react";
import { Route, Routes } from "react-router";
import { HashRouter } from "react-router-dom";
import { EnsureLoggedIn } from "./components/ensure-logged-in/ensure-logged-in";
import { useApolloClient } from "./hooks/use-apollo-client";
import { AppDefinition } from "./pages/app-definition/app-definition";
import { AppDefinitions } from "./pages/app-definitions-overview/app-definitions";
import { Dashboard } from "./pages/dashboard/dashboard";
import { Features } from "./pages/features/features";
import { MachineInformation } from "./pages/machine-information/machine-information";
import { NodeApps } from "./pages/node-apps/node-apps";
import { NodeContainerD } from "./pages/node-containerd/node-containerd";
import { NodeFeatures } from "./pages/node-features/node-features";
import { NodeK8sSettings } from "./pages/node-k8s/node-k8s-settings";
import { Node } from "./pages/node-overview/node-overview";
import { NodeReportedErrors } from "./pages/node-reported-errors/node-reported-errors";
import { NodeVmLog } from "./pages/node-vm-log/node-vm-log";
import { Nodes } from "./pages/nodes/nodes";
import { Registries } from "./pages/registries/registries";
import { Users } from "./pages/users/users";

export const ShipperApollo = memo(function ShipperApollo({ accessToken, vaultToken }: { accessToken: string; vaultToken: string }) {
  const client = useApolloClient(accessToken, vaultToken);

  return (
    <HashRouter>
      <ApolloProvider client={client}>
        <Routes>
          <Route
            path="/"
            element={
              <EnsureLoggedIn>
                <Dashboard />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/nodes"
            element={
              <EnsureLoggedIn>
                <Nodes />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/node/:nodeId"
            element={
              <EnsureLoggedIn>
                <Node />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/node/:nodeId/apps"
            element={
              <EnsureLoggedIn>
                <NodeApps />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/node/:nodeId/containerd"
            element={
              <EnsureLoggedIn>
                <NodeContainerD />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/node/:nodeId/vmlog"
            element={
              <EnsureLoggedIn>
                <NodeVmLog />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/node/:nodeId/machineinformation"
            element={
              <EnsureLoggedIn>
                <MachineInformation />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/node/:nodeId/k8ssettings"
            element={
              <EnsureLoggedIn>
                <NodeK8sSettings />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/node/:nodeId/errors"
            element={
              <EnsureLoggedIn>
                <NodeReportedErrors />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/node/:nodeId/features"
            element={
              <EnsureLoggedIn>
                <NodeFeatures />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/registries"
            element={
              <EnsureLoggedIn>
                <Registries />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/registry/:registryId"
            element={
              <EnsureLoggedIn>
                <Registries />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/app-definitions"
            element={
              <EnsureLoggedIn>
                <AppDefinitions />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/features"
            element={
              <EnsureLoggedIn>
                <Features />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/app-definition/:appDefinitionId"
            element={
              <EnsureLoggedIn>
                <AppDefinition />
              </EnsureLoggedIn>
            }
          />
          <Route
            path="/users"
            element={
              <EnsureLoggedIn>
                <Users />
              </EnsureLoggedIn>
            }
          />
        </Routes>
      </ApolloProvider>
    </HashRouter>
  );
});
