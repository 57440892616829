import { GraphQLInputField, Kind } from "graphql";
import { Dropdown } from "primereact/dropdown";
import { SelectItemOptionsType } from "primereact/selectitem";
import { memo, useContext, useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { from } from "rxjs";
import ResolverContext from "../context/resolver-context";

export const DropdownResolverField = memo(function DropdownResolverField({
  field,
  name,
}: {
  field: GraphQLInputField;
  name: string;
}) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name, rules: { required: true } });
  const context = useContext(ResolverContext);
  const [options, setOptions] = useState<SelectItemOptionsType>([]);

  useEffect(() => {
    const asCustomResolver = field.astNode?.directives?.find(
      (item) => item.name.value === "asCustomResolver"
    );

    if (!asCustomResolver) {
      return;
    }

    const resolverTypeArg = asCustomResolver.arguments?.find(
      (arg) => arg.name.value === "resolverType"
    );
    if (!resolverTypeArg || resolverTypeArg.value.kind !== Kind.STRING) {
      return;
    }

    const resolverName = resolverTypeArg.value.value;

    if (!context) {
      return;
    }

    const subscription = from(
      context.resolveContextDataForDropdown(resolverName)
    ).subscribe((data) => {
      setOptions(data);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [field, context]);

  return (
    <div className="field">
      <label>{field.name}</label>
      <Dropdown
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        className={!!error ? "p-invalid" : ""}
      />
      {error && <small>{error.type}</small>}
    </div>
  );
});
