import { useInfiniteQuery } from "@tanstack/react-query";
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import { ContextMenu } from "primereact/contextmenu";
import { MenuItem } from "primereact/menuitem";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { useVirtual } from "react-virtual";
import { useContainerLogMessagesLazyQuery } from "../../../../graphql/generated/graphql";
import { ContainerDLogsFilter } from "./containerd-logs-filter";

import "./containerd-logs-table.css";

export interface ContainerDLogsTableProps {
  containerName: string;
  filter: ContainerDLogsFilter;
}

export interface LogMessage {
  time: string;
  level: string;
  callsite: string;
  message: string;
  allEventProperties: string;
  threadId: string;
  callsiteLinenumber: string;
}

const fetchSize = 25;

export const ContainerDLogsTable = memo<ContainerDLogsTableProps>(function ContainerDLogsTable({ containerName, filter }) {
  const cm = useRef<ContextMenu>(null);
  const logMessageRef = useRef<LogMessage | null>(null);
  const endReached = useRef(false);
  const { nodeId } = useParams();
  const [loadContainerLogMessages] = useContainerLogMessagesLazyQuery();
  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [sorting, setSorting] = useState<SortingState>([]);

  const logMenu = useMemo<MenuItem[]>(
    () => [
      {
        label: "Hide Logger",
        icon: "pi pi-eye-slash",
        command: (e) => {
          filter.onHideLogger(logMessageRef.current?.callsite ?? "");
        },
      },
    ],
    [filter]
  );

  useEffect(() => {
    endReached.current = false;
  }, [filter]);

  const columns = useMemo<ColumnDef<LogMessage>[]>(
    () => [
      {
        accessorKey: "level",
        cell: (info) => info.getValue(),
        header: () => "Level",
      },
      {
        accessorKey: "time",
        cell: (info) => info.getValue(),
        header: () => "Time",
      },
      {
        accessorKey: "callsite",
        cell: (info) => info.getValue(),
        header: () => "Callsite",
      },
      {
        accessorKey: "message",
        cell: (info) => info.getValue(),
        header: () => "Message",
      },
      {
        accessorKey: "allEventProperties",
        cell: (info) => info.getValue(),
        header: () => "AllEventProperties",
      },
      {
        accessorKey: "threadId",
        cell: (info) => info.getValue(),
        header: () => "ThreadId",
      },
      {
        accessorKey: "callsiteLinenumber",
        cell: (info) => info.getValue(),
        header: () => "CallsiteLinenumber",
      },
    ],
    []
  );

  //react-query has an useInfiniteQuery hook just for this situation!
  const { data, fetchNextPage, isFetching, /*isLoading,*/ refetch, remove } = useInfiniteQuery(
    ["table-data", sorting], //adding sorting state as key causes table to reset and fetch from new beginning upon sort
    async ({ pageParam = 0 }) => {
      const start = pageParam * fetchSize;
      console.log(start);

      const logMessages = await loadContainerLogMessages({
        variables: {
          firstLogEntryIndex: start,
          lastLogEntryIndex: start + fetchSize,
          containerName,
          nodeId: nodeId!,
          filter: {
            reverse: filter.reverse,
            hideLoggers: filter.hideLoggers,
            selectedLevels: filter.selectedLevels,
          },
        },
      });

      //   console.log(logMessages.data);
      const containerLogMessages = logMessages.data?.log?.containerLogMessages ?? [];
      const parsedMessages = containerLogMessages.filter((logMessage: any) => !!logMessage).map((logMessage: any) => JSON.parse(logMessage));

      endReached.current = parsedMessages.length < fetchSize;

      return parsedMessages;
    },
    {
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    remove();
    refetch();
  }, [containerName, filter, refetch, remove]);

  //we must flatten the array of arrays from the useInfiniteQuery hook
  const flatData = useMemo(() => data?.pages?.flatMap((page) => page) ?? [], [data]);

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (scrollHeight - scrollTop - clientHeight < 300 && !isFetching && !endReached.current) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching]
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const table = useReactTable({
    data: flatData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const { rows } = table.getRowModel();

  //Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  // Check if it's needed for any cases except startup screen
  // if (isLoading) {
  //   return <>Loading...</>;
  // }

  return (
    <>
      <ContextMenu model={logMenu} ref={cm} />
      <div ref={tableContainerRef} onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)} className="flex flex-1 flex-column relative overflow-auto">
        <div className="absolute top-0 bottom-0 left-0 right-0 p-datatable">
          <table className="p-datatable-table">
            <thead className="sticky top-0 p-datatable-thead">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                        {header.isPlaceholder ? null : <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="p-datatable-tbody">
              {paddingTop > 0 && (
                <tr>
                  <td style={{ height: `${paddingTop}px` }} />
                </tr>
              )}
              {virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index];
                return (
                  <tr
                    key={row.id}
                    className={"row-" + row.getValue("level")}
                    onContextMenu={(e) => {
                      logMessageRef.current = row.original;
                      cm.current?.show(e);
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id} className="white-space-nowrap" style={{ height: 33 }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {paddingBottom > 0 && (
                <tr>
                  <td style={{ height: `${paddingBottom}px` }} />
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
});
