import { TabPanel, TabView } from "primereact/tabview";
import { memo } from "react";
import { NodeActionsList } from "./node-information/node-actions-list";
import { NodeHostInfo } from "./node-information/node-host-info";
import { NodeIps } from "./node-information/node-ips";
import { NodeStats } from "./node-information/node-stats";

export const NodeInformation = memo(function NodeInformation() {
  return (
    <div className="flex flex-column flex-1">
      <NodeHostInfo />
      <TabView
        className="flex-1 flex flex-column"
        panelContainerClassName="flex-1 flex flex-column"
      >
        <TabPanel header="Stats" className="flex-1 flex flex-column">
          <NodeStats />
        </TabPanel>
        <TabPanel header="NodeActions" className="flex-1 flex flex-column">
          <NodeActionsList />
        </TabPanel>
        <TabPanel header="IP's" className="flex-1 flex flex-column">
          <NodeIps />
        </TabPanel>
      </TabView>
    </div>
  );
});
