import { Knob } from "primereact/knob";
import { memo, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useNodeStatsQuery } from "../../../../graphql/generated/graphql";
// @ts-ignore
import parsePrometheusTextFormat from "parse-prometheus-text-format";

export const NodeStats = memo(function NodeStats() {
  const { nodeId } = useParams();
  const { data } = useNodeStatsQuery({ variables: { id: nodeId! } });

  const promData = useMemo<
    { help: string; name: string; metrics: { value: string }[] }[]
  >(() => parsePrometheusTextFormat(data?.node?.byId?.nodeStats ?? ""), [data]);

  return (
    <div className="flex">
      {promData.map((item) => {
        const value = parseFloat(item.metrics[0].value);

        return (
          <div className="field m-5" key={item.name}>
            <Knob value={Math.round(value * 100) / 100} />
            <h5>{item.name}</h5>
          </div>
        );
      })}
    </div>
  );
});
