import { Column } from "primereact/column";
import {
  DataTable,
  DataTableSelectionChangeParams,
} from "primereact/datatable";
import { memo, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAllRegistriesQuery } from "../../../graphql/generated/graphql";
import { RegisterRegistry } from "./register-registry";

export const RegistriesList = memo(function RegistriesList() {
  const navigate = useNavigate();
  const { registryId } = useParams();
  const { data } = useAllRegistriesQuery();
  const onSelectionChange = useCallback(
    (e: DataTableSelectionChangeParams) => {
      navigate(`/registry/${e.value.id}`);
    },
    [navigate]
  );

  const allRegistries = useMemo(() => data?.registry?.all ?? [], [data]);

  const selectedRegistry = useMemo(() => {
    if (!registryId) {
      return null;
    }

    return allRegistries?.find((item) => item.id === registryId);
  }, [allRegistries, registryId]);

  if (!allRegistries) {
    return null;
  }

  return (
    <div className="flex" style={{ width: "calc(100vw - 21rem)" }}>
      <div className="p-card flex flex-1 flex-column">
        <div className="p-card-body flex flex-1 flex-column">
          <div className="flex flex-1 flex-column">
            <DataTable
              className="flex-1"
              selectionMode="single"
              value={allRegistries}
              selection={selectedRegistry}
              onSelectionChange={onSelectionChange}
              header={
                <div className="flex-1">
                  <RegisterRegistry />
                </div>
              }
            >
              <Column field="name" header="Name" />
              <Column field="url" header="Url" />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
});
