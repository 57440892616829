import { GraphQLInputField, isRequiredInputField } from "graphql";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { memo, useMemo } from "react";
import { useController } from "react-hook-form";

export const TextField = memo(function TextField({ field, name }: { field: GraphQLInputField; name: string }) {
  const required = isRequiredInputField(field);

  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    rules: {
      required: required,
    },
  });

  const asTextarea = useMemo(() => field.astNode?.directives?.some((item) => item.name.value === "asTextarea") ?? false, [field.astNode?.directives]);

  const asPassword = useMemo(() => field.astNode?.directives?.some((item) => item.name.value === "asPassword") ?? false, [field.astNode?.directives]);

  return (
    <div className="field">
      <label htmlFor={name}>{field.name}</label>
      {asTextarea && <InputTextarea id={name} value={value ?? ""} onChange={onChange} onBlur={onBlur} className={!!error ? "p-invalid" : ""} />}
      {!asTextarea && <InputText id={name} value={value ?? ""} onChange={onChange} onBlur={onBlur} type={asPassword ? "password" : "text"} className={!!error ? "p-invalid" : ""} />}
      {field.astNode?.description?.value && <small>{field.astNode?.description?.value}</small>}
      {error && <small>{error.type}</small>}
    </div>
  );
});
