import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { memo } from "react";
import { useAllFeaturesQuery } from "../../../graphql/generated/graphql";
import { FeatureRegister } from "./feature-register";

export const FeaturesList = memo(function FeaturesList() {
  const { data } = useAllFeaturesQuery();

  const allFeatures = data?.feature?.all;

  return (
    <div className="flex" style={{ width: "calc(100vw - 21rem)" }}>
      <div className="p-card flex flex-1 flex-column">
        <div className="p-card-body flex flex-1 flex-column">
          <div className="flex flex-1 flex-column">
            <DataTable
              className="flex-1"
              selectionMode="single"
              value={allFeatures}
              header={
                <div className="flex-1">
                  <FeatureRegister />
                </div>
              }
            >
              <Column field="name" header="Name" className="w-full" />
              <Column field="description" header="description" className="w-full" />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
});
