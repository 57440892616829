import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { useNodeInformationQuery } from "../../../../graphql/generated/graphql";

export const NodeIps = memo(function NodeIps() {
  const { nodeId } = useParams();
  const { data } = useNodeInformationQuery({
    variables: { id: nodeId! },
  });

  const ips = data?.node?.byId?.host?.ips?.map((ip) => ({ ip }));

  if (!ips) {
    return null;
  }

  return (
    <div className="flex flex-1 relative">
      <div className="absolute flex top-0 right-0 bottom-0 left-0">
        <DataTable
          value={ips}
          className="flex-1"
          scrollable
          scrollHeight="flex"
        >
          <Column field="ip" header="IP" />
        </DataTable>
      </div>
    </div>
  );
});
