import { mdiCircle } from "@mdi/js";
import Icon from "@mdi/react";
import dayjs from "dayjs";
import { Column } from "primereact/column";
import { DataTable, DataTableSelectionChangeParams } from "primereact/datatable";
import { classNames } from "primereact/utils";
import { memo, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAllNodesQuery } from "../../../graphql/generated/graphql";
import { RegisterNodeVm } from "./register-node-with-vm";

export const NodesList = memo(function NodesList() {
  const navigate = useNavigate();
  const { nodeId } = useParams();
  const { data } = useAllNodesQuery();
  const onSelectionChange = useCallback(
    (e: DataTableSelectionChangeParams) => {
      navigate(`/node/${e.value.id}`);
    },
    [navigate]
  );

  const allNodes = data?.node?.all;

  const selectedNode = useMemo(() => {
    if (!nodeId) {
      return null;
    }

    return allNodes?.find((item) => item.id === nodeId);
  }, [allNodes, nodeId]);

  const renderState = (row: { lastSeen: string }) => {
    const lastSeenDayJs = dayjs(row.lastSeen);
    const diffSeconds = dayjs().diff(lastSeenDayJs, "second");

    return (
      <Icon
        path={mdiCircle}
        size={0.7}
        className={classNames({
          "text-red-500": diffSeconds > 60,
          "text-yellow-500": diffSeconds > 10 && diffSeconds <= 60,
          "text-green-500": diffSeconds <= 10,
        })}
      />
    );
  };

  if (!allNodes) {
    return null;
  }

  return (
    <div className="flex" style={{ width: "calc(100vw - 21rem)" }}>
      <div className="p-card flex flex-1 flex-column">
        <div className="p-card-body flex flex-1 flex-column">
          <div className="flex flex-1 flex-column">
            <DataTable
              className="flex-1"
              selectionMode="single"
              value={allNodes}
              selection={selectedNode}
              onSelectionChange={onSelectionChange}
              header={
                <div className="flex-1">
                  <RegisterNodeVm />
                </div>
              }
            >
              <Column field="name" header="Name" className="w-full" />
              <Column field="lastSeen" header="State" body={renderState} />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
});
