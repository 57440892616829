import { mdiBug, mdiInformation, mdiListBoxOutline, mdiMonitorEye, mdiStar, mdiWindowMaximize } from "@mdi/js";
import Icon from "@mdi/react";
import { MenuItem } from "primereact/menuitem";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as ContainerdIcon } from "../../../icons/containerd.svg";

export function useNodeMenuStructure() {
  const { nodeId } = useParams();
  return useMemo<MenuItem[]>(
    () => [
      {
        label: "Node",
        items: [
          {
            label: "Overview",
            icon: <Icon path={mdiListBoxOutline} size={0.7} />,
            command: () => {
              window.location.hash = `/node/${nodeId}`;
            },
          },
          {
            label: "Apps",
            icon: <Icon path={mdiWindowMaximize} size={0.7} />,
            command: () => {
              window.location.hash = `/node/${nodeId}/apps`;
            },
          },
          {
            label: "Features",
            icon: <Icon path={mdiStar} size={0.7} />,
            command: () => {
              window.location.hash = `/node/${nodeId}/features`;
            },
          },
          {
            label: "ContainerD",
            icon: React.createElement(ContainerdIcon, { style: { height: 16, marginRight: "0.5rem", fill: "#6c757d" } }, []),
            command: () => {
              window.location.hash = `/node/${nodeId}/containerd`;
            },
          },
          {
            label: "Machine Information",
            icon: <Icon path={mdiInformation} size={0.7} />,
            command: () => {
              window.location.hash = `/node/${nodeId}/machineinformation`;
            },
          },
          {
            label: "K8s Settings",
            icon: <Icon path={mdiInformation} size={0.7} />,
            command: () => {
              window.location.hash = `/node/${nodeId}/k8ssettings`;
            },
          },
          {
            label: "Errors",
            icon: <Icon path={mdiBug} size={0.7} />,
            command: () => {
              window.location.hash = `/node/${nodeId}/errors`;
            },
          },
          {
            label: "VM Log",
            icon: <Icon path={mdiMonitorEye} size={0.7} />,
            command: () => {
              window.location.hash = `/node/${nodeId}/vmlog`;
            },
          },
        ],
      },
    ],
    [nodeId]
  );
}
