import { GraphQLEnumValue } from "graphql";
import { RadioButton } from "primereact/radiobutton";
import { memo } from "react";
import { useController } from "react-hook-form";

export const EnumFieldRadioItem = memo(
  ({ name, enumValue }: { name: string; enumValue: GraphQLEnumValue }) => {
    const {
      field: { onChange, onBlur, value },
    } = useController({ name, rules: { required: true } });

    return (
      <div className="field-radiobutton">
        <RadioButton
          inputId={name + enumValue.value}
          value={enumValue.value}
          onChange={onChange}
          onBlur={onBlur}
          checked={value === enumValue.value}
        />
        <label htmlFor={name + enumValue.value}>{enumValue.name}</label>
      </div>
    );
  }
);
