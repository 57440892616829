import { mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import { memo, useCallback } from "react";
import { useParams } from "react-router";
import { AsyncButton } from "../../../components/async-button/async-button";
import { useRemoveFeatureFromNodeMutation } from "../../../graphql/generated/graphql";

export interface NodeRemoveFeatureProps {
  featureId: string;
}

export const NodeRemoveFeature = memo<NodeRemoveFeatureProps>(function NodeRemoveFeature({ featureId }) {
  const [removeFeature] = useRemoveFeatureFromNodeMutation();
  const { nodeId } = useParams();

  const onRemoveFeature = useCallback(async () => {
    await removeFeature({ variables: { featureId: featureId!, nodeId: nodeId! } });
  }, [featureId, nodeId, removeFeature]);

  return (
    <>
      <AsyncButton label="Remove Feature" className="p-button-danger ml-1" icon={<Icon path={mdiDelete} size={0.7} />} onClick={onRemoveFeature} />
    </>
  );
});
