import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { memo, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useNodeContainerDStatsQuery } from "../../../../graphql/generated/graphql";

export interface SelectableContainerProps {
  onChange: (containerName: string) => void;
}

export const SelectableContainer = memo<SelectableContainerProps>(function SelectableContainer({ onChange }) {
  const { nodeId } = useParams();
  const { data } = useNodeContainerDStatsQuery({
    variables: { id: nodeId! },
  });

  const [selectedContainer, setSelectedContainer] = useState<string | null>(null);

  const onContainerChange = useCallback(
    (e: DropdownChangeParams) => {
      setSelectedContainer(e.value);
      onChange(e.value);
    },
    [onChange]
  );

  const containerNames = useMemo(() => {
    return data?.node?.byId?.containerD?.containers.map((container) => container.containerName) ?? [];
  }, [data]);

  return <Dropdown options={containerNames} value={selectedContainer} onChange={onContainerChange} />;
});
