import { GraphQLEnumType, GraphQLInputField } from "graphql";
import { memo } from "react";
import { ListFieldEnumCheckbox } from "./list-field-enum-checkbox";
import { ListFieldObject } from "./list-field-object";

export const ListFieldEnum = memo(
  ({
    name,
    field,
    enumField,
    objectFields,
  }: {
    name: string;
    field: GraphQLInputField;
    enumField: GraphQLEnumType;
    objectFields?: GraphQLInputField[];
  }) => {
    return (
      <div className="field">
        <label>{field.name}</label>
        {enumField.getValues().map((enumValue) => (
          <div
            key={enumValue.name}
            className={"formgrid grid " + (objectFields ? " mb-3" : "")}
          >
            <div className="col">
              <ListFieldEnumCheckbox
                enumValue={enumValue}
                fieldName={enumField.name}
                listName={name}
                // isObjectStructure={
                //   objectFields ? objectFields.length > 0 : false
                // }
              />
            </div>

            {objectFields && (
              <ListFieldObject
                objectFields={objectFields}
                listName={name}
                enumValue={enumValue.value}
                fieldName={enumField.name}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
);
