import { Tag } from "primereact/tag";
import { memo, useMemo } from "react";
import { useParams } from "react-router";
import { NodeState, useNodeStateUpdatedSubscription } from "../../graphql/generated/graphql";

export const NodeStateTag = memo(function NodeStateTag({ nodeState }: { nodeState?: NodeState | "NotSet" | null }) {
  const { nodeId } = useParams();
  const { data } = useNodeStateUpdatedSubscription({
    variables: { nodeId: nodeId! },
  });

  nodeState = data?.nodeStateUpdated ?? nodeState ?? "NotSet";

  const severity = useMemo<"success" | "info" | "warning" | "danger">(() => {
    // switch (nodeState) {
    //   case NodeState.RequestTimeout:
    //     return "danger";
    // }

    return "info";
  }, []);

  return <Tag severity={severity}>{nodeState}</Tag>;
});
