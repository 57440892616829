import dayjs from "dayjs";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { NodeStateTag } from "../../../../components/node-state/node-state-tag";
import { useNodeInformationQuery } from "../../../../graphql/generated/graphql";
import { NodeLastSeen } from "./node-last-seen";

export const NodeHostInfo = memo(function NodeHostInfo() {
  const { nodeId } = useParams();
  const { data } = useNodeInformationQuery({
    variables: { id: nodeId! },
  });

  const vmIpAddress = data?.node?.byId?.vmIpAddress;
  const lastSeen = data?.node?.byId?.lastSeen;
  const host = data?.node?.byId?.host;

  if (!host) {
    return null;
  }

  return (
    <div className="flex">
      <table className="flex-1 h-1rem">
        <tbody>
          <tr>
            <td>State</td>
            <td>
              <NodeStateTag nodeState={data.node?.byId?.nodeState} />
            </td>
          </tr>
          <tr>
            <td>Upgrade</td>
            <td>
              {data.node?.byId?.upgradeProgress?.message && (
                <>
                  {data.node?.byId?.upgradeProgress?.message} {data.node?.byId?.upgradeProgress?.progress}%
                </>
              )}
            </td>
          </tr>
          <tr>
            <td>Family</td>
            <td>{host.os?.family}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>{host.os?.name}</td>
          </tr>
          <tr>
            <td>Version</td>
            <td>{host.os?.version}</td>
          </tr>
          <tr>
            <td>Build</td>
            <td>{host.os?.build}</td>
          </tr>
          <tr>
            <td>Codename</td>
            <td>{host.os?.codename}</td>
          </tr>
          {/* <tr>
            <td>Major</td>
            <td>{host.os?.major}</td>
          </tr>
          <tr>
            <td>Minor</td>
            <td>{host.os?.minor}</td>
          </tr>
          <tr>
            <td>Patch</td>
            <td>{host.os?.patch}</td>
          </tr> */}
          <tr>
            <td>Platform</td>
            <td>{host.os?.platform}</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>{host.os?.type}</td>
          </tr>
          <tr>
            <td>LastSeen</td>
            <td>
              <NodeLastSeen lastSeen={lastSeen} />
            </td>
          </tr>
          <tr>
            <td>VM IP Address</td>
            <td>{vmIpAddress}</td>
          </tr>
        </tbody>
      </table>
      <table className="flex-1 h-1rem">
        <tbody>
          <tr>
            <td>Hostname</td>
            <td>{host.hostName}</td>
          </tr>
          <tr>
            <td>Architecture</td>
            <td>{host.architecture}</td>
          </tr>
          <tr>
            <td>BootTime</td>
            <td>{dayjs(host.bootTime).format("LLL")}</td>
          </tr>
          <tr>
            <td>Timezone</td>
            <td>{host.timezone}</td>
          </tr>
          <tr>
            <td>TimezoneOffsetSec</td>
            <td>{host.timezoneOffsetSec}</td>
          </tr>
          <tr>
            <td>UniqueId</td>
            <td>{host.uniqueId}</td>
          </tr>
          <tr>
            <td>Kernelversion</td>
            <td>{host.kernelVersion}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});
