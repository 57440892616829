import dayjs from "dayjs";
import { memo, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNodeLastSeenUpdatedSubscription } from "../../../../graphql/generated/graphql";

export const NodeLastSeen = memo(function NodeLastSeen({ lastSeen }: { lastSeen: string }) {
  const { nodeId } = useParams();
  const [lastSeenState, setLastSeenState] = useState(lastSeen);

  const { data } = useNodeLastSeenUpdatedSubscription({
    variables: { nodeId: nodeId! },
  });

  useEffect(() => {
    setLastSeenState(lastSeen);
  }, [lastSeen]);

  useEffect(() => {
    if (data?.nodeLastSeenUpdated) {
      setLastSeenState(data.nodeLastSeenUpdated);
    }
  }, [data]);

  return (
    <>
      {lastSeenState && dayjs(lastSeenState).format("LLL")}
      {!lastSeenState && "N/A"}
    </>
  );
});
