import { mdiArrowLeftBold } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useNodeMenuStructure } from "./hooks/use-node-menu-structure";
import { NodeName } from "./nodeName";

export const NodeMenu = memo(function NodeMenu() {
  const menuStructure = useNodeMenuStructure();
  const navigate = useNavigate();

  const onBack = useCallback(() => {
    navigate("/nodes");
  }, [navigate]);

  return (
    <div className="p-card w-15rem mr-5 pb-2 flex flex-column">
      <NodeName />
      <div>
        <Menu model={menuStructure} className="border-none m-0 w-full" />
      </div>
      <div className="flex-1" />
      <Button className="p-button-sm ml-2 mr-2 mt-2" icon={<Icon path={mdiArrowLeftBold} size={0.7} />} onClick={onBack} label="Back" />
    </div>
  );
});
