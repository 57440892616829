import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { timeRenderer } from "../../../../components/columns/time-renderer";
import { userRenderer } from "../../../../components/columns/user-renderer";
import {
  NodeActionDetail,
  useNodeActionsListQuery,
} from "../../../../graphql/generated/graphql";

export const NodeActionsList = memo(function NodeActionsList() {
  const { nodeId } = useParams();
  const { data } = useNodeActionsListQuery({ variables: { id: nodeId! } });

  return (
    <div className="flex flex-1 relative">
      <div className="absolute flex top-0 right-0 bottom-0 left-0">
        <DataTable
          value={data?.node?.byId?.allActions}
          className="flex-1"
          scrollable
          scrollHeight="flex"
        >
          <Column field="action" header="Action" />
          <Column
            field="arrangedTime"
            header="ArrangedTime"
            body={timeRenderer<NodeActionDetail>("arrangedTime")}
          />
          <Column
            field="startedTime"
            header="StartedTime"
            body={timeRenderer<NodeActionDetail>("startedTime")}
          />
          <Column
            field="finishedTime"
            header="FinishedTime"
            body={timeRenderer<NodeActionDetail>("finishedTime")}
          />
          <Column
            field="user"
            header="User"
            body={userRenderer<NodeActionDetail>("arrangedBy")}
          />
        </DataTable>
      </div>
    </div>
  );
});
