import { mdiAutorenew, mdiCloudDownload, mdiKeyVariant, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { AsyncButton } from "../../../components/async-button/async-button";
import {
  useArrangeRequestRestartAllAppsMutation,
  useArrangeRequestUpgradeAllAppsMutation,
  useArrangeRestartAllAppsMutation,
  useArrangeUpgradeAllAppsMutation,
  useRemoveNodeMutation,
} from "../../../graphql/generated/graphql";
import { NodeToken } from "./node-token";

export const NodeActions = memo(function NodeActions() {
  const { nodeId } = useParams();
  const [upgradeAllApps] = useArrangeUpgradeAllAppsMutation();
  const [restartAllApps] = useArrangeRestartAllAppsMutation();
  const [requestRestartAllApps] = useArrangeRequestRestartAllAppsMutation();
  const [requestUpgradeAllApps] = useArrangeRequestUpgradeAllAppsMutation();
  const [removeNode] = useRemoveNodeMutation();
  const [nodeTokenVisible, setNodeTokenVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);

  const onShowNodeToken = useCallback(() => {
    setNodeTokenVisible(true);
  }, []);

  const onHideNodeToken = useCallback(() => {
    setNodeTokenVisible(false);
  }, []);

  const onSetDialogVisible = useCallback(() => {
    setDialogVisible(true);
  }, []);

  const onHideDialog = useCallback(() => {
    setDialogVisible(false);
  }, []);

  const onUpgradeAllApps = useCallback(async () => {
    await upgradeAllApps({ variables: { nodeId: nodeId! } });
  }, [nodeId, upgradeAllApps]);

  const onRestartAllApps = useCallback(async () => {
    await restartAllApps({ variables: { nodeId: nodeId! } });
  }, [nodeId, restartAllApps]);

  const onRequestUpgradeAllApps = useCallback(async () => {
    await requestUpgradeAllApps({ variables: { nodeId: nodeId! } });
  }, [nodeId, requestUpgradeAllApps]);

  const onRequestRestartAllApps = useCallback(async () => {
    await requestRestartAllApps({ variables: { nodeId: nodeId! } });
  }, [nodeId, requestRestartAllApps]);

  const onRemoveNode = useCallback(async () => {
    await removeNode({ variables: { nodeId: nodeId! } });
  }, [nodeId, removeNode]);

  return (
    <div className="p-card flex mb-5 flex-column">
      <div className="p-card-body flex">
        <AsyncButton label="Request restart all Apps" className="p-button-sm" icon={<Icon path={mdiAutorenew} size={0.7} />} onClick={onRequestRestartAllApps} />
        <AsyncButton label="Request upgrade all Apps" className="p-button-sm ml-1" icon={<Icon path={mdiCloudDownload} size={0.7} />} onClick={onRequestUpgradeAllApps} />
        <Button label="Force restart or upgrade" className="p-button-sm ml-1" icon={<Icon path={mdiCloudDownload} size={0.7} />} onClick={onSetDialogVisible} />

        <Dialog onHide={onHideDialog} visible={dialogVisible}>
          <div className="flex flex-1 flex-column align-items-center" style={{ padding: "10px" }}>
            <h1>Attention!</h1>
            <p>This will force a restart or upgrade of this node. Make sure the machine is in a state that allows such an operation!</p>
            <p>Ensure with customer that this action is necessary and intended.</p>
            <div className="line-height-4" style={{ padding: "10px" }}>
              <AsyncButton
                label="Force restart all Apps"
                className="p-button-sm ml-1"
                icon={<Icon path={mdiAutorenew} size={0.7} />}
                style={{ padding: "10px" }}
                onClick={onRestartAllApps}
              />
              <AsyncButton
                label="Force upgrade all Apps"
                className="p-button-sm ml-1"
                icon={<Icon path={mdiCloudDownload} size={0.7} />}
                style={{ padding: "10px" }}
                onClick={onUpgradeAllApps}
              />
            </div>
          </div>
        </Dialog>

        <div className="flex-1" />

        <Button label="Show Token" icon={<Icon path={mdiKeyVariant} size={0.7} />} className="p-button-sm" onClick={onShowNodeToken} />
        <Dialog header="Node Token" visible={nodeTokenVisible} style={{ width: "50vw" }} modal onHide={onHideNodeToken}>
          <NodeToken />
        </Dialog>

        <AsyncButton label="Remove Node" className="p-button-sm p-button-danger ml-1" icon={<Icon path={mdiTrashCan} size={0.7} />} onClick={onRemoveNode} />
      </div>
    </div>
  );
});
