import { memo, useCallback } from "react";
import { useParams } from "react-router";
import { GraphqlForm } from "../../../components/graphql-form/graphql-form";
import { MachineInformation, useGetMachineInformationQuery, useUpdateMachineInformationMutation } from "../../../graphql/generated/graphql";
const schemaFile = require("../../../schema.graphql");

export const NodeMachineInformation = memo(function NodeMachineInformation() {
  const { nodeId } = useParams();
  const machineInformation = useGetMachineInformationQuery({
    variables: { nodeId: nodeId! },
  });
  const [updateMachineInformation] = useUpdateMachineInformationMutation();

  const onSubmit = useCallback(
    async (values: MachineInformation) => {
      console.log(values);
      await updateMachineInformation({
        variables: {
          nodeId: nodeId!,
          machineInformation: values,
        },
      });
    },
    [updateMachineInformation, nodeId]
  );

  const initialData = machineInformation.data?.node?.byId;

  if (!initialData) {
    return null;
  }

  return (
    <div className="flex flex-column" style={{ width: "calc(100vw - 21rem)" }}>
      <div className="p-card flex flex-1 flex-column overflow-y-auto">
        <div className="p-card-body flex flex-1 flex-column">
          <div className="flex flex-1 flex-column">
            <div className="p-fluid">
              <GraphqlForm<MachineInformation> schemaFile={schemaFile} inputTypeName="MachineInformation" initialData={initialData} onSubmit={onSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
