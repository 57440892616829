import { Menu } from "primereact/menu";
import { memo } from "react";
import { useMenuStructure } from "./hooks/use-menu-structure";

export const SideMenu = memo(function SideMenu() {
  const menuStructure = useMenuStructure();

  return (
    <div className="p-card w-15rem mr-5">
      <Menu model={menuStructure} className="border-none m-0 w-full" />
    </div>
  );
});
