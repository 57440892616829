import { memo } from "react";
import { NodeMenu } from "../../components/node-menu/node-menu";
import { NodeOverviewDetail } from "./components/node-overview-detail";

export const Node = memo(function Node() {
  return (
    <div className="flex flex-1">
      <NodeMenu />
      <NodeOverviewDetail />
    </div>
  );
});
