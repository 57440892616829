import {
  getNullableType,
  GraphQLInputField,
  isEnumType,
  isListType,
  isScalarType,
} from "graphql";
import { memo } from "react";
import { CheckboxField } from "./fields/checkbox-field";
import { EnumField } from "./fields/enum-field";
import { IntField } from "./fields/int-field";
import { ListField } from "./fields/list-field";
import { StringField } from "./fields/string-field";

export const InputField = memo(
  ({ field, name }: { field: GraphQLInputField; name: string }) => {
    const type = getNullableType(field.type);

    return (
      <>
        {isScalarType(type) && (
          <>
            {type.name === "String" && (
              <StringField field={field} name={name} />
            )}
            {type.name === "Int" && <IntField field={field} name={name} />}
            {type.name === "Boolean" && (
              <CheckboxField field={field} name={name} />
            )}
          </>
        )}
        {isListType(type) && (
          <>
            <ListField field={field} type={type} name={name} />
          </>
        )}
        {isEnumType(type) && (
          <EnumField field={field} type={type} name={name} />
        )}
        {/* {isScalarType(type) && type.name === "Int" && <div>scalar</div>} */}
      </>
    );
  }
);
