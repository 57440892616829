import { memo, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useCurrentUserQuery } from "../../graphql/generated/graphql";

export const EnsureLoggedIn = memo<PropsWithChildren>(function EnsureLoggedIn({ children }) {
  const { data, loading } = useCurrentUserQuery({ fetchPolicy: "no-cache" });

  if (loading) {
    return null;
  }

  if (!data?.user?.current?.id) {
    return <Navigate replace to="/more/login" />;
  }

  return <>{children}</>;
});
